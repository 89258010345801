import { styled } from "@mui/material"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import MuiFormLabel from "@mui/material/FormLabel"
import CustomTextField from "@/components/TextField"
import Typography from "@mui/material/Typography"
import MuiAlert from "@mui/material/Alert"
import InlineContainer from "@/components/InlineContainer"

export const Container = styled(Box)(
  ({ theme }) => `
  background: ${theme.palette.primary.dark};
  display: flex;
  align-items: center;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
	
	${theme.breakpoints.up("lg")} {
  flex-direction: row;

	}
`,
)

export const Logo = styled(Box)(
  ({ theme }) => `
	display: flex;
	position: relative;
	margin: 2rem auto;
	text-align: center;
	flex-direction: column;

	img {
		width: 80px;
		: 40px;
	}

	& p {
		color: ${theme.palette.common.white};
		margin-top: 1rem;
	}

	& a {
		color: ${theme.palette.common.white};
		text-decoration-color: ${theme.palette.common.white};
	}

	& button {
		color: ${theme.palette.common.white};
		display: block;
		font-size: 1rem;
	}
	
	${theme.breakpoints.up("lg")} {
		position: fixed;
		top: 2rem;
		left: 2rem;
		margin: 0;
		
		& p,
		& button {
			display: none;
		}
	}
`,
)

export const Aside = styled(Box)(
  ({ theme }) => `
	display: none;
	position: relative;
	
	${theme.breakpoints.up("lg")} {
		display: flex;
		flex-basis: 100%;
		min-height: 100vh;
		justify-content: center;
		align-items: center;
	}
`,
)

export const Content = styled(Paper)(
  ({ theme }) => `
	background: ${theme.palette.common.white};
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	padding-top: 1rem;
	flex-basis: 100%;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
	border-radius: 0px;
	overflow-y: auto;
	
	${theme.breakpoints.up("sm")} {
		padding-top: 5rem;
	}
	
	${theme.breakpoints.up("lg")} {
		padding-top: 0;
		justify-content: center;
		width: auto;
	}
`,
)

export const SignUpOrLoginFormContainer = styled(Box)(
  ({ theme }) => `
	margin-top: 7rem;
	background-color: ${theme.palette.common.white};
	border-radius: 10px;
	width: 90%;
	text-align: center;
	padding: 1rem;
   
	${theme.breakpoints.up("md")} {
		width: 550px;
		padding: 2rem 4rem;
		box-shadow: ${theme.shadows[8]};
	}
`,
)

export const Hint = styled(Box)(
  ({ theme }) => `
	display: none;
	
	${theme.breakpoints.up("lg")} {
		display: block;
		position: absolute;
		top: 2rem;
		right: 2rem;
	}
`,
)

export const FormLabel = styled(MuiFormLabel)`
  color: ${({ theme }) => theme.palette.common.black};
  display: block;
  font-weight: 600;
  margin: 0 0 -10px 0;
  text-align: left;
  width: 100%;
`

export const AdornedInput = styled(Box)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`

export const TextField = styled(CustomTextField)(
  ({ theme }) => `
  & .MuiOutlinedInput-root {
    padding-left: 40px;
  }

  & .MuiOutlinedInput-root.Mui-disabled {
    background: #fafafa;
  }

  & .MuiInputLabel-root {
    left: 40px;
  }

  & .MuiOutlinedInput-notchedOutline {
    padding-left: 46px;
  }

  & .MuiInputAdornment-root svg {
    color: ${theme.palette.grey["600"]};
  }

  & .MuiFormHelperText-root {
    font-size: 0.8rem;
    margin: 0;
  }
`,
)

export const Adornment = styled(Box)(
  () => `
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 8.5px 0 8.5px 16px;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  max-height: none;
  z-index: 1;
`,
)

export const Spacer = styled(Box)(
  ({ theme }) => `
  display: flex;
  margin: 2.125rem 0;
  justify-content: center;
  align-items: center;
	text-align: center;
	position: relative;

  & span {
    background: ${theme.palette.common.white};
    display: inline-block;
		position: absolute;
    padding: 0 0.5rem;
  }

	& .MuiDivider-root {
		width: 100%;
	}
`,
)

export const Legal = styled(Typography)(
  ({ theme }) => `
  color: ${theme.palette.grey["600"]};
	font-size: 0.8rem;
  margin-top: 1.5rem;
	text-align: center;
	width: 100%;
`,
)

export const Alert = styled(MuiAlert)`
  text-align: left;
`

export const PasswordRequirements = styled(Box)(
  ({ theme }) => `
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
	margin: -0.5rem 0 0 0;
	
	& .MuiTypography-body1 {
		font-size: 13px;
		margin: 0;
	}
	
	& .MuiTypography-body2 {
		font-size: 13px;
		display: flex;
		flex-direction: row;
		justify-content: start;
		align-items: center;
		margin: 0.25rem 0 0 0;
		line-height: 100%;
	}
	
	& .MuiTypography-body2 .MuiSvgIcon-root {
		color: ${theme.palette.success.light};
		display: inline-block;
		margin-right: 0.35rem;
		width: 18px;
		height: 18px;
	}
	
	& .MuiTypography-body2 .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
		color: ${theme.palette.grey["800"]};
	}
`,
)

export const Header = styled(InlineContainer)(
  ({ theme }) => `
	background-color: ${theme.palette.primary.dark};
	justify-content: space-between;
	padding: ${theme.spacing(1.5, 3)};
	position: fixed;
	height: 64px;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10;
`,
)
