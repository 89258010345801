import { combineReducers, Store } from "redux"
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import { createWrapper } from "next-redux-wrapper"
import layoutReducer, { LayoutState } from "@/features/layout/layout-reducers"
import invoiceReducer, { InvoiceState } from "@/features/invoice-management/invoice-management-reducers"
import quoteReducer, { QuoteState } from "@/features/quote-management/quote-management-reducers"
import walletReducer, { WalletState } from "@/features/wallet/wallet-reducers"
import authReducer, { AuthState } from "@/features/auth/auth-reducers"

export type AppState =
  | {
      layout: LayoutState
      invoice: InvoiceState
      quote: QuoteState
      wallet: WalletState
      auth: AuthState
    }
  | undefined

const reducer = combineReducers({
  layout: layoutReducer,
  invoice: invoiceReducer,
  quote: quoteReducer,
  wallet: walletReducer,
  auth: authReducer,
})

export const rootReducer = (state: AppState, action: Action) => reducer(state, action)

// ---------- NO TOUCH ZONE ----------
// Please don't refactor anything within this zone.
// configureStore is designed to be called everytime any request comes in
// The store in each wrapper should be different.
const store = configureStore({
  reducer: rootReducer,
  // devTools: process.env.NODE_ENV !== "production",
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>

export const wrapper = createWrapper<Store<AppState>>(() =>
  configureStore({
    reducer: rootReducer,
    // devTools: process.env.NODE_ENV !== "production",
  }),
)
// ---------- NO TOUCH ZONE ----------
