import InlineContainer from "@/components/InlineContainer"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import WarningIcon from "@mui/icons-material/WarningAmberRounded"
import ErrorIcon from "@mui/icons-material/ErrorOutlineRounded"
import FormattedNumber from "@/components/FormattedNumber"
import unformatNumber from "@/utils/unformatNumber"

type InventoryQuantityProps = {
  minimum: number
  remaining: number
  alwaysVisible?: boolean
  label?: string | null
  [key: string]: any
}

const InventoryQuantity = ({
  minimum = 0,
  remaining = 0,
  alwaysVisible = true,
  label = null,
  ...props
}: InventoryQuantityProps) => {
  const isLow = unformatNumber(remaining) <= unformatNumber(minimum) && unformatNumber(remaining) > 0
  const isOut = unformatNumber(remaining) <= 0

  return isLow || isOut ? (
    <InlineContainer gap="0.25rem" marginRight="1rem" {...props}>
      {isLow ? (
        <WarningIcon fontSize="small" color="warning" sx={{ fontSize: "1.125rem" }} />
      ) : (
        <ErrorIcon fontSize="small" color="error" sx={{ fontSize: "1.125rem" }} />
      )}
      <Box
        sx={{
          color: isLow ? "warning.main" : "error.main",
        }}
      >
        {isLow ? (
          <Typography
            variant="body2"
            sx={{
              color: isLow ? "warning.main" : "error.main",
            }}
          >
            Low Stock{" "}
            <FormattedNumber
              prefix={`(${Boolean(label) ? label : "Qty: "}`}
              suffix=")"
              allowNegative={true}
              fixedDecimalScale={false}
              value={remaining ?? 0}
            />
          </Typography>
        ) : (
          <Typography
            variant="body2"
            sx={{
              color: isLow ? "warning.main" : "error.main",
            }}
          >
            Out of Stock{" "}
            <FormattedNumber
              prefix={`(${Boolean(label) ? label : "Qty: "}`}
              suffix=")"
              allowNegative={true}
              fixedDecimalScale={false}
              value={remaining ?? 0}
            />
          </Typography>
        )}
      </Box>
    </InlineContainer>
  ) : alwaysVisible ? (
    <Typography variant="body2" {...props}>
      <FormattedNumber
        prefix={label !== null ? label : "Available Qty: "}
        fixedDecimalScale={false}
        value={remaining ?? 0}
      />
    </Typography>
  ) : null
}

export default InventoryQuantity
