import { FormLabel } from "@/features/auth/components/styled"
import TextField from "@/components/TextField"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import getFieldErrors from "@/utils/getFieldErrors"
import { PatternFormat } from "react-number-format"

const BusinessSsn = ({ formik }) => {
  return (
    <>
      <Typography variant="h4">Verify your identity</Typography>
      <Typography variant="body1" sx={{ marginTop: "0.25rem" }}>
        We use this information to prevent fraud and safeguard your identity.
      </Typography>
      <Box>
        <Box marginTop="1.5rem">
          <FormLabel sx={{ marginBottom: "6px" }}>Last 4 digits of SSN</FormLabel>
          <PatternFormat
            displayType="input"
            format="####"
            mask="_"
            allowEmptyFormatting
            customInput={TextField}
            type="tel"
            size="small"
            sx={{ width: "50%" }}
            {...formik.getFieldProps("last_four_ssn")}
            {...getFieldErrors("last_four_ssn", formik)}
          />
        </Box>
      </Box>
    </>
  )
}

export default BusinessSsn
