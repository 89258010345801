import InlineContainer from "@/components/InlineContainer"
import { FormLabel } from "@/features/auth/components/styled"
import TextField from "@/components/TextField"
import getFieldErrors from "@/utils/getFieldErrors"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

const FullLegalName = ({ formik }) => {
  return (
    <>
      <Typography variant="h4">What is your full legal name?</Typography>
      <InlineContainer
        gap="1rem"
        marginTop="2rem"
        alignItems="start"
        sx={{
          flexDirection: { xs: "column", md: "row" },
          width: "100%",
        }}
      >
        <Box sx={{ flexBasis: "100%", width: "100%" }}>
          <FormLabel sx={{ marginBottom: "6px" }}>First Name</FormLabel>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            placeholder="First Name"
            {...formik.getFieldProps("compliance_answer['first_name']")}
            {...getFieldErrors("compliance_answer['first_name']", formik)}
          />
        </Box>
        <Box sx={{ flexBasis: { xs: "100%", md: "50%" }, width: "100%" }}>
          <FormLabel sx={{ marginBottom: "6px" }}>M.I.</FormLabel>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            placeholder="Optional"
            {...formik.getFieldProps("compliance_answer['middle_name']")}
            {...getFieldErrors("compliance_answer['middle_name']", formik)}
          />
        </Box>
        <Box sx={{ flexBasis: "100%", width: "100%" }}>
          <FormLabel sx={{ marginBottom: "6px" }}>Last Name</FormLabel>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            placeholder="Last Name"
            {...formik.getFieldProps("compliance_answer['last_name']")}
            {...getFieldErrors("compliance_answer['last_name']", formik)}
          />
        </Box>
      </InlineContainer>
    </>
  )
}

export default FullLegalName
