import { createSelector } from "@reduxjs/toolkit"
import type { RootState } from "@/config/store"

export const selectAuth = ({ auth }: RootState) => auth

export const selectAccountId = createSelector([selectAuth], (auth) => auth?.accountId)

export const selectPartnershipId = createSelector([selectAuth], (auth) => auth?.partnershipId)

export const selectAdminRole = createSelector([selectAuth], (auth) => auth?.adminRole)

export const selectSpoof = createSelector([selectAuth], (auth) => auth?.spoof)
