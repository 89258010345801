import { v4 as uuid } from "uuid"
import Box from "@mui/material/Box"
import MenuItem from "@mui/material/MenuItem"
import MuiDialog from "@mui/material/Dialog"
import Link from "@/components/Link"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import NiceModal, { useModal } from "@ebay/nice-modal-react"
import Typography from "@mui/material/Typography"
import InlineContainer from "@/components/InlineContainer"
import { useMediaQuery, useTheme } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/CloseRounded"
import ExpandIcon from "@mui/icons-material/ExpandMoreRounded"
import CollapseIcon from "@mui/icons-material/ExpandLessRounded"
import useAppDispatch from "@/hooks/useAppDispatch"
import { setInvoice } from "@/features/invoice-management/invoice-management-reducers"
import FormattedNumber from "@/components/FormattedNumber"
import LeftArrowIcon from "@mui/icons-material/ChevronLeftRounded"
import { useFormik } from "formik"
import useAppSelector from "@/hooks/useAppSelector"
import {
  selectInvoice,
  selectInvoiceCustomers,
  selectInvoiceItems,
} from "@/features/invoice-management/invoice-management-selectors"
import DashedDivider from "@/components/DashedDivider"
import Select from "@/components/Select"
import getFieldErrors from "@/utils/getFieldErrors"
import TextField from "@/components/TextField"
import { fetchAllPriceModifiers, fetchCustomerCategories } from "@/features/price-management/price-management-queries"
import { useQuery } from "@tanstack/react-query"
import StatusIndicator from "@/components/StatusIndicator"
import PlusIcon from "@mui/icons-material/AddRounded"
import {
  DialogKeys,
  Invoice,
  InvoiceStatus,
  ItemPriceModifier,
  PriceModifierType,
  QuantityMeasurement,
  Query,
  Quote,
} from "@/types"
import { useState } from "react"
import Collapse from "@mui/material/Collapse"
import { getDollarsFromInt, getIntFromDollars, getPercentFromFloat, getPriceModifierValue } from "@/utils/calculations"
import InventoryQuantity from "@/features/price-management/components/InventoryQuantity"
import AffirmativeButton from "@/components/buttons/AffirmativeButton"
import NeutralButton from "@/components/buttons/NeutralButton"
import { selectQuote, selectQuoteCustomers, selectQuoteItems } from "../quote-management/quote-management-selectors"
import { setQuote } from "../quote-management/quote-management-reducers"
import { INDIVIDUAL_ITEMS } from "./utils"

export const transformPriceModifierValues = (modifier) => {
  return {
    pricemodifier_name: modifier?.name,
    pricemodifier_value_pct:
      modifier?.mod === "percentage"
        ? ((modifier.type === PriceModifierType.DISCOUNT ? -1 : 1) * Math.round(+modifier?.value * 100)) / 10000
        : null,
    pricemodifier_value_flat:
      modifier?.mod === "fixed"
        ? (modifier.type === PriceModifierType.DISCOUNT ? -1 : 1) * getIntFromDollars(+modifier?.value)
        : null,
  }
}

export default NiceModal.create(
  ({ mode, selectedPriceItems = [] }: { mode: "invoice" | "quote"; selectedPriceItems: any[] }) => {
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))
    const modal = useModal(DialogKeys.INVOICE_QUOTE_PRICE_ITEM_OPTIONS)
    const selectedInvoiceQuote = useAppSelector<Invoice | Quote>(mode === "invoice" ? selectInvoice : selectQuote)
    const customer = useAppSelector(mode === "invoice" ? selectInvoiceCustomers : selectQuoteCustomers)
    const invoiceQuoteItems = useAppSelector(mode === "invoice" ? selectInvoiceItems : selectQuoteItems)
    const [open, setOpen] = useState(selectedPriceItems?.map(() => false))

    const hasOneCustomer = customer?.length === 1

    const customerId = hasOneCustomer ? customer?.[0]?.id : null

    const isEditing = selectedPriceItems?.some((i) => i?.is_editing)

    const { data: customerCategories } = useQuery(
      [Query.account.BUILDER_ALL_CUSTOMER_CATEGORIES, customerId],
      () => fetchCustomerCategories(customerId as string),
      {
        enabled: hasOneCustomer,
      },
    )

    const { data: priceModifiers } = useQuery([Query.account.BUILDER_ALL_PRICE_MODIFIERS], () =>
      fetchAllPriceModifiers(),
    )

    const handleCloseModal = () => {
      modal.reject()
      modal.hide()
    }

    const initialValues = {
      items: selectedPriceItems?.map((item) => ({
        ...item,
        id: item.id ?? null,
        account_priceitem_id: item?.account_priceitem_id ?? null,
        account_pricemodifier_id: item?.account_pricemodifier_id ?? null,
        account_customer_category_id: item?.account_customer_category_id ?? null,
        pricemodifier_name: item?.pricemodifier_name ?? null,
        pricemodifier_value_pct: item?.pricemodifier_value_pct ?? null,
        pricemodifier_value_flat: item?.pricemodifier_value_flat ?? null,
        quantity_measurement:
          item?.is_track_inventory ?? false
            ? QuantityMeasurement.UNIT
            : item?.quantity_measurement ?? QuantityMeasurement.UNIT,
        quantity: item?.quantity ?? 1,
        description: item?.description ?? "",
        price: item?.price ?? 0,
        name: item?.name ?? "",
        is_recurring: item?.is_recurring ?? false,
        is_track_inventory: item?.is_track_inventory ?? false,
        inventory_reorder_point: item?.inventory_reorder_point ?? 0,
        inventory_on_hand: item?.inventory_on_hand ?? 0,
        is_editing: item?.is_editing ?? false,
        is_taxable: item?.is_taxable ?? false,
      })),
    }

    const formatItems = (items) => {
      return items.map((item) => ({
        ...item,
        quantity: +item.quantity,
      }))
    }

    const formik = useFormik({
      initialValues,
      onSubmit: (values) => {
        if (isEditing) {
          const sort_order = selectedPriceItems?.[0]?.sort_order
          const pricemodifiers = [
            ...(values?.items?.[0]?.pricemodifier_name
              ? [
                  {
                    name: values?.items?.[0]?.pricemodifier_name,
                    value_flat: values?.items?.[0]?.pricemodifier_value_flat,
                    value_pct: values?.items?.[0]?.pricemodifier_value_pct,
                    account_pricemodifier_id: values?.items?.[0]?.account_pricemodifier_id || null,
                    is_tax: false,
                  },
                ]
              : []),
            ...(values?.items?.[0]?.pricemodifiers || []).filter((pm) => pm.is_tax),
          ]

          const items = formatItems([
            ...(invoiceQuoteItems || []).slice(0, sort_order),
            {
              ...values?.items?.[0],
              pricemodifiers,
              ...(values?.items?.[0]?.account_customer_category_id
                ? {
                    account_customer_category_name: customerCategories.find(
                      ({ id }) => id === values?.items?.[0].account_customer_category_id,
                    )?.name,
                  }
                : {}),
            },
            ...(invoiceQuoteItems || []).slice(sort_order + 1),
          ])

          if (mode === "invoice") {
            if (selectedInvoiceQuote?.sync_mode === "ask" && values?.items?.[0]?.is_recurring) {
              dispatch(
                setInvoice({
                  ...selectedInvoiceQuote,
                  items,
                  has_sync_data_changed: true,
                  should_sync_data: true,
                  has_changed: true,
                }),
              )
            } else {
              dispatch(
                setInvoice({
                  ...selectedInvoiceQuote,
                  has_changed: true,
                  items,
                }),
              )
            }
          } else {
            dispatch(
              setQuote({
                ...selectedInvoiceQuote,
                has_changed: true,
                items,
              } as Quote),
            )
          }
        } else {
          const newItems = values.items.map((item) => {
            let pricemodifiers: ItemPriceModifier[] = []

            if (item.pricemodifier_name) {
              pricemodifiers.push({
                name: item.pricemodifier_name,
                value_flat: item.pricemodifier_value_flat,
                value_pct: item.pricemodifier_value_pct,
                account_pricemodifier_id: item.account_pricemodifier_id || null,
                is_tax: false,
              })
            }

            if (item.is_taxable) {
              pricemodifiers = [
                ...pricemodifiers,
                ...(selectedInvoiceQuote?.taxes || []).map((tax) => ({
                  name: tax.name,
                  value_pct: tax.value_pct,
                  account_pricemodifier_id: tax.id,
                  is_tax: true,
                })),
              ]
            }

            return {
              ...item,
              pricemodifiers,
              id: uuid(),
              ...(item?.account_customer_category_id
                ? {
                    account_customer_category_name: customerCategories.find(
                      ({ id }) => id === item.account_customer_category_id,
                    )?.name,
                  }
                : {}),
              is_recurring:
                mode === "invoice" &&
                [InvoiceStatus.SCHEDULED, InvoiceStatus.DRAFT].includes(selectedInvoiceQuote.status as InvoiceStatus) &&
                selectedInvoiceQuote?.is_recurring,
            }
          })

          const items = formatItems([...(invoiceQuoteItems || []), ...newItems])

          if (mode === "invoice") {
            if (selectedInvoiceQuote?.status === InvoiceStatus.SCHEDULED && selectedInvoiceQuote?.sync_mode === "ask") {
              dispatch(
                setInvoice({
                  ...selectedInvoiceQuote,
                  items,
                  has_sync_data_changed: true,
                  should_sync_data: true,
                  has_changed: true,
                }),
              )
            } else {
              dispatch(
                setInvoice({
                  ...selectedInvoiceQuote,
                  has_changed: true,
                  items,
                }),
              )
            }
          } else {
            dispatch(
              setQuote({
                ...selectedInvoiceQuote,
                has_changed: true,
                items,
              } as Quote),
            )
          }
        }

        modal.hide()
      },
    })

    const toggleCollapse = (index) => {
      const list = [...open]
      list[index] = !list[index]
      setOpen(list)
    }

    const handleAddNewPriceModifier = async (priceItem) => {
      modal.hide()
      NiceModal.show(DialogKeys.PRICE_MODIFIER, {
        existingModifier: { from_builder: true },
        priceItems: formik.values.items,
      })
        .then(({ modifier, priceItems }: any) => {
          // NOTE: Initially, it might seem that with us reopening the dialog, we could simply set the formik value for the newly added
          // discount/surcharge, however, when we pass back the previously selected products, it replaces formik's initialValues with
          // what we pass in. So, what we're doing here is passing back the selected products with the updated discount/surcharge selection.
          modal.show({
            selectedPriceItems: priceItems.map((item) => {
              if (item?.account_priceitem_id !== priceItem?.id && item?.sort_order !== priceItem?.sort_order) {
                return item
              }

              return {
                ...item,
                account_pricemodifier_id: modifier?.id,
                ...transformPriceModifierValues(modifier),
              }
            }),
            mode,
          })
        })
        .catch(({ priceItems }) => modal.show({ selectedPriceItems: priceItems, mode }))
    }

    const handleAddNewServiceFor = async (index) => {
      modal.hide()
      NiceModal.show(DialogKeys.CUSTOMER_CATEGORY, {
        customerId,
        priceItems: formik.values.items,
      })
        .then(({ category, priceItems }: any) => {
          // Refer to the "NOTE:" above. We're effectively doing the same thing here:
          modal.show({
            selectedPriceItems: priceItems.map((item, i) => ({
              ...item,
              account_customer_category_id: i === index ? category?.id : item.account_customer_category_id,
            })),
            mode,
          })
        })
        .catch(({ priceItems }) => modal.show({ selectedPriceItems: priceItems, mode }))
    }

    const serviceForText = () => {
      if (selectedInvoiceQuote?.customers?.length > 1) {
        return `Cannot select Service For when creating a Batch ${mode}.`
      } else if (!customerId) {
        return "Select a customer to enable Service For selection."
      } else if (customerCategories?.length === 0) {
        return "No Service For options available for this customer."
      } else {
        return null
      }
    }

    return (
      <MuiDialog
        open={modal.visible}
        maxWidth="sm"
        fullWidth
        fullScreen={isMobile}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
          <InlineContainer justifyContent="space-between">
            <Typography
              color="primary"
              variant="h6"
              sx={{
                paddingLeft: { xs: "30px", md: 0 },
                textAlign: { xs: "center", md: "left" },
                width: "100%",
              }}
            >
              Item Options
            </Typography>
            <IconButton
              aria-label="close"
              size="small"
              onClick={handleCloseModal}
              sx={{
                color: (theme) => theme.palette.grey[500],
                position: "relative",
                right: -6,
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </InlineContainer>
        </DialogTitle>
        <DialogContent sx={{ padding: "0.75rem 1.25rem 0.25rem" }} dividers>
          {formik?.values?.items?.map((item, index) => (
            <Box key={`invoice-builder-item-options-${item?.name}-${index}`} width="100%">
              {formik?.values?.items?.length === 1 ? (
                <>
                  <Typography variant="h6" sx={{ fontWeight: 500 }}>
                    {item?.name}
                  </Typography>

                  <Typography variant="h3" sx={{ fontWeight: 400 }}>
                    <FormattedNumber prefix="$" value={getDollarsFromInt(item?.price)} />
                  </Typography>
                  <DashedDivider spacing={3} />
                </>
              ) : (
                <InlineContainer
                  justifyContent="space-between"
                  sx={(theme) => ({
                    backgroundColor: "grey.200",
                    marginLeft: "-0.75rem",
                    marginBottom: "0.5rem",
                    padding: "0.5rem 0.75rem",
                    borderRadius: `${theme.shape.borderRadius}px`,
                    width: "calc(100% + 1.5rem)",
                  })}
                >
                  <Typography
                    variant="h6"
                    onClick={() => toggleCollapse(index)}
                    sx={{
                      cursor: "pointer",
                      fontSize: "1rem",
                      fontWeight: 500,
                    }}
                  >
                    <strong>Item {index + 1}:</strong> {item?.name} -{" "}
                    <FormattedNumber prefix="$" value={getDollarsFromInt(item?.price)} />
                  </Typography>

                  <InlineContainer>
                    <Link
                      onClick={() => toggleCollapse(index)}
                      variant="body2"
                      underline="none"
                      color="grey.700"
                      sx={{ fontSize: "0.85rem" }}
                    >
                      {open[index] ? "Close" : "Edit"}
                    </Link>
                    <IconButton size="small" onClick={() => toggleCollapse(index)}>
                      {open[index] ? <CollapseIcon fontSize="small" /> : <ExpandIcon fontSize="small" />}
                    </IconButton>
                  </InlineContainer>
                </InlineContainer>
              )}
              <Collapse in={open[index] || formik?.values?.items?.length == 1} collapsedSize={0}>
                <Box sx={{ marginBottom: "1rem" }}>
                  <Typography variant="body1" sx={{ fontWeight: 400, marginBottom: "0.75rem" }}>
                    Item Details
                  </Typography>
                  <InlineContainer gap="1rem" alignItems="start">
                    <Box width="250px">
                      <Select
                        {...formik.getFieldProps(`items[${index}].quantity_measurement`)}
                        {...getFieldErrors(`items[${index}].quantity_measurement`, formik)}
                        label="Select Type"
                        sx={{ width: "100%" }}
                        disabled={item?.is_track_inventory}
                      >
                        <MenuItem value={QuantityMeasurement.UNIT}>Quantity</MenuItem>
                        <MenuItem value={QuantityMeasurement.HOURLY}>Hours</MenuItem>
                      </Select>
                    </Box>
                    <Box width="80px">
                      <TextField
                        label={
                          formik?.values?.items?.[index]?.quantity_measurement === QuantityMeasurement.UNIT
                            ? "Qty"
                            : "Hr"
                        }
                        {...formik.getFieldProps(`items[${index}].quantity`)}
                        {...getFieldErrors(`items[${index}].quantity`, formik)}
                        onChange={(e) => {
                          formik.setValues({
                            items: formik?.values?.items?.map((i, idx) => ({
                              ...i,
                              ...(index === idx
                                ? {
                                    quantity: Number.isNaN(+e.target.value)
                                      ? i.quantity
                                      : e.target.value.endsWith(".")
                                        ? e.target.value
                                        : `${Math.round(+e.target.value * 100) / 100}`,
                                  }
                                : {}),
                            })),
                          })
                        }}
                        sx={{ width: "100%" }}
                      />
                    </Box>
                  </InlineContainer>
                  {item?.is_track_inventory && (
                    <Box width="250px">
                      <InventoryQuantity
                        alwaysVisible={false}
                        minimum={item?.inventory_reorder_point}
                        remaining={item?.inventory_on_hand}
                        sx={{ marginTop: "0.5rem" }}
                      />
                    </Box>
                  )}
                  {mode === "invoice" && selectedInvoiceQuote?.is_recurring && item?.is_track_inventory && (
                    <Typography variant="body2" color="primary.dark" sx={{ marginTop: "0.5rem", width: 350 }}>
                      This item's stock will be automatically updated every time this recurring invoice is sent.
                    </Typography>
                  )}
                  <Box marginTop="1.25rem" width="100%" textAlign="right">
                    <TextField
                      label="Item Description (Optional)"
                      {...formik.getFieldProps(`items[${index}].description`)}
                      {...getFieldErrors(`items[${index}].description`, formik)}
                      multiline
                      rows="3"
                      sx={{ width: "100%" }}
                      inputProps={{ maxLength: 250 }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        color: formik?.values?.items?.[index]?.description?.length >= 250 ? "error.dark" : "grey.600",
                        margin: "0.5rem 0 0 0",
                        textAlign: "right",
                      }}
                    >
                      {formik?.values?.items?.[index]?.description?.length ?? 0} / 250
                    </Typography>
                  </Box>
                  <DashedDivider spacing={3} />
                  <InlineContainer justifyContent="space-between" marginBottom="0.75rem">
                    <Typography variant="body1" sx={{ fontWeight: 400, marginBottom: "0.75rem" }}>
                      Discount or Surcharge (Optional)
                    </Typography>
                    <NeutralButton
                      disableElevation
                      size="small"
                      onClick={() => handleAddNewPriceModifier(formik.values.items?.[index])}
                      variant="outlined"
                      startIcon={<PlusIcon fontSize="small" />}
                    >
                      Create New
                    </NeutralButton>
                  </InlineContainer>
                  <Select
                    label="Discount or Surcharge"
                    {...formik.getFieldProps(`items[${index}].account_pricemodifier_id`)}
                    onChange={(e) => {
                      const priceModifier = priceModifiers?.results?.find(({ id }) => id === e.target.value)
                      formik.setValues({
                        items: formik?.values?.items?.map((i, idx) => ({
                          ...i,
                          ...(index === idx
                            ? {
                                account_pricemodifier_id: e.target.value || null,
                                pricemodifier_name: priceModifier?.name || null,
                                pricemodifier_value_flat: priceModifier?.value_flat || null,
                                pricemodifier_value_pct: priceModifier?.value_pct || null,
                              }
                            : {}),
                        })),
                      })
                    }}
                    value={
                      !formik?.values?.items?.[index]?.account_pricemodifier_id &&
                      formik?.values?.items?.[index]?.pricemodifier_name
                        ? "adhoc"
                        : formik?.values?.items?.[index]?.account_pricemodifier_id
                    }
                  >
                    <MenuItem value="">
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        <em>None</em>
                      </Typography>
                    </MenuItem>
                    {!formik?.values?.items?.[index]?.account_pricemodifier_id &&
                      formik?.values?.items?.[index]?.pricemodifier_name && (
                        <MenuItem value="adhoc">
                          <InlineContainer justifyContent="space-between" width="100%">
                            <Typography variant="body1">
                              {formik?.values?.items?.[index]?.pricemodifier_name}
                              <span>
                                {(
                                  formik?.values?.items?.[index]?.pricemodifier_value_pct
                                    ? formik?.values?.items?.[index]?.pricemodifier_value_pct < 0
                                    : formik?.values?.items?.[index]?.pricemodifier_value_flat < 0
                                )
                                  ? " (Discount)"
                                  : " (Surcharge)"}
                              </span>
                            </Typography>
                            <Typography variant="body1">
                              <FormattedNumber
                                prefix={!formik?.values?.items?.[index]?.pricemodifier_value_pct ? "$" : ""}
                                value={
                                  getPercentFromFloat(formik?.values?.items?.[index]?.pricemodifier_value_pct) ||
                                  getDollarsFromInt(formik?.values?.items?.[index]?.pricemodifier_value_flat)
                                }
                                suffix={formik?.values?.items?.[index]?.pricemodifier_value_pct ? "%" : ""}
                              />
                            </Typography>
                          </InlineContainer>
                        </MenuItem>
                      )}
                    {priceModifiers?.results?.map((mod) => {
                      const isPercent = !!mod?.value_pct
                      const value = getPriceModifierValue(mod)

                      return (
                        <MenuItem value={mod?.id} key={`invoice-quote-item-mod-${mod?.id}`}>
                          <InlineContainer justifyContent="space-between" width="100%">
                            <Typography variant="body1">
                              {mod?.name}
                              <span> ({value < 0 ? "Discount" : "Surcharge"})</span>
                            </Typography>
                            <Typography variant="body1">
                              <FormattedNumber
                                prefix={!isPercent ? "$" : ""}
                                value={value}
                                suffix={isPercent ? "%" : ""}
                              />
                            </Typography>
                          </InlineContainer>
                        </MenuItem>
                      )
                    })}
                  </Select>
                  <DashedDivider spacing={3} />
                  <InlineContainer justifyContent="space-between" marginBottom="0.75rem">
                    <Typography variant="body1" sx={{ fontWeight: 400 }}>
                      Service For (Optional)
                    </Typography>
                    <NeutralButton
                      disableElevation
                      size="small"
                      onClick={() => handleAddNewServiceFor(index)}
                      startIcon={<PlusIcon fontSize="small" />}
                      disabled={!customerId || selectedInvoiceQuote?.customers?.length > 1}
                    >
                      Create New
                    </NeutralButton>
                  </InlineContainer>
                  <Select
                    label="Service For"
                    disabled={!customerId || customerCategories?.length === 0}
                    {...formik.getFieldProps(`items[${index}].account_customer_category_id`)}
                  >
                    <MenuItem value={INDIVIDUAL_ITEMS}>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        <em>None</em>
                      </Typography>
                    </MenuItem>
                    {customerCategories?.map((cat) => {
                      return (
                        <MenuItem value={cat?.id} key={`invoice-customer-category-${cat?.id}`}>
                          <Typography variant="body1">
                            <StatusIndicator status={cat?.status} sx={{ marginRight: "0.75rem" }} />
                            {cat?.name}
                          </Typography>
                        </MenuItem>
                      )
                    })}
                  </Select>
                  <Typography variant="body2" sx={{ color: "grey", marginTop: "0.5rem" }}>
                    {serviceForText()}
                  </Typography>
                </Box>
              </Collapse>
            </Box>
          ))}
        </DialogContent>
        <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
          <InlineContainer justifyContent="space-between" width="100%">
            <NeutralButton
              onClick={handleCloseModal}
              startIcon={!isEditing && <LeftArrowIcon fontSize="small" />}
              disableElevation
            >
              {isEditing ? "Cancel" : "Back"}
            </NeutralButton>
            <AffirmativeButton onClick={() => formik.handleSubmit()} sx={{ width: 180 }}>
              {isEditing
                ? `Update Item`
                : selectedPriceItems?.length === 1
                  ? `Add to ${mode === "invoice" ? "Invoice" : "Quote"}`
                  : `Add ${selectedPriceItems?.length} to ${mode === "invoice" ? "Invoice" : "Quote"}`}
            </AffirmativeButton>
          </InlineContainer>
        </DialogActions>
      </MuiDialog>
    )
  },
)
