import { User } from "@/types"

export const unformatPhoneNumber = (phoneNumber: string) => {
  let number = phoneNumber?.replace(/[\+\(\)\-\s]/g, "")

  if (number?.length === 10 && number?.charAt(0) !== "1") {
    number = `1${number}`
  }

  return number
}

export const adaptUser = (user: Partial<User>) => {
  return {
    ...user,
    ...(user?.cell_phone ? { cell_phone: unformatPhoneNumber(user?.cell_phone) } : {}),
  }
}
