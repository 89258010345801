import { InvoiceItem, QuoteItem } from "@/types"
import sortBy from "lodash/sortBy"

export const INDIVIDUAL_ITEMS = "INDIVIDUAL_ITEMS"

export const calculateItemGroup = (items: QuoteItem[] | InvoiceItem[] = []) => {
  const c: { [x: string]: QuoteItem[] } = {
    [INDIVIDUAL_ITEMS]: [],
  }
  const categorySortOrder = {
    [INDIVIDUAL_ITEMS]: 0,
  }
  items?.forEach((i, idx) => {
    if (i.account_customer_category_id && !c[i.account_customer_category_id]) {
      c[i.account_customer_category_id] = []
      if (i.account_customer_category_id !== INDIVIDUAL_ITEMS) {
        categorySortOrder[i.account_customer_category_id] = Object.keys(categorySortOrder).length
      }
    }

    c[i.account_customer_category_id || INDIVIDUAL_ITEMS].push({
      ...i,
      sort_order: idx,
    })
  })

  const categories = sortBy(Object.keys(c), (k) => categorySortOrder[k])

  return {
    categories,
    itemDict: c,
    categorySortOrder,
  }
}

export const reorderItems = (items) => {
  const incomingItems: any[] = [[]]

  const categories = {}

  items?.forEach((item) => {
    if (!item.account_customer_category_id || item.account_customer_category_id === INDIVIDUAL_ITEMS) {
      incomingItems[0].push(item)
    } else {
      if (!categories[item.account_customer_category_id]) {
        categories[item.account_customer_category_id] = incomingItems.length
        incomingItems.push([])
      }
      incomingItems[categories[item.account_customer_category_id]].push(item)
    }
  })

  const newItems = incomingItems.flat().map((item) => {
    const discount = (item?.pricemodifiers || []).find((pm) => !pm.is_tax)

    return {
      ...item,
      taxes: (item?.pricemodifiers || []).filter((pm) => pm.is_tax),
      pricemodifier_name: discount?.name || null,
      pricemodifier_value_flat: discount?.value_flat || null,
      pricemodifier_value_pct: discount?.value_pct || null,
    }
  })
  return newItems
}
