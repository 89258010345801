import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const ApplePayIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="59" height="24" viewBox="0 0 59 24" fill="none" {...props}>
      <path
        d="M10.6871 3.0942C10.002 3.90485 8.90594 4.54424 7.80984 4.4529C7.67283 3.3568 8.20946 2.1922 8.83744 1.47288C9.5225 0.639391 10.7214 0.0456708 11.6919 0C11.806 1.14177 11.3607 2.2607 10.6871 3.0942ZM11.6804 4.66984C10.0934 4.5785 8.73468 5.57184 7.98111 5.57184C7.21612 5.57184 6.06294 4.71551 4.80699 4.73834C3.17426 4.76118 1.65571 5.68601 0.822213 7.1589C-0.890441 10.1047 0.376923 14.4662 2.03249 16.8639C2.84315 18.0514 3.81365 19.353 5.09243 19.3073C6.30271 19.2617 6.78225 18.5195 8.24372 18.5195C9.7166 18.5195 10.1391 19.3073 11.4178 19.2845C12.7423 19.2617 13.5758 18.0971 14.3864 16.9096C15.3113 15.5623 15.6881 14.2493 15.7109 14.1808C15.6881 14.1579 13.1533 13.1874 13.1305 10.2645C13.1077 7.82112 15.1286 6.65652 15.2199 6.58801C14.0782 4.89819 12.297 4.71551 11.6804 4.66984ZM20.8489 1.35871V19.1589H23.6119V13.0733H27.4369C30.9307 13.0733 33.3855 10.6755 33.3855 7.20457C33.3855 3.73359 30.9764 1.35871 27.5282 1.35871H20.8489ZM23.6119 3.68792H26.7975C29.1952 3.68792 30.5653 4.9667 30.5653 7.21598C30.5653 9.46527 29.1952 10.7555 26.7861 10.7555H23.6119V3.68792ZM38.4321 19.2959C40.1676 19.2959 41.7775 18.4167 42.5082 17.0238H42.5653V19.1589H45.1229V10.2988C45.1229 7.72978 43.0677 6.07422 39.905 6.07422C36.9706 6.07422 34.8013 7.75262 34.7214 10.059H37.2104C37.4159 8.96289 38.4321 8.24358 39.8251 8.24358C41.5149 8.24358 42.4626 9.0314 42.4626 10.4814V11.4634L39.0144 11.6689C35.806 11.863 34.0705 13.176 34.0705 15.4596C34.0705 17.7659 35.8631 19.2959 38.4321 19.2959ZM39.1743 17.1836C37.7014 17.1836 36.7651 16.4757 36.7651 15.3911C36.7651 14.2721 37.6671 13.6213 39.3912 13.5186L42.4626 13.3245V14.3292C42.4626 15.9962 41.0468 17.1836 39.1743 17.1836ZM48.5368 24C51.2314 24 52.4987 22.9724 53.6062 19.8554L58.4588 6.24548H55.65L52.396 16.7612H52.3389L49.0848 6.24548H46.1961L50.8774 19.2046L50.6262 19.9924C50.2038 21.3283 49.5187 21.8421 48.297 21.8421C48.0801 21.8421 47.6576 21.8192 47.4863 21.7964V23.9315C47.6462 23.9772 48.3313 24 48.5368 24Z"
        fill="black"
      />
    </SvgIcon>
  )
}

export default ApplePayIcon
