import NiceModal, { useModal } from "@ebay/nice-modal-react"
import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import InlineContainer from "@/components/InlineContainer"
import Typography from "@mui/material/Typography"
import CheckIcon from "@mui/icons-material/CheckCircleOutlineRounded"
import FormattedNumber from "@/components/FormattedNumber"
import Link from "@/components/Link"
import AffirmativeButton from "@/components/buttons/AffirmativeButton"
import { useRouter } from "next/router"

export default NiceModal.create<{ customerName: string; quoteTotal: number }>(({ customerName, quoteTotal }) => {
  const modal = useModal()
  const router = useRouter()

  return (
    <Dialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      onClose={() => {
        modal.hide()
      }}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogContent sx={{ textAlign: "center" }} dividers>
        <Box maxWidth="75%" margin="0 auto">
          <Typography variant="h1">
            <CheckIcon sx={{ color: "success.light", fontSize: "4rem" }} />
          </Typography>
          <Typography variant="h5">Quote sent successfully!</Typography>
          <Typography variant="body2" marginTop="0.75rem">
            Quote Amount
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            <FormattedNumber prefix="$" value={quoteTotal} />
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {customerName}
          </Typography>
          <Typography variant="body2" margin="0.75rem 0 0.5rem">
            You can view the status of this
            <br />
            quote in the{" "}
            <Link
              onClick={() => {
                modal.hide()
                router.push(`/quotes`)
              }}
            >
              Quote Tab
            </Link>
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="center" width="100%">
          <AffirmativeButton
            fullWidth
            onClick={() => {
              modal.resolve()
              modal.hide()
            }}
            sx={{ maxWidth: "60%" }}
          >
            Done!
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </Dialog>
  )
})
