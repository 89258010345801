import { Breakpoints, darken, lighten, PaletteMode, ThemeOptions, PaletteOptions } from "@mui/material"

// TODO: Setup the theme based on the latest
// rewrite designs from the Finli design team.
export const getColorOptions = (mode: "light" | "dark"): PaletteOptions => {
  const light = {
    primary: {
      main: "#355CF4",
      light: "#CEE0FD",
      lighter: lighten("#355CF4", 0.4),
      dark: "#002F95",
      darker: "#000C66",
    },
    secondary: {
      main: "#5DC268",
    },
    custom: {
      autopay: "#0C5E06",
      approved: "#25913B",
      rejected: "#69012D",
      verified: "#57C421",
      unverified: "#B74F0A",
      draft: "#FFF0D0",
      scheduled: "#FFFFFF",
      issued: "#CEE0FD",
      sent: "#CEE0FD",
      pastDue: "#FDDBCC",
      complete: "#D7F0D9",
      invoiced: "#D7F0D9",
      canceled: "#C1CAD1",
    },
    error: {
      lighter: lighten("#D32F2F", 0.9),
      light: "#EF5350",
      main: "#D32F2F",
      dark: "#C62828",
      darker: darken("#D32F2F", 0.4),
    },
    warning: {
      lighter: lighten("#FF9800", 0.9),
      light: "#FF9800",
      main: "#ED6C02",
      dark: "#E65100",
    },
    info: {
      main: "#355CF4",
    },
    success: {
      light: "#4CAF50",
      main: "#2E7D32",
      dark: "#1B5E20",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    background: {
      default: "#FFFFFF",
      paper: "#FFFFFF",
    },
    divider: "rgba(0, 0, 0, 0.12)",
  }

  const dark = {
    primary: light.primary,
    secondary: light.secondary,
    custom: light.custom,
  }

  return mode === "dark" ? dark : light
}

export const breakpoints: { values: Breakpoints["values"] } = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    mobile: 0,
    tablet: 900,
    laptop: 1200,
    desktop: 1536,
  },
}

export const getThemeOptions = (
  mode: PaletteMode,
  primaryColor: string = "",
  secondaryColor: string = "",
): ThemeOptions => ({
  palette: {
    mode,
    ...getColorOptions(mode),
    ...(Boolean(primaryColor)
      ? {
          primary: {
            main: primaryColor,
            light: lighten(primaryColor, 0.25),
            lighter: lighten(primaryColor, 0.5),
            darker: darken(primaryColor, 0.5),
            dark: darken(primaryColor, 0.25),
          },
        }
      : {}),
    ...(Boolean(secondaryColor)
      ? {
          secondary: {
            main: secondaryColor,
            light: lighten(secondaryColor, 0.3),
            lighter: lighten(secondaryColor, 0.5),
            dark: darken(secondaryColor, 0.3),
            darker: darken(secondaryColor, 0.5),
          },
        }
      : {}),
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    fontSize: 16, // NOTE: default font-size of MUI is 14px
    h1: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: 49,
      fontWeight: 700,
      lineHeight: "130%",
    },
    h2: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: 39,
      fontWeight: 700,
      lineHeight: "130%",
    },
    h3: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: 31,
      fontWeight: 700,
      lineHeight: "150%",
    },
    h4: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: 25,
      fontWeight: 700,
      lineHeight: "150%",
    },
    h5: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: 20,
      fontWeight: 600,
      lineHeight: "150%",
    },
    h6: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: 18,
      fontWeight: 600,
      lineHeight: "150%",
    },
    subtext: {
      fontFamily: "Poppins, sans-serif",
      fontSize: 12,
    },
    body1: {
      fontFamily: "Poppins, sans-serif",
      fontSize: 16,
    },
    body2: {
      fontFamily: "Poppins, sans-serif",
      fontSize: 14,
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { size: "large" },
          style: {
            borderRadius: "2rem",
            fontSize: 20,
            fontWeight: 700,
            paddingLeft: 16,
            paddingRight: 16,
            textTransform: "none",
          },
        },
        {
          props: { size: "medium" },
          style: {
            borderRadius: "2rem",
            fontSize: 16,
            fontWeight: 700,
            paddingLeft: 16,
            paddingRight: 16,
            textTransform: "none",
          },
        },
        {
          props: { size: "small" },
          style: {
            borderRadius: "2rem",
            fontSize: 14,
            fontWeight: 700,
            paddingLeft: 16,
            paddingRight: 16,
            textTransform: "none",
          },
        },
      ],
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "currentColor",
          fontFamily: "Poppins, sans-serif",
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          color: "currentColor",
          fill: "currentColor",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          whiteSpace: "nowrap",
        },
      },
    },
  },
  breakpoints,
  shape: {
    borderRadius: 10,
  },
})

export default getThemeOptions
