import { createSelector } from "@reduxjs/toolkit"
import type { RootState } from "@/config/store"
import { QuoteCustomer, QuoteItem } from "@/types"

export const selectQuote = ({ quote }: RootState) => quote.quote

export const selectQuotePreview = ({ quote }: RootState) => quote.quotePreview

export const selectQuoteItems = createSelector([selectQuote], (quote) => quote?.items ?? ([] as QuoteItem[]))

export const selectQuoteItemIds = createSelector([selectQuoteItems], (items) =>
  items?.map((i) => i?.account_priceitem_id),
)

export const selectQuoteCustomers = createSelector(
  [selectQuote],
  (quote) => quote?.customers ?? ([{}] as QuoteCustomer[]),
)
