import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const CardIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path d="M27.2 4.7998H4.8C3.246 4.7998 2.014 6.0458 2.014 7.5998L2 24.3998C2 25.9538 3.246 27.1998 4.8 27.1998H27.2C28.754 27.1998 30 25.9538 30 24.3998V7.5998C30 6.0458 28.754 4.7998 27.2 4.7998ZM25.8 24.3998H6.2C5.43 24.3998 4.8 23.7698 4.8 22.9998V15.9998H27.2V22.9998C27.2 23.7698 26.57 24.3998 25.8 24.3998ZM27.2 10.3998H4.8V7.5998H27.2V10.3998Z" />
    </SvgIcon>
  )
}

export default CardIcon
