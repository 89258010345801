import { RadioLabel } from "@/features/onboarding/components"
import { FormLabel } from "@/features/auth/components/styled"
import TextField from "@/components/TextField"
import getFieldErrors from "@/utils/getFieldErrors"
import Box from "@mui/material/Box"
import Select from "@/components/Select"
import { customerTypeList, ServicesList } from "@/types"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import RadioGroup from "@mui/material/RadioGroup"
import Radio from "@mui/material/Radio"
import Typography from "@mui/material/Typography"
import MenuItem from "@mui/material/MenuItem"
import FormHelperText from "@mui/material/FormHelperText"

const BusinessService = ({ formik }) => {
  return (
    <>
      <Typography variant="h4">Tell us more about your business.</Typography>
      <Box marginTop="2rem" width="100%">
        <Box>
          <FormLabel sx={{ marginBottom: "6px" }}>What services do you offer?</FormLabel>
          <Select
            placeholder="Business Services"
            sx={{
              fontSize: "1rem",
              maxWidth: "100%",
            }}
            SelectProps={{
              renderValue: (value) => {
                const option = ServicesList.find((option) => option.value === value)

                if (option) {
                  return <Typography variant="body1">{option?.title}</Typography>
                } else {
                  return <Typography variant="body1">Select Type</Typography>
                }
              },
            }}
            {...formik.getFieldProps("service_type")}
            {...getFieldErrors("service_type", formik)}
          >
            <MenuItem
              disabled
              value="x"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography variant="body1">Select Type</Typography>
            </MenuItem>
            {ServicesList.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "start",
                  maxWidth: 500,
                }}
              >
                <Box width="340px">
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {option.title}
                  </Typography>
                  <Typography variant="body2" noWrap sx={{ maxWidth: 500 }}>
                    {option.description}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box marginTop="1rem">
          <FormLabel>Who are your customers?</FormLabel>
          <FormControl
            sx={{ width: "100%" }}
            error={getFieldErrors("customer_type", formik, { ignoreTouched: true })?.error}
          >
            <FormHelperText
              sx={{
                marginTop: "1rem",
                marginLeft: "0",
                marginBottom: "-0.5rem",
              }}
            >
              {getFieldErrors("customer_type", formik, { ignoreTouched: true })?.helperText}
            </FormHelperText>
            <RadioGroup>
              {customerTypeList.map((type) => (
                <RadioLabel
                  key={type.title}
                  sx={{
                    borderColor: getFieldErrors("customer_type", formik, {
                      ignoreTouched: true,
                    })?.error
                      ? "error.main"
                      : "",
                  }}
                >
                  <FormControlLabel
                    {...formik.getFieldProps("customer_type")}
                    control={<Radio size="small" checked={type.value === formik.values.customer_type} />}
                    value={type.value}
                    label={type.title}
                  />
                </RadioLabel>
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
        <Box marginTop="1rem">
          <FormLabel sx={{ marginBottom: "6px" }}>Briefly describe the product or service you provide.</FormLabel>
          <TextField
            size="small"
            color="primary"
            variant="outlined"
            fullWidth
            placeholder="e.g. I build websites"
            sx={{ fontSize: "1rem" }}
            {...formik.getFieldProps("description")}
            {...getFieldErrors("description", formik)}
            inputProps={{ maxLength: 200 }}
          />
          <Typography
            variant="body2"
            sx={{
              color: formik?.values?.description?.length >= 200 ? "error.dark" : "grey.600",
              margin: "0.5rem 0 0 0",
              textAlign: "right",
            }}
          >
            {formik?.values?.description?.length ?? 0} / 200
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default BusinessService
