import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const TrashIcon = (props: SvgIconProps) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    fill="transparent"
    viewBox="0 0 14 16"
    stroke="currentColor"
    strokeWidth={0}
    width="16"
    height="14"
    style={{ padding: "0.125rem" }}
    {...props}
  >
    <path d="M2.43742 15.5C2.10409 15.5 1.81242 15.375 1.56242 15.125C1.31242 14.875 1.18742 14.5833 1.18742 14.25V2.375H0.958252C0.777696 2.375 0.628391 2.31597 0.510335 2.19792C0.39228 2.07986 0.333252 1.93056 0.333252 1.75C0.333252 1.56944 0.39228 1.42014 0.510335 1.30208C0.628391 1.18403 0.777696 1.125 0.958252 1.125H4.24992C4.24992 0.944444 4.30895 0.795139 4.427 0.677083C4.54506 0.559028 4.69436 0.5 4.87492 0.5H9.12492C9.30547 0.5 9.45478 0.559028 9.57283 0.677083C9.69089 0.795139 9.74992 0.944444 9.74992 1.125H13.0416C13.2221 1.125 13.3714 1.18403 13.4895 1.30208C13.6076 1.42014 13.6666 1.56944 13.6666 1.75C13.6666 1.93056 13.6076 2.07986 13.4895 2.19792C13.3714 2.31597 13.2221 2.375 13.0416 2.375H12.8124V14.25C12.8124 14.5833 12.6874 14.875 12.4374 15.125C12.1874 15.375 11.8958 15.5 11.5624 15.5H2.43742ZM2.43742 2.375V14.25H11.5624V2.375H2.43742ZM4.64575 11.8333C4.64575 12.0139 4.70478 12.1632 4.82283 12.2812C4.94089 12.3993 5.0902 12.4583 5.27075 12.4583C5.45131 12.4583 5.60061 12.3993 5.71867 12.2812C5.83672 12.1632 5.89575 12.0139 5.89575 11.8333V4.77083C5.89575 4.59028 5.83672 4.44097 5.71867 4.32292C5.60061 4.20486 5.45131 4.14583 5.27075 4.14583C5.0902 4.14583 4.94089 4.20486 4.82283 4.32292C4.70478 4.44097 4.64575 4.59028 4.64575 4.77083V11.8333ZM8.10408 11.8333C8.10408 12.0139 8.16311 12.1632 8.28117 12.2812C8.39922 12.3993 8.54853 12.4583 8.72908 12.4583C8.90964 12.4583 9.05895 12.3993 9.177 12.2812C9.29506 12.1632 9.35408 12.0139 9.35408 11.8333V4.77083C9.35408 4.59028 9.29506 4.44097 9.177 4.32292C9.05895 4.20486 8.90964 4.14583 8.72908 4.14583C8.54853 4.14583 8.39922 4.20486 8.28117 4.32292C8.16311 4.44097 8.10408 4.59028 8.10408 4.77083V11.8333ZM2.43742 2.375V14.25V2.375Z" />
  </SvgIcon>
)

export default TrashIcon
