import { RootState } from "@/config/store"

import { createSelector } from "@reduxjs/toolkit"

export const selectLayout = (state: RootState) => state.layout

export const selectCollapsedNav = createSelector([selectLayout], (layout) => Boolean(layout?.collapseNav))

export const selectShowMobileMenu = createSelector([selectLayout], (layout) => Boolean(layout?.showMobileMenu))

export const selectGlobalSearchEnabled = createSelector([selectLayout], (layout) => Boolean(layout?.showSearch))

export const selectGlobalSearchQuery = createSelector([selectLayout], (layout) => layout?.searchQuery ?? "")

export const selectColorMode = createSelector([selectLayout], (layout) => layout?.colorMode ?? "light")

export const selectPartnerTheme = createSelector([selectLayout], (layout) => layout?.theme ?? null)

export const selectIsWhiteLabeled = createSelector([selectLayout], (layout) => !!layout?.theme?.primaryColor)

export const selectTableCollapseState = createSelector([selectLayout], (layout) => layout?.tableCollapseState ?? {})

export const selectIsAnyRowExpanded = createSelector(
  [selectTableCollapseState],
  (tableCollapseState) => Object.values(tableCollapseState).some((collapseState) => collapseState) ?? {},
)
