import { DateTime } from "luxon"

const getFutureYears = (count = 10) => {
  const thisYear = DateTime.now().year
  const list: number[] = []
  var i = thisYear

  while (i <= thisYear + count) {
    list.push(i)
    i++
  }

  return list
}

export default getFutureYears
