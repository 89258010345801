import Tooltip from "@mui/material/Tooltip"
import InfoOutlineIcon from "@/components/icons/InfoOutlineIcon"
import NiceModal, { useModal } from "@ebay/nice-modal-react"
import { Dialog, DialogContent, DialogActions } from "@/components/Dialog"
import InlineContainer from "@/components/InlineContainer"
import Typography from "@mui/material/Typography"
import NeutralButton from "@/components/buttons/NeutralButton"
import { PaymentAction, Query } from "@/types"
import TextField from "@/components/TextField"
import { refundPayment } from "@/features/payment-management/payment-management-queries"
import { useTheme, useMediaQuery, Box } from "@mui/material"
import { useSnackbar } from "notistack"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import AffirmativeButton from "@/components/buttons/AffirmativeButton"
import { useEffect, useState } from "react"
import { formatDollar } from "@/utils/formatters"
import Link from "next/link"
import NumberField from "@/components/NumberField"
import { fetchInvoice } from "@/features/invoice-management/invoice-management-queries"
import useAppDispatch from "@/hooks/useAppDispatch"
import { refetchInvoice } from "@/features/invoice-management/invoice-management-reducers"
import { Divider } from "../../account-management/components/styled"

export default NiceModal.create<{
  payment
  refundAction: PaymentAction.FULL_REFUND | PaymentAction.PARTIAL_REFUND
  isAdmin?: boolean
}>(({ payment, refundAction, isAdmin = false }) => {
  const modal = useModal()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()

  const [note, setNote] = useState("")
  const maxRefundAmount = payment?.amount - payment?.refund_amount
  const [refundAmount, setRefundAmount] = useState(maxRefundAmount)
  const transactionFee = payment?.transaction_fee
    ? Math.max(Math.round((refundAmount * 3) / (payment.transaction_fee_payer === "PAY_IN" ? 103 : 100)), 1)
    : 0

  const allowPartialRefund = refundAction === PaymentAction.PARTIAL_REFUND

  const { refetch: refetchInvoiceQuery } = useQuery({
    queryKey: [Query.account.INVOICE, payment?.invoice_id],
    queryFn: () => fetchInvoice(payment?.invoice_id),
    onSuccess: (data) => {
      dispatch(refetchInvoice(data))
    },
    enabled: false,
  })

  useEffect(() => {
    setRefundAmount(maxRefundAmount)
  }, [maxRefundAmount])

  const { mutate: refund } = useMutation(
    (values: { paymentId: string; note?: string; amount?: number }) => refundPayment({ ...values, isAdmin }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([Query.account.PAYMENTS])
        queryClient.invalidateQueries([Query.account.INVOICE, payment?.invoice_id])
        queryClient.invalidateQueries([Query.admin.PAYMENTS_PAGINATION])
        enqueueSnackbar("Payment refunded successfully!", {
          variant: "success",
        })
        refetchInvoiceQuery()
        setNote("")

        modal.resolve()
        modal.hide()
      },
      onError: (error: any) => {
        enqueueSnackbar(error?.response?.data?.message ?? "Cannot refund payment.", { variant: "error" })
      },
    },
  )

  const noteMaxLength = 250

  const isCustomerPayTransactionFee = payment?.transaction_fee_payer === "PAY_IN"

  return (
    <Dialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      onClose={() => {
        modal.hide()
        setNote("")
      }}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
      title="Refund Payment"
    >
      <DialogContent>
        <Typography variant="body1" sx={{ fontWeight: 600, marginBottom: "0.5rem" }}>
          Refund amount
          {!allowPartialRefund && (
            <Tooltip title="In order to make a partial refund, the payment must be “Paid”" placement="top">
              {/* Requires a component to hold the ref */}
              <Box sx={{ display: "inline" }}>
                <InfoOutlineIcon
                  style={{
                    marginLeft: "0.5rem",
                    position: "relative",
                    top: "0.25rem",
                    fontSize: "1.3rem",
                  }}
                />
              </Box>
            </Tooltip>
          )}
        </Typography>
        {allowPartialRefund ? (
          <>
            <InlineContainer>
              <NumberField
                fullWidth
                placeholder="0.00"
                value={refundAmount / 100}
                decimalScale={2}
                fixedDecimalScale
                style={{ marginLeft: "0.3rem" }}
                prefix="$"
                isAllowed={(values) => {
                  return (values?.floatValue || 0) * 100 <= maxRefundAmount
                }}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    setRefundAmount(
                      Math.min(maxRefundAmount, Number(e.target.value.replaceAll(",", "").replaceAll("$", "")) * 100),
                    )
                  }
                }}
              />
            </InlineContainer>
            <Typography sx={{ fontWeight: 400, color: "grey.600", fontSize: "0.9rem", margin: "0.5rem 0" }}>
              Total refundable amount: {formatDollar(maxRefundAmount)}
            </Typography>
          </>
        ) : (
          <Typography variant="body1">{formatDollar(payment?.amount)}</Typography>
        )}
        <Typography variant="body1" sx={{ fontWeight: 600, margin: "1rem 0 0.5rem" }}>
          Note (Optional)
        </Typography>
        <TextField
          value={note}
          multiline
          rows={5}
          sx={{ width: "100%" }}
          placeholder="Provide a reason for the refund"
          inputProps={{
            maxLength: noteMaxLength,
          }}
          onChange={(e) => {
            setNote(e.target.value.slice(0, noteMaxLength))
          }}
        />
        <Typography
          variant="body2"
          sx={{
            color: "grey.600",
            display: "block",
            margin: "0.5rem 0 0 0",
            textAlign: "right",
          }}
        >
          {note.length ?? 0} / {noteMaxLength}
        </Typography>
        {transactionFee ? (
          <>
            <Divider sx={{ margin: "1rem 0 !important" }} />
            <Typography variant="body1" sx={{ fontWeight: 600, marginBottom: "0.5rem" }}>
              Processing fee (Already Paid)
            </Typography>
            <Typography variant="body1">{formatDollar(transactionFee)}</Typography>
            <Typography variant="body1" sx={{ margin: "1rem 0" }}>
              {isCustomerPayTransactionFee ? "Your customer" : "Your business"} paid the processing fee.&nbsp;
              {isCustomerPayTransactionFee
                ? "To refund the full payment, this amount will be debited from your bank account."
                : "This amount will not be refunded to you."}
              &nbsp;
              <Link
                href="https://finli.com/terms-and-conditions/#:~:text=export%20control%20laws.-,Payment%20Services,-%E2%80%9CPayment%20Services%E2%80%9D%20are"
                target="_blank"
              >
                Learn more
              </Link>
              .
            </Typography>
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <InlineContainer
          width="100%"
          flexWrap="wrap"
          sx={{
            justifyContent: { xs: "center", sm: "space-between" },
          }}
        >
          <NeutralButton
            type="button"
            onClick={() => {
              modal.reject()
              modal.hide()
            }}
            disableElevation
            sx={{ margin: "0.25rem 0.5rem" }}
          >
            Cancel
          </NeutralButton>
          <AffirmativeButton
            type="submit"
            sx={{
              minWidth: 120,
              margin: "0.25rem 0.5rem",
            }}
            onClick={() => {
              refund({
                paymentId: payment?.id,
                note,
                amount: refundAmount,
              })
            }}
          >
            Confirm
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </Dialog>
  )
})
