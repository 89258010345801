import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const AutopayIcon = (props: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" fill="transparent" viewBox="0 0 19 17" width="19" height="17" {...props}>
    <path
      d="M4.21619 5.07553L5.76637 5.97055C6.15609 6.19555 6.07662 6.7732 5.64449 6.88165L1.83327 7.90288C1.56007 7.97608 1.29159 7.82108 1.21839 7.54788L0.197194 3.73665C0.0837185 3.3132 0.544244 2.95553 0.933944 3.18053L2.48414 4.07553C4.69414 0.247701 9.58452 -1.06267 13.4123 1.14733C14.313 1.66733 15.079 2.34055 15.6821 3.11598C16.1274 3.6848 15.8532 4.51958 15.1566 4.70625C14.7877 4.80508 14.3777 4.69535 14.1409 4.38543C13.6883 3.7893 13.0965 3.27438 12.4123 2.87938C9.54579 1.22438 5.87119 2.209 4.21619 5.07553ZM3.66812 11.4448C4.03694 11.346 4.45567 11.4607 4.68379 11.7657C5.14999 12.3581 5.72819 12.8767 6.41234 13.2717C9.27889 14.9267 12.9535 13.9421 14.6085 11.0756L13.0583 10.1806C12.6686 9.95555 12.7481 9.37788 13.1802 9.26943L16.9914 8.2482C17.2646 8.175 17.5331 8.33 17.6063 8.6032L18.6275 12.4144C18.741 12.8379 18.2804 13.1956 17.8994 12.9756L16.3405 12.0756C14.1305 15.9034 9.24017 17.2138 5.41234 15.0038C4.51167 14.4838 3.74564 13.8105 3.14257 13.0351C2.69734 12.4663 2.97144 11.6315 3.66812 11.4448Z"
      fill="currentColor"
    />
    <path
      d="M7.60938 11.25H6.34375L8.64808 4.70459H10.1118L12.4194 11.25H11.1538L9.40555 6.04692H9.3544L7.60938 11.25ZM7.65093 8.68364H11.1026V9.63604H7.65093V8.68364Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default AutopayIcon
