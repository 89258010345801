import RadioBox from "./RadioBox"
import { Typography, Box } from "@mui/material"
import Image from "next/legacy/image"
import { CardImage } from "@/components/DashboardCard"
import { useRouter } from "next/router"

const WelcomeRadioBox = (props: any) => {
  const router = useRouter()

  const { children, icon, link, handleCloseModal } = props

  return (
    <RadioBox
      active={false}
      onClick={() => {
        handleCloseModal()
        router.push(link)
      }}
      sx={{ padding: "0.2rem 0.5rem", maxWidth: "50%", height: "10rem" }}
    >
      <Box
        sx={(theme) => ({
          height: "75%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F6F9FB",
          borderRadius: `${theme.shape.borderRadius}px`,
        })}
      >
        <CardImage sx={{ height: "60%" }}>
          <Image src={icon} alt="feature icon" layout="fill" objectFit="contain" />
        </CardImage>
      </Box>
      <Typography variant="body1" noWrap>
        {children}
      </Typography>
    </RadioBox>
  )
}

export default WelcomeRadioBox
