import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { logoutThunk } from "@/features/auth/auth-queries"
import type { Quote, QuoteCustomer } from "@/types"
import { QuoteStatus } from "@/types"
import { HYDRATE } from "next-redux-wrapper"
import { RootState } from "@/config/store"
import assignIn from "lodash/assignIn"
import { reorderItems } from "../builder/utils"

const hydrate = createAction<RootState>(HYDRATE)

// parse quote from server into a redux state
export const parseQuoteResponseToQuoteState = (quote) => {
  const status = quote.status || QuoteStatus.DRAFT

  let customers: QuoteCustomer[] = []

  // If we receive
  if (Object.keys(quote?.customer ?? {}).length) {
    customers = [quote?.customer]
  } else if (Array.isArray(quote?.customers)) {
    customers = quote?.customers
  }

  const items = reorderItems(quote?.items)

  const quoteToReturn: Quote = {
    ...quote,
    customers,
    items,
    status,
    // general data that has changed, used for autosave
    has_changed: false,
    force_save: false,
    is_readonly:
      quote?.is_readonly ||
      [
        QuoteStatus.ISSUED,
        QuoteStatus.CANCELED,
        QuoteStatus.DELETED,
        QuoteStatus.APPROVED,
        QuoteStatus.INVOICED,
      ].includes(status),
  }

  delete quoteToReturn.customer

  return quoteToReturn
}

const emptyQuote = {
  account_pricemodifier_id: null,
  pricemodifier_name: null,
  pricemodifier_value_flat: null,
  pricemodifier_value_pct: null,
  status: QuoteStatus.DRAFT,
  recipient_message: "",
  due_at: null,
  expected_issue_at: null,
  series: null,
  items: [],
  customers: [],
  is_send_via_sms: true,
  is_send_via_email: true,
  taxes: [],
  force_save: false,
  is_readonly: false,
  has_changed: false,
}

export interface QuoteState {
  quote: Quote
  quotePreview: Quote
}

const initialState: QuoteState = {
  quote: emptyQuote as unknown as Quote,
  quotePreview: emptyQuote as unknown as Quote,
}

export const quoteSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {
    setQuote: (state, action: PayloadAction<Quote>) => {
      return {
        ...state,
        quote: {
          ...action.payload,
          ...(action?.payload?.customer ? { customers: [action.payload.customer] } : {}),
          items: reorderItems(action.payload.items || []),
        },
      }
    },
    refetchQuote: (_, action: PayloadAction<Quote | {} | null>) => {
      return {
        quote: parseQuoteResponseToQuoteState(action?.payload ?? {}),
        quotePreview: parseQuoteResponseToQuoteState(action?.payload ?? {}),
      }
    },

    setQuoteCustomers: (state, action: PayloadAction<QuoteCustomer[]>) => {
      return {
        ...state,
        quote: {
          ...state.quote,
          customers: [...action.payload],
        },
        quotePreview: {
          ...state.quotePreview,
          customers: [...action.payload],
        },
      }
    },
    resetQuote: (_, action: PayloadAction<undefined | Partial<Quote>>) => {
      return {
        quote: {
          ...initialState.quote,
          ...(typeof action?.payload === "object" ? action.payload : {}),
        },
        quotePreview: {
          ...initialState.quotePreview,
          ...(typeof action?.payload === "object" ? action.payload : {}),
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logoutThunk.fulfilled, () => initialState)
      .addCase(hydrate, (state, action) => {
        return assignIn(state, action?.payload?.auth)
      })
  },
})

export const { resetQuote, setQuote, setQuoteCustomers, refetchQuote } = quoteSlice.actions

export default quoteSlice.reducer
