import Box from "@mui/material/Box"
import InlineContainer from "@/components/InlineContainer"
import { styled } from "@mui/material"

export const ItemList = styled(Box)(
  () => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  margin: 0.75rem auto;
`,
)

export const ItemCard = styled(InlineContainer, {
  shouldForwardProp: (prop) => prop !== "highlight",
})<{ highlight?: boolean }>(
  ({ theme, highlight }) => `
  background-color: ${theme.palette.common.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${highlight ? theme.palette.primary.main : theme.palette.common.white};
  border-radius: ${theme.shape.borderRadius}px;
  cursor: pointer;
  padding: ${theme.spacing(1.5, 2)};
  width: 100%;

  &:hover {
    border-color: ${theme.palette.primary.main};
  }
`,
)
