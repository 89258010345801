import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const MoneyServicesIcon = (props: SvgIconProps) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    fill="transparent"
    stroke="none"
    width="18"
    height="19"
    viewBox="0 0 18 19"
    style={{ padding: "0.125rem" }}
    {...props}
  >
    <g clipPath="url(#clip0_1327_98816)">
      <path
        d="M17.25 6.5V14C17.25 14.825 16.575 15.5 15.75 15.5H3.75C3.3375 15.5 3 15.1625 3 14.75C3 14.3375 3.3375 14 3.75 14H15.75V6.5C15.75 6.0875 16.0875 5.75 16.5 5.75C16.9125 5.75 17.25 6.0875 17.25 6.5ZM3 12.5C1.755 12.5 0.75 11.495 0.75 10.25V5.75C0.75 4.505 1.755 3.5 3 3.5H12C13.245 3.5 14.25 4.505 14.25 5.75V11C14.25 11.825 13.575 12.5 12.75 12.5H3ZM5.25 8C5.25 9.245 6.255 10.25 7.5 10.25C8.745 10.25 9.75 9.245 9.75 8C9.75 6.755 8.745 5.75 7.5 5.75C6.255 5.75 5.25 6.755 5.25 8Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1327_98816">
        <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default MoneyServicesIcon
