import MuiDialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import NiceModal, { useModal } from "@ebay/nice-modal-react"
import Typography from "@mui/material/Typography"
import InlineContainer from "@/components/InlineContainer"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/CloseRounded"
import WelcomeRadioBox from "./WelcomeRadioBox"
import SimpleLink from "./SimpleLink"

export default NiceModal.create(() => {
  const modal = useModal()

  const handleCloseModal = () => {
    modal.hide()
  }

  return (
    <MuiDialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      onClose={handleCloseModal}
      keepMounted
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="flex-end">
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: (theme) => theme.palette.grey[500],
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "1.25rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Typography color="text.primary" variant="h3">
          Welcome to Finli!
        </Typography>
        <Typography variant="body2">What would you like to explore first?</Typography>
        <InlineContainer
          sx={{
            gap: { xs: "1rem", md: "2rem" },
            margin: "1.5rem 2rem 0rem 2rem",
          }}
        >
          <WelcomeRadioBox handleCloseModal={handleCloseModal} link={`/invoices/create`} icon={"/mail-with-color.svg"}>
            Invoices
          </WelcomeRadioBox>
          <WelcomeRadioBox handleCloseModal={handleCloseModal} link={`/pay-by-link`} icon={"/no-ach-fees.svg"}>
            Pay-by Links
          </WelcomeRadioBox>
        </InlineContainer>
        <InlineContainer
          sx={{
            gap: { xs: "1rem", md: "2rem" },
            margin: "1.5rem 2rem",
          }}
        >
          <WelcomeRadioBox handleCloseModal={handleCloseModal} link={`/quotes/create`} icon={"/faqs.svg"}>
            Quotes
          </WelcomeRadioBox>
          <WelcomeRadioBox handleCloseModal={handleCloseModal} link={`/customers`} icon={"/relations.svg"}>
            Customers
          </WelcomeRadioBox>
        </InlineContainer>
        <Typography variant="body2" sx={{ alignSelf: "flex-end" }}>
          <SimpleLink component="button" type="button" onClick={handleCloseModal}>
            No thanks, I’d like to explore on my own
          </SimpleLink>
        </Typography>
      </DialogContent>
    </MuiDialog>
  )
})
