import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const UnknownIcon = (props: SvgIconProps) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    fillRule="evenodd"
    clipRule="evenodd"
    style={{ width: 30, height: 22 }}
    {...props}
  >
    <path d="M22 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h20zm1 8H1v8a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1v-8zM8 16v1H3v-1h5zm13-2v1h-3v-1h3zm-10 0v1H3v-1h8zM1 8v2h22V8H1zm22-1V5a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2h22z" />
  </SvgIcon>
)

export default UnknownIcon
