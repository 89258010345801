import { styled } from "@mui/material"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import MuiCardContent from "@mui/material/CardContent"
import AffirmativeButton from "@/components/buttons/AffirmativeButton"

type CustomCardProps = {
  border?: boolean
  spacing?: "large" | "small" | "default" | string
  fullWidth?: boolean
  center?: boolean
}

export const DashboardCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== "spacing" && prop !== "border" && prop !== "fullWidth" && prop !== "center",
})<CustomCardProps>(
  ({ spacing = "default", border = false, fullWidth = false, theme }) => `
  display: flex;
  align-items: center;
  max-width: 100%;
  border-width: ${border ? "1px" : 0};
  border-style: ${border ? "solid" : "none"};
  border-color: ${border ? theme.palette.grey[300] : "transparent"};
  min-height: ${spacing === "large" ? "10rem" : "none"};
  max-height: ${spacing === "large" ? "10rem" : "none"};
  position: relative;
  padding: ${spacing === "large" ? theme.spacing(2) : spacing === "small" ? theme.spacing(1) : theme.spacing(2.5, 3)};
  width: ${fullWidth ? "100%" : "auto"};
  transition: width 250ms ease-in-out;

  ${
    spacing === "large"
      ? `
    & > .MuiCardContent-root {
      padding: ${theme.spacing(0, 2)};
    }

    & > .MuiCardContent-root:last-child {
      padding: ${theme.spacing(0, 2)};
    }
  `
      : ``
  }
`,
)

export const CardImage = styled(Box)(
  () => `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 88px;
  min-width: 60px;
  position: relative;
`,
)

export const CardContent = styled(MuiCardContent, {
  shouldForwardProp: (prop) => prop !== "spacing" && prop !== "border" && prop !== "fullWidth" && prop !== "center",
})<CustomCardProps>(
  ({ fullWidth = false, center = false, theme }) => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${center ? "center" : "left"};
  gap: 0.25rem;
  height: 100%;
  padding: ${theme.spacing(0)};
  text-align: ${center ? "center" : "left"};
  width: ${fullWidth ? "100%" : "auto"};
  
  &:last-child {
    padding: ${theme.spacing(0)};
  }
`,
)

export const VerifyButton = (props) => (
  <AffirmativeButton
    size="small"
    sx={{
      fontWeight: "600",
      marginTop: "0.5rem",
      width: "8rem",
    }}
    {...props}
  >
    {props?.children}
  </AffirmativeButton>
)
