import { useEffect } from "react"
import { createWalletInstrument, verifyWalletInstrument } from "@/features/wallet/wallet-queries"
import { usePlaidLink } from "react-plaid-link"
import { Query } from "@/types"
import useAppSelector from "@/hooks/useAppSelector"
import { useQueryClient } from "@tanstack/react-query"
import useAppDispatch from "@/hooks/useAppDispatch"
import {
  setWalletInstrumentId,
  setWalletInstrumentPlaidLinkType,
  tweakPlaidWidget,
} from "@/features/wallet/wallet-reducers"
import { selectWallet } from "@/features/wallet/wallet-selectors"

export const PlaidWidget = () => {
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()

  const { walletId, walletInstrumentId, plaidLinkToken, showPlaidWidget, account_type, account_subtype } =
    useAppSelector(selectWallet)

  const { open: openPlaid, ready } = usePlaidLink({
    env: process.env.ENV_NAME === "production" ? "production" : "sandbox",
    token: plaidLinkToken || "",
    onSuccess: async (public_token: string, metadata: any) => {
      localStorage.removeItem("plaid_link_token")
      localStorage.removeItem("plaid_link_origin_uri")

      let plaid_info: any = {
        account_id: metadata?.account?.id,
        public_token,
        account_type: metadata?.account?.class_type === "business" ? "BUSINESS" : "PERSONAL",
        account_subtype: metadata?.account?.subtype || "unknown",
        account_name: metadata?.account?.name,
        bank_name: metadata?.institution?.name || null,
        link_type: null,
        last_four_digits: metadata?.account?.mask,
      }

      if (metadata?.account?.verification_status === "pending_manual_verification") {
        plaid_info.link_type = "SAMEDAY_MICRO_DEPOSIT"
      } else if (metadata?.account?.verification_status === "manually_verified") {
        if (walletInstrumentId) {
          await verifyWalletInstrument(walletInstrumentId).then(() => {
            queryClient.invalidateQueries([Query.account.ACCOUNT_INFO])
            queryClient.invalidateQueries([Query.user.USER_INFO])
            queryClient.invalidateQueries([Query.user.PIL_INVOICE])
          })
        } else {
          plaid_info.link_type = "INSTANT_MICRO_DEPOSIT"
        }
      } else {
        plaid_info.link_type = "INSTANT_MATCH"
      }

      if (plaid_info.link_type) {
        await createWalletInstrument(walletId, {
          bank_account: {
            account_type,
            account_subtype,
          },
          plaid_info,
        })
          .then((data) => {
            queryClient.invalidateQueries([Query.account.ACCOUNT_INFO])
            queryClient.invalidateQueries([Query.user.USER_INFO])
            queryClient.invalidateQueries([Query.user.PIL_INVOICE])

            dispatch(setWalletInstrumentId(data.wallet_instrument_id as string))
            dispatch(setWalletInstrumentPlaidLinkType(plaid_info?.link_type))
          })
          .catch(() => {})
      }

      setTimeout(() => {
        dispatch(tweakPlaidWidget())
      }, 500)
    },
    onExit: () => {
      localStorage.removeItem("plaid_link_token")
      localStorage.removeItem("plaid_link_origin_uri")
      localStorage.removeItem("plaid_link_oauth_payload")

      setTimeout(() => {
        dispatch(tweakPlaidWidget())
      }, 500)
    },
  })

  useEffect(() => {
    if (showPlaidWidget && ready) {
      localStorage.setItem("plaid_link_token", plaidLinkToken)
      localStorage.setItem("plaid_link_origin_uri", window.location.href)
      openPlaid()
    }
  }, [showPlaidWidget, ready, openPlaid])

  return <></>
}
