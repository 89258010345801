import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { HYDRATE } from "next-redux-wrapper"
import assignIn from "lodash/assignIn"
import { logoutThunk } from "@/features/auth/auth-queries"
import { RootState } from "@/config/store"

export interface AuthState {
  accountId: string | null
  partnershipId: string | null
  adminRole: string | null
  spoof: boolean
}

const initialState: AuthState = {
  accountId: null,
  partnershipId: null,
  adminRole: null,
  spoof: false,
}

const hydrate = createAction<RootState>(HYDRATE)

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccountId: (state, action: PayloadAction<any>) => {
      state.accountId = action?.payload
    },
    setPartnershipId: (state, action: PayloadAction<any>) => {
      state.partnershipId = action?.payload
    },
    setAdminRole: (state, action: PayloadAction<any>) => {
      state.adminRole = action?.payload
    },
    setSpoof: (state, action: PayloadAction<any>) => {
      state.spoof = action?.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(hydrate, (state, action) => {
        return assignIn(state, action?.payload?.auth)
      })
      .addCase(logoutThunk.fulfilled, () => initialState)
  },
})

export const { setAccountId, setAdminRole, setSpoof, setPartnershipId } = authSlice.actions

export default authSlice.reducer
