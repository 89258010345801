import { AccountRoles, AuthValues, LoginValues, Query, User } from "@/types"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { FormikConfig, useFormik } from "formik"
import { Dispatch, SetStateAction, useState } from "react"
import { otcValidationSchema, loginValidationSchema, twoFaValidationSchema } from "@/features/auth/auth-validation"
import { getUser, login, sendOtc } from "@/features/auth/auth-queries"
import { identifyLogRocketUser } from "@/utils/logrocket"
import decodeToken from "@/utils/saveSessionToken"
import { setAdminRole } from "./auth-reducers"
import { useRouter } from "next/router"
import useAppDispatch from "@/hooks/useAppDispatch"
import { Message } from "@/hooks/useMessage"
import { formatPhone } from "@/utils/phone"
import { OnboardingSteps, onboardingPaths } from "@/features/onboarding/steps"

type UseLoginProps = {
  setMessage: Dispatch<SetStateAction<Message | null>>
  initialValues?: FormikConfig<AuthValues>["initialValues"]
  roleId?: string
  stayOnThisPage?: boolean
  onLoginSuccess?: () => void
  onLoginError?: (error?: any) => void
  onOtcSuccess?: () => void
  onOtcError?: (error?: any) => void
}

const defaultValues = {
  isPhone: 0,
  email_or_phone: "",
  password: "",
  email: "",
  googleAuthToken: "",
  otc_code: ["", "", "", "", "", ""],
  two_fa_token: ["", "", "", "", "", ""],
}

export const getPhoneOrEmail = (values) => ({
  ...(values?.isPhone ? { cell_phone: formatPhone(values?.email_or_phone) } : { email: values?.email_or_phone }),
})

const useLogin = ({
  setMessage,
  initialValues,
  roleId = undefined,
  stayOnThisPage = false,
  onLoginSuccess,
  onLoginError,
  onOtcSuccess,
  onOtcError,
}: UseLoginProps) => {
  const router = useRouter()
  const destination = router?.query?.dest as string
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  const [showOneTimeCodeInput, setShowOneTimeCodeInput] = useState(false)
  const [showTwoFaInput, setShowTwoFaInput] = useState(false)
  const [showPasswordInput, setShowPasswordInput] = useState(false)

  const { mutate: attemptLogin } = useMutation(
    async (credentials: LoginValues & { role_id?: string }) => await login(credentials),
    {
      onSuccess: async (response, input) => {
        formik.setSubmitting(false)

        if (
          (response?.must_verify_email && Boolean(input?.email)) ||
          (response?.must_verify_sms && Boolean(input?.cell_phone))
        ) {
          resetForm()
          setShowOneTimeCodeInput(true)
          setShowTwoFaInput(false)
          setShowPasswordInput(false)
          setMessage({
            type: "info",
            message: "We sent you a 6-digit code. Please check your email or mobile phone and enter the code below.",
          })
          return
        }

        if (response?.require_otp) {
          resetForm()
          setShowTwoFaInput(true)
          setShowOneTimeCodeInput(false)
          setShowPasswordInput(false)
          setMessage({
            type: "info",
            message: "Using your authentication app, please enter the 6-digit code below to continue with login.",
          })
          return
        }

        setMessage({
          type: "success",
          message: "Login successful!",
        })

        let redirectPath = "/"
        let partnershipId

        if (!response?.require_otp) {
          let decodedToken = decodeToken(response?.token)

          partnershipId = decodedToken?.partnershipId
          dispatch(setAdminRole(decodedToken?.adminRole || null))
        }

        if (typeof onLoginSuccess === "function") {
          onLoginSuccess()
        }

        const user = await getUser()

        identifyLogRocketUser(user as User)

        if (stayOnThisPage) {
          window.location.reload()
          return
        }

        if (response?.must_finish_user_setup) {
          return router.push({
            pathname:
              onboardingPaths[!response.has_password ? OnboardingSteps.USER_PASSWORD : OnboardingSteps.USER_NAME],
          })
        }

        const ownerOrMemberRoles = user?.roles.filter(({ type }) =>
          [AccountRoles.ACCOUNT_OWNER, AccountRoles.ACCOUNT_MEMBER].includes(type),
        )

        if (partnershipId) {
          redirectPath = "/partner"
        } else if (
          ownerOrMemberRoles?.length === 1 ||
          ownerOrMemberRoles?.some((r) => Boolean(r?.account?.partnership_id))
        ) {
          redirectPath = "/"
        } else if (ownerOrMemberRoles?.length === 0) {
          // normal payer login
          redirectPath = "/user/settings"
        } else {
          redirectPath = "/choose-account"
        }

        const route =
          Boolean(destination) && redirectPath?.includes("choose-account")
            ? `${redirectPath}?dest=${destination}`
            : Boolean(destination)
              ? destination
              : redirectPath

        if (route) {
          return router.push(route)
        }
      },
      onError: (error: any) => {
        formik.setSubmitting(false)
        if (formik?.values?.googleAuthToken) {
          setMessage({ type: "error", message: "Please Sign Up first." })
        } else {
          setMessage({
            type: "error",
            message: error.response.data.message || "Login failed. Please try again.",
          })
        }

        if (typeof onLoginError === "function") {
          onLoginError(error)
        }
      },
    },
  )

  const initiateLogin = async (values: AuthValues) => {
    formik.setSubmitting(true)
    // If has google auth token, only send email and token.
    // otherwise send cell phone or email and password or otc_code.
    const submission: LoginValues & { role_id?: string } = values?.googleAuthToken
      ? {
          google_auth_token: values?.googleAuthToken,
        }
      : {
          ...getPhoneOrEmail(values),
          password: values?.password,
          otc_code: values?.otc_code?.join(""),
          two_fa_token: values?.two_fa_token?.join(""),
          role_id: roleId,
        }

    attemptLogin(submission)
  }

  const { mutate: sendOneTimeCode } = useMutation(async () => await sendOtc(getPhoneOrEmail(formik.values)), {
    onSuccess: async () => {
      setShowOneTimeCodeInput(true)
      setShowTwoFaInput(false)
      setShowPasswordInput(false)
      setMessage({
        type: "info",
        message: `We sent you a 6-digit code. Please check your ${
          formik.values?.isPhone ? "phone" : "email"
        } and enter the code below.`,
      })

      formik.setSubmitting(false)

      queryClient.invalidateQueries([Query.user.USER_INFO])

      if (typeof onOtcSuccess === "function") {
        onOtcSuccess()
      }
    },
    onError: (error: any) => {
      setMessage({
        type: "error",
        message: error.response.data.message || "An error occured. Please try again.",
      })

      formik.setSubmitting(false)

      if (typeof onOtcError === "function") {
        onOtcError()
      }
    },
  })

  const formik = useFormik<AuthValues>({
    initialValues: {
      ...defaultValues,
      ...(Boolean(Object.keys(initialValues ?? {}).length)
        ? {
            isPhone: initialValues?.cell_phone ? 1 : 0,
            email_or_phone: initialValues?.email || initialValues?.cell_phone || "",
          }
        : {}),
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: showOneTimeCodeInput
      ? otcValidationSchema
      : showTwoFaInput
        ? twoFaValidationSchema
        : loginValidationSchema,

    onSubmit: initiateLogin,
  })

  const resetForm = () => {
    setMessage(null)
    formik.setFieldValue("otc_code", ["", "", "", "", "", ""])
    formik.setFieldValue("password", "")
  }

  return {
    showOneTimeCodeInput,
    showPasswordInput,
    showTwoFaInput,
    setShowOneTimeCodeInput,
    setShowPasswordInput,
    setShowTwoFaInput,
    resetForm,
    initiateLogin,
    loginFormik: formik,
    sendOneTimeCode: () => {
      resetForm()
      sendOneTimeCode()
    },
  }
}

export default useLogin
