import libphonenumber, { PhoneNumberFormat } from "google-libphonenumber"

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

export const intlFormat = (phoneNumber: string) => {
  let output = phoneNumber

  if (typeof phoneNumber !== "string") {
    return ""
  }

  if (output.substring(0, 2) === "+1") {
    return output
  }

  if (output.charAt(0) !== "1") {
    output = "1" + phoneNumber
  }

  output = output.replace("+", "")

  output = "+" + output

  return output
}

export const formatPhone = (phoneNumber: string, options?: { pretty?: boolean }) => {
  if (!Boolean(phoneNumber)) {
    return ""
  }

  try {
    const parsed = phoneUtil.parse(phoneNumber, "US")
    return phoneUtil.format(parsed, options?.pretty ? PhoneNumberFormat.NATIONAL : PhoneNumberFormat.E164)
  } catch (e) {
    return intlFormat(phoneNumber)
  }
}

export const validatePhone = (number: string, country = "US"): boolean => {
  // NOTE: phone number length < 3 will cause phoneUtil parse error
  if (!number || number.length < 3) {
    return false
  } else {
    try {
      const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(number, "US")

      return phoneUtil.isValidNumberForRegion(parsedPhoneNumber, country)
    } catch (err) {
      return false
    }
  }
}

// Note: renaming to be consistent with isValidEmail
export const isValidPhoneNumber = validatePhone

export const isPhoneNumberEmpty = (phone) => phone === "+1" || phone.includes("_") || !Boolean(phone)
