import { GetServerSidePropsContext } from "next"
import { initApi, routes } from "@/config/api"
import { PartnerAnalytics, Query } from "@/types"

export const partnerTeamQueries = [
  Query.partnership.TEAM,
  Query.partnership.TEAMS,
  Query.partnership.ALL_TEAM_MEMBERS,
  Query.partnership.TEAM_MEMBERS,
  Query.partnership.TEAM_MEMBER,
  Query.partnership.TEAM_BUSINESSES,
  Query.partnership.TEAM_MEMBER_TEAMS,
  Query.partnership.BUSINESS,
  Query.partnership.BUSINESS_TEAMS,
  Query.partnership.BUSINESSES_PAGINATION,
]

export const switchPartnership = async (partnershipId: string) => {
  return await initApi()
    .post(routes.user.context.switch, { partnershipId })
    .then(({ data }) => {
      return data
    })
}

export const fetchPartnerPublicInfo = async (partnershipId: string, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.admin.partnership.public(partnershipId))
    .then(({ data }) => data?.data)

export const fetchPartnerAnalytics = async (filters = {}, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get<{ data: PartnerAnalytics }>(routes.partnership.analytics(filters))
    .then(({ data }) => data?.data)

export const fetchPartnershipDetails = async (req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.partnership.base)
    .then(({ data }) => data?.data)

export const fetchPartnerReceivables = async (filters = {}, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.partnership.receivables(filters))
    .then(({ data }) => data?.data)

export const fetchPartnerBusinessReceivables = async (
  merchantId: string,
  filters = {},
  req?: GetServerSidePropsContext["req"],
) =>
  await initApi(req)
    .get(routes.partnership.businessReceivables(merchantId, filters))
    .then(({ data }) => data?.data)

export const fetchPartnerBusinesses = async (filters = {}, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.partnership.businesses(filters))
    .then(({ data }) => data?.data)

export const fetchPartnerTeamBusinesses = async (teamId: string, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.partnership.teamAccounts(teamId))
    .then(({ data }) => data?.data)

export const invitePartnerBusiness = async (email: string) =>
  await initApi()
    .post(routes.partnership.businessInvite, { email })
    .then(({ data }) => data?.data)

export const fetchPartnerBusiness = async (accountId: string, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.partnership.business(accountId))
    .then(({ data }) => data?.data ?? {})

export const removePartnerBusiness = async (accountId: string) =>
  await initApi()
    .delete(routes.partnership.business(accountId))
    .then(({ data }) => data?.data ?? {})

export const fetchPartnerBusinessTeams = async (accountId: string, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.partnership.accountTeams(accountId))
    .then(({ data }) => data?.data ?? {})

export const fetchPartnerTeamMember = async (memberId: string, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.partnership.role(memberId))
    .then(({ data }) => data?.data)

export const fetchPartnerTeamMemberTeams = async (memberId: string, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.partnership.roleTeams(memberId))
    .then(({ data }) => data?.data)

export const updatePartnerTeamMember = async (memberId: string, values: any) =>
  await initApi()
    .patch(routes.partnership.role(memberId), values)
    .then(({ data }) => data?.data)

export const createPartnerTeamMember = async (values: any) =>
  await initApi()
    .post(routes.partnership.role(), values)
    .then(({ data }) => data?.data)

export const deletePartnerTeamMember = async (memberId: string) =>
  await initApi()
    .delete(routes.partnership.role(memberId))
    .then(({ data }) => data?.data)

export const fetchPartnerAllTeamMembers = async (filters: any = {}, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.partnership.roles(filters))
    .then(({ data }) => data?.data)

export const fetchPartnerTeamMembers = async (teamId: string, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.partnership.teamRoles(teamId))
    .then(({ data }) => data?.data)

export const invitePartnerTeamMember = async (values: any) =>
  await initApi()
    .post(routes.partnership.roles(), values)
    .then(({ data }) => data?.data)

export const updatePartnerTheme = async (values: any) =>
  await initApi()
    .patch(routes.partnership.team(values?.teamId ?? undefined), values)
    .then(({ data }) => data?.data)

export const fetchPartnerTeam = async (teamId: string, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.partnership.team(teamId))
    .then(({ data }) => data?.data)

export const createPartnerTeam = async (values: any) =>
  await initApi()
    .post(routes.partnership.team(), values)
    .then(({ data }) => data?.data)

export const updatePartnerTeam = async (teamId: string, values: { name: string; description?: string }) =>
  await initApi()
    .patch(routes.partnership.team(teamId), values)
    .then(({ data }) => data?.data)

export const deletePartnerTeam = async (teamId: string) =>
  await initApi()
    .delete(routes.partnership.team(teamId))
    .then(({ data }) => data?.data)

export const deletePartnerTheme = async (values: any) =>
  await initApi()
    .delete(routes.partnership.team(), { ...values })
    .then(({ data }) => data?.data)

export const fetchPartnerTeams = async (filters: any = {}, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.partnership.teams(filters))
    .then(({ data }) => data?.data)

export const resendPartnerInvitation = async (partnershipId: string) =>
  await initApi()
    .post(routes.partnership.resendRoleNotification(partnershipId))
    .then(({ data }) => data?.data)

export const deletePartnerInvitation = async (partnershipId: string) =>
  await initApi()
    .delete(routes.partnership.role(partnershipId))
    .then(({ data }) => data?.data)

export const deleteBusinessInvite = async (accountId: string) =>
  await initApi()
    .delete(routes.partnership.deleteBusinessInvite(accountId))
    .then(({ data }) => data?.data)

export const resendBusinessInvite = async (accountId: string) =>
  await initApi()
    .post(routes.partnership.resendBusinessInvite(accountId))
    .then(({ data }) => data?.data)

export const resendMemberInvite = async (memberId: string) =>
  await initApi()
    .post(routes.partnership.resendRoleNotification(memberId))
    .then(({ data }) => data?.data)

export const addTeamMemberToTeam = async ({
  partnershipTeamIds,
  roleIds,
}: {
  partnershipTeamIds: string[]
  roleIds: string[]
}) =>
  await initApi()
    .post(routes.partnership.assignRoles, {
      partnershipTeamIds,
      roleIds,
    })
    .then(({ data }) => data?.data)

export const addTeamToBusiness = async ({
  partnershipTeamIds,
  accountIds,
}: {
  partnershipTeamIds: string[]
  accountIds: string[]
}) =>
  await initApi()
    .post(routes.partnership.assignAccounts, {
      partnershipTeamIds,
      accountIds,
    })
    .then(({ data }) => data)

export const unassignMemberFromTeam = async (memberId: string, teamId: string) =>
  await initApi()
    .delete(routes.partnership.deleteRoleTeam(memberId, teamId))
    .then(({ data }) => data?.data)

export const unassignBusinessFromTeam = async (businessId: string, teamId: string) =>
  await initApi()
    .delete(routes.partnership.deleteTeamAccount(teamId, businessId))
    .then(({ data }) => data?.data)
