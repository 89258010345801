import InlineContainer from "@/components/InlineContainer"
import IconButton from "@mui/material/IconButton"
import MinusCircleIcon from "@mui/icons-material/RemoveCircleRounded"
import AddCircleIcon from "@mui/icons-material/AddCircleRounded"
import NumberField, { NumberFieldProps } from "@/components/NumberField"
import TestId from "@/constants/testIds"

type NumberSelectProps = NumberFieldProps & {
  value: number
  onDecrement?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onIncrement?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  min?: number
  max?: number
  disableDecrement?: boolean
  disableIncrement?: boolean
  disabled?: boolean
}

const NumberSelect = ({
  value,
  onDecrement,
  onIncrement,
  onChange,
  onBlur,
  min = undefined,
  max = undefined,
  disableDecrement = false,
  disableIncrement = false,
  disabled = false,
  ...props
}: NumberSelectProps) => {
  return (
    <InlineContainer>
      <IconButton
        size="small"
        edge="start"
        sx={{
          color: "primary.dark",
          ":hover": {
            color: "primary.main",
          },
        }}
        onClick={(e) => {
          if (typeof onDecrement === "function") {
            onDecrement(e)
          }
        }}
        disabled={disableDecrement || disabled || (typeof min !== "undefined" && min !== null ? value <= min : false)}
        {...{ "data-testid": TestId.Quantity.Decrease }}
      >
        <MinusCircleIcon fontSize="small" />
      </IconButton>
      <NumberField
        {...props}
        value={value}
        disabled={disabled}
        onChange={(e) => {
          if (typeof onChange === "function") {
            onChange(e)
          }
        }}
        onBlur={(e) => {
          if (typeof onBlur === "function") {
            onBlur(e)
          }
        }}
        inputProps={{
          "data-testid": TestId.Quantity.Input,
        }}
      />
      <IconButton
        size="small"
        edge="end"
        sx={{
          color: "primary.dark",
          ":hover": {
            color: "primary.main",
          },
        }}
        onClick={(e) => {
          if (typeof onIncrement === "function") {
            onIncrement(e)
          }
        }}
        disabled={disableIncrement || disabled || (typeof max !== "undefined" && max !== null ? value <= max : false)}
        {...{ "data-testid": TestId.Quantity.Increase }}
      >
        <AddCircleIcon fontSize="small" />
      </IconButton>
    </InlineContainer>
  )
}

export default NumberSelect
