import MuiDivider from "@mui/material/Divider"
import { styled } from "@mui/material"

const DashedDivider = styled(MuiDivider, {
  shouldForwardProp: (prop) => prop !== "margin" && prop !== "spacing",
})<{ margin?: "wide" | "default"; spacing?: number }>(
  ({ theme, margin = "default", spacing = 4 }) => `
  margin: ${theme.spacing(spacing, 0)};
  border-style: dashed !important;
  border-bottom-width: 2px !important;
  padding: 0;

  ${theme.breakpoints.up("md")} {
    margin: ${theme.spacing(spacing, margin === "wide" ? 4.75 : 0)};
  }
`,
)

export default DashedDivider
