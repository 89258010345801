import NiceModal, { useModal } from "@ebay/nice-modal-react"
import { Dialog, DialogContent, DialogActions } from "@/components/Dialog"
import InlineContainer from "@/components/InlineContainer"
import Typography from "@mui/material/Typography"
import NeutralButton from "@/components/buttons/NeutralButton"
import AffirmativeButton from "@/components/buttons/AffirmativeButton"
import TestId from "@/constants/testIds"

export default NiceModal.create<{
  mode: "edit" | "delete"
  item: string
}>(({ mode, item }) => {
  const modal = useModal()

  const handleCloseModal = () => {
    modal.hide()
  }

  return (
    <Dialog modal={modal} title={`${mode === "delete" ? "Delete" : "Edit"} ${item}`} fullScreen={false}>
      <DialogContent>
        <Typography variant="body1" sx={{ marginBottom: 1.5 }}>
          {mode === "delete"
            ? `This ${item.toLowerCase()} will be removed from all Draft and Scheduled invoices and quotes. Issued invoices and sent quotes will not be affected.`
            : "This update will affect all Draft and Scheduled invoices and quotes. Invoices and quotes already issued will not be updated."}
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 1.5 }}>
          Are you sure you want to proceed?
        </Typography>
      </DialogContent>
      <DialogActions>
        <InlineContainer
          width="100%"
          flexWrap="wrap"
          sx={{
            justifyContent: { xs: "center", sm: "space-between" },
          }}
        >
          <NeutralButton
            type="button"
            onClick={() => {
              modal.reject()
              handleCloseModal()
            }}
            disableElevation
            sx={{ margin: "0.25rem 0.5rem" }}
          >
            No, cancel
          </NeutralButton>
          <AffirmativeButton
            type="submit"
            data-testid={TestId.Dialog.ConfirmButton}
            sx={{
              minWidth: 120,
              margin: "0.25rem 0.5rem",
            }}
            onClick={() => {
              modal.resolve()
              modal.hide()
            }}
          >
            Yes, confirm
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </Dialog>
  )
})
