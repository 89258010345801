import { FormLabel, Spacer } from "@/features/auth/components/styled"
import { doesFormHaveErrors } from "@/utils/getFieldErrors"
import { Divider, Typography, useMediaQuery } from "@mui/material"
import { PinInput } from "react-input-pin-code"
import AffirmativeButton from "./buttons/AffirmativeButton"
import EmailOrPhoneField from "./EmailOrPhoneField"
import GoogleAuthButton from "./GoogleAuthButton"
import InlineContainer from "./InlineContainer"
import Link from "./Link"
import SystemFeedback from "./SystemFeedback"
import { useTheme } from "@emotion/react"
import { useRouter } from "next/router"
import TestId from "@/constants/testIds"

const ContactForm = ({ formik, message }) => {
  return (
    <form onSubmit={formik.handleSubmit} id="initial_signup">
      <SystemFeedback {...message} />
      <EmailOrPhoneField formik={formik} />
      <AffirmativeButton
        fullWidth
        type="submit"
        form="initial_signup"
        disabled={doesFormHaveErrors(formik.errors)}
        tabIndex={4}
        data-testid={TestId.Input.Continue}
      >
        Continue
      </AffirmativeButton>
    </form>
  )
}

const VerificationForm = ({ formik, message }) => {
  return (
    <form onSubmit={formik.handleSubmit} id="otc_signup">
      <EmailOrPhoneField testId={TestId.Input.EmailOrPhone} formik={formik} />
      <SystemFeedback {...message} />
      <FormLabel htmlFor="password">One-time code</FormLabel>
      <PinInput
        autoFocus
        showState={false}
        placeholder=""
        size="lg"
        autoComplete="one-time-code"
        containerStyle={{
          flexBasis: "100%",
          justifyContent: "space-around",
          margin: "1rem 0 1.25rem 0",
        }}
        values={formik.values.otc_code}
        onChange={(_value, _index, values) => formik.setFieldValue("otc_code", values)}
      />
      <AffirmativeButton
        fullWidth
        type="submit"
        form="otc_signup"
        disabled={doesFormHaveErrors(formik.errors) || formik.values.otc_code.join("").length < 6}
        tabIndex={4}
      >
        Create Account
      </AffirmativeButton>
    </form>
  )
}

type SignUpFormProps = {
  signupStep: "initial" | "verify"
  sendOneTimeCode: (values: any) => void
  handleGoogleAuthSuccess?: any
  formik: any
  message: any
  alternateAction?: () => any
  partnershipId?: string
}

const SignupForm = ({
  signupStep,
  sendOneTimeCode,
  handleGoogleAuthSuccess,
  formik,
  message,
  alternateAction,
  partnershipId,
}: SignUpFormProps) => {
  const router = useRouter()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  return (
    <>
      {signupStep === "initial" && <ContactForm formik={formik} message={message} />}

      {signupStep === "verify" && (
        <>
          <VerificationForm formik={formik} message={message} />
          <InlineContainer gap="0.35rem" justifyContent="center" marginTop="1rem">
            <Typography variant="body2">Problems receiving the code?</Typography>
            <Typography variant="body2">
              <Link component="button" type="button" onClick={() => sendOneTimeCode(formik.values)}>
                Resend Code
              </Link>
            </Typography>
          </InlineContainer>
        </>
      )}
      {isDesktop && (
        <>
          <Spacer>
            <span>or</span>
            <Divider />
          </Spacer>
          <GoogleAuthButton variant="signup" action={handleGoogleAuthSuccess} />
        </>
      )}
      <Typography variant="body2" sx={{ color: "grey.600", marginTop: "1rem" }}>
        Already have an account?{" "}
        <Link
          onClick={() => {
            if (typeof alternateAction === "function" || (alternateAction !== null && alternateAction !== undefined)) {
              alternateAction()
            } else {
              router.push("/login" + (partnershipId ? `?partnership_id=${partnershipId}` : ""))
            }
          }}
        >
          Log in
        </Link>
      </Typography>
    </>
  )
}

export default SignupForm
