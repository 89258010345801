import { BankAccountType, Query, BankAccountSubtype, PaymentMethodTypeEnum } from "@/types"
import { useFormik, FormikConfig, FormikProps } from "formik"
import { useQuery } from "@tanstack/react-query"
import { useSnackbar } from "notistack"
import { createWalletInstrument, getPlaidLinkToken } from "@/features/wallet/wallet-queries"
import { useQueryClient } from "@tanstack/react-query"
import useAppDispatch from "@/hooks/useAppDispatch"
import {
  setAccountType,
  setLinkToken,
  setWalletId,
  setWalletInstrumentId,
  tweakPlaidWidget,
} from "@/features/wallet/wallet-reducers"
import * as yup from "yup"
import { cardValidationSchema, bankValidationSchema } from "@/features/wallet/wallet-validation"
import { DateTime } from "luxon"
import humanName from "humanparser"
// import PaymentMethodTypeSelection from "./PaymentMethodTypeSelection";
import InlineContainer from "@/components/InlineContainer"
import RadioBox from "@/components/RadioBox"
import BankIcon from "@/components/icons/BankIcon"
import CardIcon from "@/components/icons/CardIcon"
import ApplePayIcon from "@/components/icons/ApplePayIcon"
import { Box, Tooltip, Typography } from "@mui/material"
import Radio from "@mui/material/Radio"
import AddCreditCard from "./AddCreditCard"
import AddBankAccount from "./AddBankAccount"
import AffirmativeButton from "@/components/buttons/AffirmativeButton"
import { Fragment } from "react"
import { isApplePayEligible } from "@/features/pay-invoice/payWithApplePay"

export interface WalletInstrumentValues {
  payment_type?: PaymentMethodTypeEnum
  card_number?: string
  expiration_month?: string
  expiration_year?: string
  card_full_name?: string
  card_first_name?: string
  card_last_name?: string
  billing_address1?: string
  billing_address2?: string
  billing_city?: string
  billing_state?: string
  billing_zip_code?: string
  cvn?: string
  account_type?: BankAccountType
  account_subtype?: BankAccountSubtype
  account_number?: string
  routing_number?: string
  bank_full_name?: string
  bank_first_name?: string
  bank_last_name?: string
  phone_number?: string
}

export type AddWalletInstrumentProps = {
  wallet_id: string
  defaultPaymentType?: PaymentMethodTypeEnum
  supportAddRawBankInfo?: boolean
}

export const addWalletInstrumentDefaultValues = {
  card_number: "",
  expiration_month: `${new Date().getMonth() + 1}`.padStart(2, "0"),
  expiration_year: new Date().getFullYear().toString(),
  cvn: "",
  card_full_name: "",
  card_first_name: "",
  card_last_name: "",
  billing_address1: "",
  billing_address2: "",
  billing_city: "",
  billing_state: "",
  billing_zip_code: "",
  account_type: BankAccountType.BUSINESS,
  account_subtype: BankAccountSubtype.CHECKING,
  account_number: "",
  routing_number: "",
  bank_full_name: "",
  bank_first_name: "",
  bank_last_name: "",
  phone_number: "",
}

export const useAddNewWalletInstrument = ({
  wallet_id,
  defaultPaymentType = PaymentMethodTypeEnum.BANK_ACCOUNT,
  isResetForm = false,
  supportAddRawBankInfo = false,
  initialValues: defaultValues = {},
  isDisableMicroDeposit = false,
  ...props
}: Omit<FormikConfig<WalletInstrumentValues>, "initialValues" | "onSubmit"> &
  AddWalletInstrumentProps & {
    successCallback?: Function
    plaidTriggeredCallback?: Function
    initialValues?: FormikConfig<WalletInstrumentValues>["initialValues"]
    isDisableMicroDeposit?: boolean
    isResetForm?: boolean
  }) => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const initialValues = {
    payment_type: defaultPaymentType,
    ...addWalletInstrumentDefaultValues,
    account_type: defaultValues?.account_type || addWalletInstrumentDefaultValues.account_type,
  }

  // todo handle plaid link token expiration case
  const { data: plaidLinkToken } = useQuery(
    [Query.wallet.PLAID_LINK_TOKEN, wallet_id, isDisableMicroDeposit],
    () => getPlaidLinkToken(wallet_id, isDisableMicroDeposit),
    {
      enabled: !!wallet_id,
    },
  )

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    validateOnMount: false,
    validationSchema: () =>
      yup.lazy((values) =>
        values.payment_type === PaymentMethodTypeEnum.CREDIT_CARD ? cardValidationSchema : bankValidationSchema,
      ),
    onSubmit: async (values, { setSubmitting, setStatus, resetForm }) => {
      const launchPlaid = () => {
        dispatch(
          setAccountType({
            account_type: Boolean(values?.account_type) ? values?.account_type : undefined,
            account_subtype: Boolean(values?.account_subtype) ? values?.account_subtype : undefined,
          }),
        )
        // only set the link token until every data is preset,
        // otherwise the data in onSuccess call or usePlaidLink will always be the original value
        dispatch(setLinkToken(plaidLinkToken?.link_token))
        dispatch(setWalletId(wallet_id))
        dispatch(setWalletInstrumentId(""))
        dispatch(tweakPlaidWidget())
        if (isResetForm) {
          setTimeout(() => {
            resetForm()
          }, 500)
        }
        if (typeof props?.plaidTriggeredCallback === "function") {
          props?.plaidTriggeredCallback()
        }
      }
      if (!supportAddRawBankInfo && values.payment_type === PaymentMethodTypeEnum.BANK_ACCOUNT) {
        launchPlaid()

        return
      }

      setSubmitting(true)
      setStatus("pending")

      const cardFirstName = humanName.parseName(values?.card_full_name ?? "")?.firstName
      const cardLastName = humanName.parseName(values?.card_full_name ?? "")?.lastName

      const bankFirstName = humanName.parseName(values?.bank_full_name ?? "")?.firstName
      const bankLastName = humanName.parseName(values?.bank_full_name ?? "")?.lastName

      const accountInfo =
        values.payment_type === PaymentMethodTypeEnum.BANK_ACCOUNT
          ? {
              bank_account: {
                first_name: bankFirstName ?? undefined,
                last_name: bankLastName ?? undefined,
                account_type: Boolean(values?.account_type) ? values?.account_type : undefined,
                account_subtype: Boolean(values?.account_subtype) ? values?.account_subtype : undefined,
                routing_number: Boolean(values?.routing_number)
                  ? values?.routing_number?.trim()
                  : defaultValues.routing_number,
                account_number: Boolean(values?.account_number) ? values?.account_number?.trim() : undefined,
                address1: Boolean(values?.billing_address1) ? values?.billing_address1?.trim() : undefined,
                address2: Boolean(values?.billing_address2) ? values?.billing_address2?.trim() : undefined,
                city: Boolean(values?.billing_city) ? values?.billing_city?.trim() : undefined,
                state: Boolean(values?.billing_state) ? values?.billing_state?.trim() : undefined,
                zipcode: Boolean(values?.billing_zip_code) ? values?.billing_zip_code?.trim() : undefined,
                country: "US",
              },
            }
          : {
              credit_card: {
                card: Boolean(values?.card_number) ? values?.card_number?.replace(/[^0-9]+/g, "") : undefined,
                exp_month: Boolean(values?.expiration_month)
                  ? // @ts-ignore
                    +values?.expiration_month
                  : undefined,
                exp_year: Boolean(values?.expiration_year)
                  ? // @ts-ignore
                    +values?.expiration_year
                  : undefined,
                cvn: Boolean(values?.cvn) ? values?.cvn : undefined,
                first_name: cardFirstName ?? undefined,
                last_name: cardLastName ?? undefined,
                zipcode: Boolean(values?.billing_zip_code) ? values?.billing_zip_code : undefined,
              },
            }

      await createWalletInstrument(wallet_id, accountInfo)
        .then((response) => {
          queryClient.invalidateQueries([Query.account.ACCOUNT_INFO])
          queryClient.invalidateQueries([Query.user.USER_INFO])
          queryClient.invalidateQueries([Query.user.PIL_INVOICE])
          setSubmitting(false)
          setStatus("success")
          if (isResetForm) {
            resetForm()
          }

          if (typeof props?.successCallback === "function") {
            props?.successCallback(response)
          }
        })
        .catch((err) => {
          const message = err?.response?.data?.message || ""

          if (message.startsWith("USE_PLAID")) {
            launchPlaid()
            setSubmitting(false)
            setStatus("success")
          } else if (message.startsWith("RISKY")) {
            setSubmitting(false)
            setStatus("failed")
            enqueueSnackbar(
              <>
                There was an error adding this account.
                <br />
                Please try using another account.
              </>,
              {
                variant: "error",
              },
            )
          } else if (message.startsWith("ACCOUNT_CLOSED")) {
            setSubmitting(false)
            setStatus("failed")
            enqueueSnackbar(
              <>
                There was an error adding this account.
                <br />
                Please try using another account.
              </>,
              {
                variant: "error",
              },
            )
          } else if (err?.response?.status === 400) {
            enqueueSnackbar(<>Please check card number, expiration month, expiration year, and CVN.</>, {
              variant: "error",
            })
          } else {
            launchPlaid()
            setSubmitting(false)
            setStatus("failed")
          }
        })
    },
    ...props,
  })

  return {
    ...formik,
    disableSubmit:
      !formik.isValid ||
      formik.isSubmitting ||
      // Note: If the expiration date is in the past, we should disable the submit button
      (formik.values.payment_type === PaymentMethodTypeEnum.CREDIT_CARD &&
        DateTime.now().startOf("month") <=
          DateTime.fromJSDate(new Date(`${formik.values.expiration_month} ${formik.values.expiration_year}`))),
  }
}

const Option = ({
  name,
  icon: Icon,
  isActive,
  onClick,
  showProcessingFee,
  disabledTooltips,
  invisible = false,
  children,
}: {
  name: string
  icon: any
  isActive: boolean
  onClick: () => void
  showProcessingFee: boolean
  disabledTooltips?: string
  invisible?: boolean
  children?: any
}) => {
  const color = isActive ? "primary.main" : "grey.800"
  const borderColor = isActive ? "primary.main" : "grey.500"

  if (invisible) return null

  let Container = Fragment
  if (disabledTooltips) {
    // @ts-ignore
    Container = ({ children }: { children: any }) => (
      <Tooltip title={disabledTooltips} enterTouchDelay={0} leaveTouchDelay={500} placement="top-start">
        {children}
      </Tooltip>
    )
  }

  return (
    <Container>
      <RadioBox
        sx={{
          margin: "1rem 0",
          padding: "0.5rem 0.75rem",
          borderColor,
          opacity: disabledTooltips !== undefined ? 0.4 : 1,
        }}
        active={isActive}
        onClick={onClick}
      >
        <InlineContainer alignItems="flex-start">
          <Radio size="small" checked={isActive} disabled={false} sx={{ color }} />
          <Box sx={{ padding: "0.4rem 0" }}>
            <InlineContainer>
              <Icon
                sx={{
                  color,
                  width: 24,
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  color,
                  margin: "0 5px !important",
                  fontWeight: 700,
                }}
                noWrap
              >
                {name}
              </Typography>
            </InlineContainer>
            {isActive && showProcessingFee && (
              <Typography variant="body2" color={"grey.600"} sx={{ fontSize: "0.85rem", marginTop: "1rem" }}>
                A <strong>3% processing fee</strong> will be added to your total.
              </Typography>
            )}
          </Box>
        </InlineContainer>
        {isActive && children && <Box sx={{ padding: "0 1rem", width: "100%", margin: "0 0 1rem" }}>{children}</Box>}
      </RadioBox>
    </Container>
  )
}

const SubmitCTA = ({ formik, cta }) => {
  return (
    <AffirmativeButton
      fullWidth
      sx={{
        marginTop: "1rem",
      }}
      onClick={() => {
        formik.handleSubmit()
        window.scrollTo(0, 0)
      }}
      loading={formik?.isSubmitting}
    >
      {cta}
    </AffirmativeButton>
  )
}

export const AddWalletInstrument = ({
  formik,
  onCreditCardClick,
  onBankAccountClick,
  onApplePayClick,
  showProcessingFee = false,
  metadata = {
    [PaymentMethodTypeEnum.CREDIT_CARD]: {
      visible: true,
    },
    [PaymentMethodTypeEnum.BANK_ACCOUNT]: {
      visible: true,
    },
  },
  isShowSubmitCTA = false,
}: {
  formik: FormikProps<WalletInstrumentValues>
  showProcessingFee?: boolean

  onCreditCardClick?: () => void
  onBankAccountClick?: () => void
  onApplePayClick?: () => void
  isShowSubmitCTA?: boolean
  metadata?: Record<
    any,
    {
      visible: boolean
      disabledTooltips?: string
      supportAddRawBankInfo?: boolean
      partnershipInformation?: any
    }
  >
}) => {
  const paymentType = formik.values.payment_type as PaymentMethodTypeEnum

  const bankAccountForm = (
    <>
      <AddBankAccount formik={formik} {...metadata?.[PaymentMethodTypeEnum.BANK_ACCOUNT]} />
      {isShowSubmitCTA && (
        <>
          {!metadata?.[PaymentMethodTypeEnum.BANK_ACCOUNT]?.supportAddRawBankInfo &&
            !metadata?.[PaymentMethodTypeEnum.BANK_ACCOUNT]?.partnershipInformation && (
              <Typography variant="body2" color="grey" textAlign="center" marginTop="0.5rem">
                You will be redirected to verify your bank account
              </Typography>
            )}
          <SubmitCTA formik={formik} cta="Link Bank Account" />
        </>
      )}
    </>
  )

  const creditCardForm = (
    <>
      <AddCreditCard formik={formik} {...metadata?.[PaymentMethodTypeEnum.CREDIT_CARD]} />
      {isShowSubmitCTA && <SubmitCTA formik={formik} cta="Save Credit Card" />}
    </>
  )

  if (Object.values(metadata).filter((a) => a.visible).length === 1) {
    return metadata?.[PaymentMethodTypeEnum.BANK_ACCOUNT]?.visible ? bankAccountForm : creditCardForm
  }

  return (
    <Box
      justifyContent="space-around"
      sx={{
        gap: { xs: "1rem", md: "1.5rem" },
        width: "100%",
      }}
    >
      <Option
        name="Bank Account"
        icon={BankIcon}
        isActive={paymentType === PaymentMethodTypeEnum.BANK_ACCOUNT}
        onClick={() => {
          if (onBankAccountClick) {
            onBankAccountClick()
          } else {
            formik.setErrors({})
            formik.setFieldValue("payment_type", PaymentMethodTypeEnum.BANK_ACCOUNT)
          }
        }}
        showProcessingFee={false}
        disabledTooltips={metadata?.[PaymentMethodTypeEnum.BANK_ACCOUNT]?.disabledTooltips}
        invisible={!metadata?.[PaymentMethodTypeEnum.BANK_ACCOUNT]?.visible}
      >
        {bankAccountForm}
      </Option>
      <Option
        name="Credit/Debit Card"
        icon={CardIcon}
        isActive={paymentType === PaymentMethodTypeEnum.CREDIT_CARD}
        onClick={() => {
          if (onCreditCardClick) {
            onCreditCardClick()
          } else {
            formik.setErrors({})
            formik.setFieldValue("payment_type", PaymentMethodTypeEnum.CREDIT_CARD)
          }
        }}
        showProcessingFee={showProcessingFee}
        disabledTooltips={metadata?.[PaymentMethodTypeEnum.CREDIT_CARD]?.disabledTooltips}
        invisible={!metadata?.[PaymentMethodTypeEnum.CREDIT_CARD]?.visible}
      >
        {creditCardForm}
      </Option>
      <Option
        name="Apple Pay"
        icon={ApplePayIcon}
        isActive={paymentType === PaymentMethodTypeEnum.APPLE_PAY}
        onClick={() => {
          if (onApplePayClick) {
            onApplePayClick()
          } else {
            formik.setErrors({})
            formik.setFieldValue("payment_type", PaymentMethodTypeEnum.APPLE_PAY)
          }
        }}
        showProcessingFee={showProcessingFee}
        disabledTooltips={metadata?.[PaymentMethodTypeEnum.APPLE_PAY]?.disabledTooltips}
        invisible={!metadata?.[PaymentMethodTypeEnum.APPLE_PAY]?.visible || !isApplePayEligible}
      />
    </Box>
  )
}
