import * as yup from "yup"
import { PaymentMethodTypeEnum } from "@/types"
import validateCard from "card-validator"
import { email_or_phone } from "@/features/auth/auth-validation"
import { DateTime } from "luxon"
import { isAmex } from "@/utils/wallet"
import { unformattedAmount } from "@/utils/stringUtils"

const today = DateTime.now().startOf("month")

export const cardValidationSchema = yup.object().shape({
  card_number: yup.string().when("payment_type", {
    is: PaymentMethodTypeEnum.CREDIT_CARD,
    // a loose min and max check, should use
    then: (schema) =>
      schema.test("is-valid-card", (value, ctx) => {
        if (!value) {
          return ctx.createError({ message: "Card number is required" })
        }

        const validCard = validateCard.number(value.replace(/[^0-9]+/g, ""))

        if (!validCard.isPotentiallyValid) {
          return ctx.createError({ message: "Incorrect card" })
        }

        return true
      }),
  }),
  expiration_month: yup.string().required("Required"),
  expiration_year: yup.string().required("Required"),
  cvn: yup
    .string()
    .required("Required")
    .when("card_number", (card_number, schema) => {
      return schema.test("cvn-length", "Required", (cvn) => {
        const requiredLength = isAmex(card_number) ? 4 : 3
        return cvn && cvn.length === requiredLength
      })
    }),
  card_full_name: yup
    .string()
    .required("Name is Required")
    .matches(/.*\s+.*/, "Please enter your first and last name"),
  billing_zip_code: yup.string().required("Required").min(5, "Invalid"),
})

export const bankValidationSchema = yup.object().shape({
  account_type: yup.string().required("Please provide if account is Business or Personal"),
  account_subtype: yup.string().required("Please specify if the account is Checking or Saving "),
})

export const knownPayerValidationSchema = yup.object().shape({
  amount: yup
    .string()
    .required("Amount is required")
    .test("three", "Minimum payment amount is $3.00.", (value) => {
      return Math.round(unformattedAmount(value || "0") * 100) >= 300
    }),
  payment_note: yup
    .string()
    .required("Additional info is required")
    .max(255, "Additional info cannot exceed 255 characters"),
})

export const anonymousPayerValidationSchema = yup.object().shape({
  amount: yup
    .string()
    .required("Amount is required")
    .test("three", "Minimum payment amount is $3.00.", (value) => {
      return Math.round(unformattedAmount(value || "0") * 100) >= 300
    }),
  card_full_name: yup
    .string()
    .required("Name is required")
    .matches(/.*\s+.*/, "Please enter your first and last name"),
  card_number: yup.string().required("Card number is required"),
  cvn: yup
    .string()
    .required("Required")
    .when("card_number", (card_number, schema) => {
      return schema.test("cvn-length", "Required", (cvn) => {
        const requiredLength = isAmex(card_number) ? 4 : 3
        return cvn && cvn.length === requiredLength
      })
    }),
  expiration_month: yup.string().required("Required"),
  expiration_year: yup.string().required("Required"),
  billing_zip_code: yup.string().required("Required").min(5, "Enter valid Zip code"),
  email_or_phone,
  payment_note: yup
    .string()
    .required("Additional info is required")
    .max(255, "Additional info cannot exceed 255 characters"),
})

yup.addMethod(yup.string, "emailOrPhone", function (message) {
  return this.test("emailOrPhone", message, function (value) {
    const { path, createError } = this

    // Define email and phone number regex patterns
    const emailPattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    const phonePattern = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/ // Simple phone number pattern

    // Check if the value matches either pattern
    const isValid = emailPattern.test(value || "") || phonePattern.test(value || "")

    // Return true if valid, otherwise create an error
    return isValid || createError({ path, message: message || "Must be a valid email or phone number" })
  })
})

export const anonymousPayerPilValidationSchema = yup.object().shape({
  card_full_name: yup
    .string()
    .required("Name is required")
    .matches(/.*\s+.*/, "Please enter your first and last name"),
  card_number: yup.string().required("Card number is required"),
  cvn: yup
    .string()
    .required("Required")
    .when("card_number", (card_number, schema) => {
      return schema.test("cvn-length", "Required", (cvn) => {
        const requiredLength = isAmex(card_number) ? 4 : 3
        return cvn && cvn.length === requiredLength
      })
    }),
  expiration_month: yup.string().required("Required"),
  expiration_year: yup
    .string()
    .required("Required")
    .test("expiration", "Card is expired", function (value) {
      if (typeof value === "string") {
        const expDate = DateTime.fromObject({
          year: parseInt(value, 10) + 1,
          month: 1,
        }).startOf("month")
        return expDate > today
      }
      return false
    }),
  billing_zip_code: yup.string().required("Required").min(5, "Enter valid Zip code"),
  // @ts-ignore
  email_or_phone: yup.string().emailOrPhone().required("Email or phone number required"),
})
