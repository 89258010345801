import { initApi } from "@/config/api"

export const downloadFile = async (fileUrl: string, fileName?: string) =>
  await initApi()
    .get(fileUrl, { responseType: "blob" })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("target", "_blank")
      link.setAttribute(
        "download",
        response?.data?.type?.includes("csv")
          ? fileName
            ? fileName
            : "exported-data.csv"
          : response?.data?.type?.includes("application/pdf")
            ? fileName
              ? fileName
              : "invoice-download.pdf"
            : fileUrl,
      )
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })

export const downloadFromURL = async (url, filename) => {
  var a = document.createElement("a")
  a.href = url
  a.setAttribute("target", "_blank")
  a.download = filename
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url)
  a.remove()
}

export const downloadFromBlob = async (res, optionalFileName: string = "") => {
  const filename = res?.headers?.["content-disposition"]?.match(/^attachment;\s*filename="(.*)"$/)?.[1]

  const href = URL.createObjectURL(res.data)

  // create "a" HTML element with href to file & click
  const link = document.createElement("a")
  link.href = href
  link.setAttribute("target", "_blank")
  link.setAttribute("download", Boolean(filename) ? filename : optionalFileName)
  document.body.appendChild(link)
  link.click()

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}
