import MuiDialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import TextField from "@/components/TextField"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Switch from "@mui/material/Switch"
import NiceModal, { useModal } from "@ebay/nice-modal-react"
import InlineContainer from "@/components/InlineContainer"
import DashedDivider from "@/components/DashedDivider"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import getFieldErrors from "@/utils/getFieldErrors"
import { taxValidationSchema } from "@/features/price-management/price-management-validation"
import { useFormik } from "formik"
import { useTheme, useMediaQuery } from "@mui/material"
import { createTax, editTax } from "@/features/price-management/price-management-queries"
import { useEffect } from "react"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/CloseRounded"
import NumberField from "@/components/NumberField"
import { DialogKeys, Query } from "@/types"
import SystemFeedback from "@/components/SystemFeedback"
import useMessage from "@/hooks/useMessage"
import AffirmativeButton from "@/components/buttons/AffirmativeButton"
import NeutralButton from "@/components/buttons/NeutralButton"
import { useSnackbar } from "notistack"
import { parsePercent } from "@/utils/calculations"
import unformatNumber from "@/utils/unformatNumber"

type TaxValues = {
  id?: string
  name?: string
  value_pct?: number
  description?: string
  is_applied_to_all_invoices?: boolean
}

export default NiceModal.create<{
  existingTax: TaxValues
  priceItems: any[]
}>(({ existingTax, priceItems }) => {
  const modal = useModal(DialogKeys.TAX)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const [message, setMessage] = useMessage()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const isNew = typeof existingTax?.id === "undefined"

  // modify the useEffect so that it correctly sets the initial values
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      value_pct: "",
      description: "",
      is_applied_to_all_invoices: false,
    },
    validationSchema: taxValidationSchema,
    onSubmit: async (values) => {
      if (!isNew) {
        if (
          // @ts-ignore
          +existingTax.value_pct !== +values.value_pct ||
          existingTax.name !== values.name
        ) {
          NiceModal.show(DialogKeys.PRICE_EDIT_DELETE, {
            mode: "edit",
            item: "Tax",
          }).then(() => {
            editTaxMutation({
              ...values,
              id: existingTax?.id,
              value_pct: parsePercent(unformatNumber(values?.value_pct)),
            })
          })
        } else {
          editTaxMutation({
            ...values,
            id: existingTax?.id,
            value_pct: parsePercent(unformatNumber(values?.value_pct)),
          })
        }
      } else {
        createTaxMutation({
          ...values,
          value_pct: parsePercent(unformatNumber(values?.value_pct)),
        })
      }
    },
  })

  useEffect(() => {
    formik.setValues({
      name: existingTax?.name ?? "",
      // @ts-ignore
      value_pct: existingTax?.value_pct || "",
      description: existingTax?.description ?? "",
      is_applied_to_all_invoices: existingTax?.is_applied_to_all_invoices ?? false,
    })
  }, [existingTax])

  const handleCloseModal = () => {
    modal.reject({ priceItems })
    modal.hide()
    formik.resetForm()
  }

  const handleSuccess = (tax) => {
    enqueueSnackbar(`Tax successfully saved`, {
      variant: "success",
    })
    queryClient.invalidateQueries([Query.account.BUILDER_ALL_TAXES])

    modal.resolve({
      tax: {
        ...formik.values,
        value_pct: parsePercent(unformatNumber(formik.values?.value_pct)),
        id: tax?.id,
      },
      priceItems,
    })
    modal.hide()
  }

  const { mutate: createTaxMutation, isLoading: isSavingTax } = useMutation((values: TaxValues) => createTax(values), {
    onSuccess: handleSuccess,
    onError: (error: any) => {
      setMessage({
        type: "error",
        message: error?.response?.data?.message ?? "Cannot create tax.",
      })
    },
  })

  const { mutate: editTaxMutation, isLoading: isEditingTax } = useMutation((values: TaxValues) => editTax(values), {
    onSuccess: handleSuccess,
    onError: (error: any) => {
      setMessage({
        type: "error",
        message: error?.response?.data?.message ?? "Cannot edit tax.",
      })
    },
  })

  return (
    <MuiDialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      TransitionProps={{
        onExited: () => {
          formik.resetForm()
          modal.remove()
        },
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            color="primary"
            variant="h6"
            sx={{
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            {isNew ? "Create" : "Edit"} Tax
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: (theme) => theme.palette.grey[500],
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      <DialogContent sx={{ padding: "1.25rem" }} dividers>
        <form onSubmit={formik.handleSubmit} id="tax-form">
          <SystemFeedback {...message} />
          <TextField
            size="small"
            color="primary"
            variant="outlined"
            fullWidth
            label="Tax Name"
            sx={{ fontSize: "1rem", marginBottom: "1.25rem" }}
            {...formik.getFieldProps("name")}
            {...getFieldErrors("name", formik)}
          />
          <NumberField
            size="small"
            color="primary"
            variant="outlined"
            fullWidth
            label="% Tax Rate"
            sx={{
              fontSize: "1rem",
              marginBottom: "1.25rem",
              flexBasis: "100%",
            }}
            {...formik.getFieldProps("value_pct")}
            {...getFieldErrors("value_pct", formik)}
          />
          <TextField
            size="small"
            color="primary"
            variant="outlined"
            fullWidth
            label="Description (Optional)"
            sx={{ fontSize: "1rem" }}
            {...formik.getFieldProps("description")}
            inputProps={{
              maxLength: 250,
            }}
          />
          <Typography
            variant="body2"
            sx={{
              color: formik?.values?.description?.length >= 250 ? "error.dark" : "grey.600",
              margin: "0.5rem 0 0 0",
              textAlign: "right",
            }}
          >
            {formik?.values?.description?.length ?? 0} / 250
          </Typography>
          <DashedDivider spacing={3} />
          <Box margin="0 auto 0.75rem">
            <InlineContainer justifyContent="space-between">
              <Typography variant="body1">Apply to all invoices</Typography>
              <Switch
                checked={formik.values.is_applied_to_all_invoices}
                onChange={() =>
                  formik.setFieldValue("is_applied_to_all_invoices", !formik.values.is_applied_to_all_invoices)
                }
              />
            </InlineContainer>
            <Typography variant="body2">
              Automatically include this tax on all taxable items in every invoice by default
            </Typography>
          </Box>
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between" width="100%">
          <NeutralButton variant="outlined" onClick={handleCloseModal} sx={{ marginRight: 2 }} disableElevation>
            Cancel
          </NeutralButton>
          <AffirmativeButton
            type="submit"
            form="tax-form"
            disabled={isSavingTax || isEditingTax}
            sx={{
              whiteSpace: "nowrap",
              width: { xs: isNew ? "100%" : 140, md: 140 },
            }}
            loading={isSavingTax || isEditingTax}
          >
            Save
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </MuiDialog>
  )
})
