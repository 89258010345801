import MuiDialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import NiceModal, { useModal } from "@ebay/nice-modal-react"
import Typography from "@mui/material/Typography"
import InlineContainer from "@/components/InlineContainer"
import { useTheme, useMediaQuery } from "@mui/material"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Invoice, Query } from "@/types"
import AffirmativeButton from "@/components/buttons/AffirmativeButton"
import ErrorButton from "@/components/buttons/ErrorButton"
import { approveInvoice, disapproveInvoice } from "@/features/invoice-management/invoice-management-queries"
import { useSnackbar } from "notistack"
import InvoiceQuotePreview from "../builder/InvoiceQuotePreview"

export default NiceModal.create<{
  invoice: Invoice
}>(({ invoice }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const modal = useModal()
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const handleCloseModal = () => {
    modal.hide()
  }

  const approveInvoiceMutation = useMutation(approveInvoice, {
    onSuccess: () => {
      queryClient.invalidateQueries([Query.account.INVOICE, invoice?.id])
      handleCloseModal()
    },
    onError: () => {
      enqueueSnackbar("Invoice changes approved", { variant: "error" })
    },
  })

  const disapproveInvoiceMutation = useMutation(disapproveInvoice, {
    onSuccess: () => {
      queryClient.invalidateQueries([Query.account.INVOICE, invoice?.id])
      queryClient.invalidateQueries([Query.user.PIL_INVOICE])
      handleCloseModal()
    },
    onError: () => {
      enqueueSnackbar("Failed to reject invoice changes", { variant: "error" })
    },
  })

  return (
    <MuiDialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            color="primary"
            variant="h6"
            sx={{
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            Update to Invoice Amount
          </Typography>
        </InlineContainer>
      </DialogTitle>
      <DialogContent dividers>
        <Typography
          variant="body2"
          sx={{
            marginBottom: "1.5rem",
          }}
        >
          Changes have been made to this invoice. Please review the updated invoice details below.
          <br />
          <br />
          By selecting "Accept Changes," your scheduled payment will be automatically adjusted to reflect the new total
          amount.
          <br />
          <br />
          To make modifications or cancel the payment, select "Cancel Payment."
        </Typography>
        <InvoiceQuotePreview is_interactable={false} sync mode="invoice" />
      </DialogContent>
      <DialogActions>
        <InlineContainer justifyContent="space-between" width="100%" padding="0.5rem">
          <ErrorButton
            onClick={() => {
              disapproveInvoiceMutation.mutate(invoice)
            }}
          >
            Cancel Payment
          </ErrorButton>
          <AffirmativeButton
            onClick={() => {
              approveInvoiceMutation.mutate(invoice)
            }}
          >
            Accept Changes
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </MuiDialog>
  )
})
