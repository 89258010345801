import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const AmexIcon = (props: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="35" height="24" viewBox="0 0 35 24" fill="none" {...props}>
    <rect x="0.5" y="0.5" width="34" height="23" rx="3.5" fill="#1F72CD" stroke="#D9D9D9" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.68111 8.5L3.5 15.7467H7.30824L7.78035 14.5913H8.85949L9.3316 15.7467H13.5234V14.8649L13.8969 15.7467H16.0652L16.4387 14.8462V15.7467H25.1566L26.2166 14.6213L27.2092 15.7467L31.6868 15.7561L28.4957 12.1436L31.6868 8.5H27.2786L26.2467 9.60463L25.2854 8.5H15.8016L14.9872 10.3704L14.1537 8.5H10.3534V9.35186L9.93068 8.5H6.68111ZM19.6994 9.52905H24.7055L26.2367 11.2316L27.8172 9.52905H29.3484L27.0219 12.1426L29.3484 14.726H27.7477L26.2166 13.0036L24.628 14.726H19.6994V9.52905ZM20.9356 11.555V10.6057V10.6048H24.0593L25.4223 12.1229L23.9989 13.6493H20.9356V12.613H23.6667V11.555H20.9356ZM7.41799 9.52905H9.2743L11.3843 14.4431V9.52905H13.4178L15.0476 13.0524L16.5496 9.52905H18.573V14.7291H17.3418L17.3317 10.6544L15.5368 14.7291H14.4355L12.6305 10.6544V14.7291H10.0977L9.61756 13.5633H7.02338L6.54421 14.728H5.18717L7.41799 9.52905ZM7.46634 12.4856L8.32101 10.4089L9.1747 12.4856H7.46634Z"
      fill="white"
    />
  </SvgIcon>
)

export default AmexIcon
