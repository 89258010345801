import { RadioLabel } from "@/features/onboarding/components"
import Box from "@mui/material/Box"
import { businessRelationshipList } from "@/types"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import RadioGroup from "@mui/material/RadioGroup"
import Radio from "@mui/material/Radio"
import Typography from "@mui/material/Typography"
import getFieldErrors from "@/utils/getFieldErrors"
import { FormHelperText } from "@mui/material"

const BusinessRelation = ({ formik }) => {
  return (
    <>
      <Typography variant="h4">What is your relationship to {formik.values.public_name}?</Typography>
      <Box marginTop="1rem">
        <FormControl
          sx={{ width: "100%" }}
          error={
            getFieldErrors("relationship_to_business", formik, {
              ignoreTouched: true,
            })?.error
          }
        >
          <FormHelperText
            sx={{
              marginTop: "-0.25rem",
              marginLeft: "0",
            }}
          >
            {
              getFieldErrors("relationship_to_business", formik, {
                ignoreTouched: true,
              })?.helperText
            }
          </FormHelperText>
          <RadioGroup>
            {businessRelationshipList.map((relationship) => (
              <RadioLabel
                key={relationship.title}
                sx={{
                  borderColor: getFieldErrors("business_structure", formik, {
                    ignoreTouched: true,
                  })?.error
                    ? "error.main"
                    : "",
                }}
              >
                <FormControlLabel
                  {...formik.getFieldProps("relationship_to_business")}
                  control={
                    <Radio size="small" checked={relationship.value === formik.values.relationship_to_business} />
                  }
                  value={relationship.value}
                  label={relationship.title}
                />
              </RadioLabel>
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </>
  )
}

export default BusinessRelation
