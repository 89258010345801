import Box from "@mui/material/Box"
import InlineContainer from "@/components/InlineContainer"
import { MonthsAbbreviated } from "@/types"
import TextField from "@/components/TextField"
import Select from "@/components/Select"
import Typography from "@mui/material/Typography"
import FormLabel from "@mui/material/FormLabel"
import MenuItem from "@mui/material/MenuItem"
import getFutureYears from "@/utils/getFutureYears"
import { FormikProps } from "formik"
import { WalletInstrumentValues } from "@/features/wallet/components/payment-method/AddWalletInstrument"
import getFieldErrors from "@/utils/getFieldErrors"
import { beautifyCardNumber, isAmex } from "@/utils/wallet"
import isEmptyOrNumber from "@/utils/isEmptyOrNumber"

// test credit cards
// https://support.bluesnap.com/docs/test-credit-card-numbers

const AddCreditCard = ({ formik }: { formik: FormikProps<WalletInstrumentValues> }) => {
  return (
    <>
      <InlineContainer justifyContent="start" alignItems="start" gap="1rem">
        <Box width="100%">
          <FormLabel
            htmlFor="card_full_name"
            sx={{
              display: "inline-block",
              marginBottom: "0.25rem",
            }}
          >
            <Typography variant="body1" sx={{ color: "black", fontWeight: 600 }}>
              Name on Card
            </Typography>
          </FormLabel>
          <TextField
            id="card_full_name"
            {...formik.getFieldProps("card_full_name")}
            {...getFieldErrors("card_full_name", formik)}
            placeholder="Full Name"
            fullWidth
          />
        </Box>
      </InlineContainer>
      <InlineContainer justifyContent="start" alignItems="start" gap="1rem" marginTop="0.75rem">
        <Box flexBasis="100%">
          <FormLabel
            htmlFor="card_number"
            sx={{
              display: "inline-block",
              marginBottom: "0.25rem",
            }}
          >
            <Typography variant="body1" sx={{ color: "black", fontWeight: 600 }}>
              Card Number
            </Typography>
          </FormLabel>
          <TextField
            id="card_number"
            {...formik.getFieldProps("card_number")}
            {...getFieldErrors("card_number", formik)}
            inputProps={{
              inputMode: "numeric",
              placeholder: "XXXX XXXX XXXX XXXX",
            }}
            fullWidth
            onChange={(event) => {
              formik.setFieldValue("card_number", beautifyCardNumber(event.target.value))
            }}
          />
        </Box>
        <Box flexBasis="30%">
          <FormLabel
            htmlFor="cvn"
            sx={{
              display: "inline-block",
              marginBottom: "0.25rem",
            }}
          >
            <Typography variant="body1" sx={{ color: "black", fontWeight: 600 }}>
              CVV
            </Typography>
          </FormLabel>
          <TextField
            placeholder="XXX"
            {...formik.getFieldProps("cvn")}
            onChange={(event) => {
              formik.setFieldValue("cvn", isEmptyOrNumber(event) ? event.target.value : formik.values.cvn)
            }}
            inputProps={{
              inputMode: "numeric",
              maxLength: isAmex(formik.values.card_number) ? 4 : 3,
              minLength: isAmex(formik.values.card_number) ? 4 : 3,
            }}
            {...getFieldErrors("cvn", formik)}
          />
        </Box>
      </InlineContainer>
      <Box width="100%" marginTop="0.75rem">
        <InlineContainer justifyContent="start" alignItems="start" gap="1rem">
          <Box flexBasis="100%">
            <FormLabel
              htmlFor="expiration_month"
              sx={{
                display: "inline-block",
                marginBottom: "0.25rem",
              }}
            >
              <Typography variant="body1" sx={{ color: "black", fontWeight: 600, whiteSpace: "nowrap" }}>
                Exp Month
              </Typography>
            </FormLabel>
            <Select
              id="expiration_month"
              {...formik.getFieldProps("expiration_month")}
              {...getFieldErrors("expiration_month", formik)}
            >
              {Object.entries(MonthsAbbreviated).map(([month, value]) => (
                <MenuItem key={month} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box flexBasis="100%">
            <FormLabel
              htmlFor="expiration_month"
              sx={{
                display: "inline-block",
                marginBottom: "0.25rem",
              }}
            >
              <Typography variant="body1" sx={{ color: "black", fontWeight: 600, whiteSpace: "nowrap" }}>
                Exp Year
              </Typography>
            </FormLabel>
            <Select {...formik.getFieldProps("expiration_year")} {...getFieldErrors("expiration_year", formik)}>
              {getFutureYears().map((year) => (
                <MenuItem key={year} value={year.toString()}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box flexBasis="120%">
            <FormLabel
              htmlFor="billing_zip_code"
              sx={{
                display: "inline-block",
                marginBottom: "0.25rem",
              }}
            >
              <Typography variant="body1" sx={{ color: "black", fontWeight: 600, whiteSpace: "nowrap" }}>
                Zip Code
              </Typography>
            </FormLabel>
            <TextField
              id="billing_zip_code"
              {...formik.getFieldProps("billing_zip_code")}
              onChange={(event) => {
                formik.setFieldValue(
                  "billing_zip_code",
                  isEmptyOrNumber(event, /^[\d-]+$/) ? event.target.value : formik.values.billing_zip_code,
                )
              }}
              {...getFieldErrors("billing_zip_code", formik)}
              placeholder="XXXXX"
            />
          </Box>
        </InlineContainer>
      </Box>
    </>
  )
}

export default AddCreditCard
