import Dialog, { DialogContent } from "@/components/Dialog"
import NiceModal, { useModal } from "@ebay/nice-modal-react"
import Box from "@mui/material/Box"
import InlineContainer from "@/components/InlineContainer"
import PhoneIcon from "@mui/icons-material/PhoneIphoneRounded"
import EmailIcon from "@mui/icons-material/MailOutlineRounded"
import { useTheme, useMediaQuery } from "@mui/material"
import Avatar from "@mui/material/Avatar"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import getInitialsFromName from "@/utils/getInitials"
import { AccountRoles, accountStatusList } from "@/types"
import DialogActions from "@mui/material/DialogActions"
import { useMutation } from "@tanstack/react-query"
import { deleteInvitation, resendInvitation } from "@/features/account-management/account-management-queries"
import useCurrentRole from "@/hooks/useCurrentRole"
import NeutralButton from "@/components/buttons/NeutralButton"
import AffirmativeButton from "@/components/buttons/AffirmativeButton"
import ErrorButton from "@/components/buttons/ErrorButton"
import useAppSelector from "@/hooks/useAppSelector"
import { selectAdminRole } from "@/features/auth/auth-selectors"

export default NiceModal.create<{ member: any }>(({ member }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const modal = useModal()
  const isAdmin = useAppSelector(selectAdminRole)
  const { isOwner } = useCurrentRole()

  const resendInvite = useMutation(async (id: string) => resendInvitation(id), {
    onSuccess: () => {
      modal.resolve()
      modal.hide()
    },
  })

  const deleteInvite = useMutation(async (id: string) => deleteInvitation(id), {
    onSuccess: () => {
      modal.reject()
      modal.hide()
    },
  })

  return (
    <Dialog modal={modal} open={isMobile ? modal.visible : false} title="Member Details">
      <DialogContent>
        <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
          <Avatar src={member?.avatar_url} sx={{ marginY: 2.5, width: 80, height: 80 }}>
            {getInitialsFromName(member?.name ?? member?.email)}
          </Avatar>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            {member?.name}
          </Typography>
          <Typography variant="body2">{accountStatusList?.[member?.status]}</Typography>
          <Divider
            flexItem
            sx={{
              borderStyle: "dashed !important",
              borderBottomWidth: "2px !important",
              margin: "2rem 0 !important",
              padding: 0,
            }}
          />
          <Typography variant="body1" sx={{ fontWeight: 500, marginBottom: "0.5rem" }}>
            Contact Information
          </Typography>
          <InlineContainer>
            <PhoneIcon fontSize="small" sx={{ color: "grey.500", marginRight: 1 }} />
            <Typography variant="body2" sx={{ marginY: 1 }}>
              {member?.cell_phone ?? "N/A"}
            </Typography>
          </InlineContainer>
          <InlineContainer>
            <EmailIcon fontSize="small" sx={{ color: "grey.500", marginRight: 1 }} />
            <Typography
              variant="body2"
              sx={{
                marginY: 1,
              }}
            >
              {member?.email ?? "N/A"}
            </Typography>
          </InlineContainer>
          <Divider
            flexItem
            sx={{
              borderStyle: "dashed !important",
              borderBottomWidth: "2px !important",
              margin: "2rem 0 !important",
              padding: 0,
            }}
          />
          <Typography variant="body1" sx={{ fontWeight: 500, marginBottom: "0.5rem" }}>
            Notifications
          </Typography>
          <InlineContainer>
            <Typography variant="body2" sx={{ marginY: 1, width: 100 }}>
              Email
            </Typography>
            <Typography variant="body2">{member?.do_receive_email_notifications ? "On" : "Off"}</Typography>
          </InlineContainer>
          <InlineContainer>
            <Typography variant="body2" sx={{ marginY: 1, width: 100 }}>
              SMS
            </Typography>
            <Typography variant="body2">{member?.do_receive_sms_notifications ? "On" : "Off"}</Typography>
          </InlineContainer>
        </Box>
      </DialogContent>
      {((member?.role?.type !== AccountRoles.ACCOUNT_OWNER && isOwner) || isAdmin) && (
        <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
          {member?.role?.type === AccountRoles.ACCOUNT_MEMBER_PENDING && (
            <InlineContainer justifyContent="space-between" width="100%">
              <NeutralButton onClick={() => deleteInvite.mutate(member?.role?.id)} disableElevation>
                Cancel Invite
              </NeutralButton>
              <AffirmativeButton type="submit" onClick={() => resendInvite.mutate(member?.role?.id)}>
                Resend Invite
              </AffirmativeButton>
            </InlineContainer>
          )}
          {member?.role?.type === AccountRoles.ACCOUNT_MEMBER && (
            <InlineContainer justifyContent="center" width="100%">
              <ErrorButton onClick={() => deleteInvite.mutate(member?.role?.id)} fullWidth>
                Remove Member
              </ErrorButton>
            </InlineContainer>
          )}
        </DialogActions>
      )}
    </Dialog>
  )
})
