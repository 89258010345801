import { initApi, routes } from "@/config/api"

export const updateWallet = async (walletId, values: any) =>
  await initApi()
    .patch(routes.wallet.base(walletId), values)
    .then(({ data }) => data?.data)

export const createWalletInstrument = async (walletId, values: any) =>
  await initApi()
    .post(routes.wallet.walletInstrument(walletId), values)
    .then(({ data }) => data?.data)

export const getPlaidLinkToken = async (walletId: string, isDisableMicroDeposit: boolean) =>
  await initApi()
    .get(routes.wallet.plaidLinkToken(walletId, isDisableMicroDeposit))
    .then(({ data }) => data?.data)

export const verifyWalletInstrument = async (walletInstrumentId) =>
  await initApi()
    .post(routes.wallet.instrumentVerify(walletInstrumentId))
    .then(({ data }) => data?.data)

export const deleteWalletInstrument = async (instrumentId: string, replacementInstrumentId?: string | null) =>
  await initApi()
    .delete(
      routes.wallet.instrument(instrumentId),
      Boolean(replacementInstrumentId) ? { data: { replacementInstrumentId } } : undefined,
    )
    .then(({ data }) => data?.data)

export const fetchFuturePayments = async (instrumentId: string | null) => {
  if (instrumentId === null) {
    return
  }

  return await initApi()
    .get(routes.wallet.futurePayments(instrumentId))
    .then(({ data }) => data?.data)
}
