import { useGoogleLogin } from "@react-oauth/google"
import Image from "next/legacy/image"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material"

export const GoogleButton = styled(Button)(
  ({ theme }) => `
	background: ${theme.palette.common.white};
	border: 1px solid ${theme.palette.primary.main};
	color: ${theme.palette.primary.main};
	
	&:hover {
		background: ${theme.palette.primary.dark};
		color: ${theme.palette.common.white};
	}
`,
)

const GoogleAuthButton = ({
  action,
  size = "medium",
  variant = "login",
}: {
  action: ({ googleAuthToken }) => void
  size?: "small" | "medium" | "large"
  variant?: "login" | "signup"
}) => {
  const initiateGoogleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: (profile) => action({ googleAuthToken: profile?.code }),
  })

  return (
    <GoogleButton
      size={size}
      onClick={() => initiateGoogleLogin()}
      fullWidth
      variant="contained"
      startIcon={<Image src="/google-icon.png" width={18} height={18} alt="Login with Google Logo" />}
      sx={{
        backgroundColor: "grey.100",
        borderColor: "primary.dark",
        color: "primary.dark",
        "&:hover": {
          color: "primary.contrastText",
        },
      }}
    >
      <Typography
        variant={size === "small" ? "body2" : "body1"}
        sx={{
          flexBasis: "100%",
          fontWeight: 500,
        }}
      >
        {variant === "signup" ? "Sign Up" : "Login"} with Google
      </Typography>
    </GoogleButton>
  )
}

export default GoogleAuthButton
