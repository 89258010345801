import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const BankIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path d="M5.87946 15.8262V21.2257C5.87946 22.3461 6.78388 23.2506 7.90429 23.2506C9.02469 23.2506 9.92911 22.3461 9.92911 21.2257V15.8262C9.92911 14.7058 9.02469 13.8014 7.90429 13.8014C6.78388 13.8014 5.87946 14.7058 5.87946 15.8262ZM13.9788 15.8262V21.2257C13.9788 22.3461 14.8832 23.2506 16.0036 23.2506C17.124 23.2506 18.0284 22.3461 18.0284 21.2257V15.8262C18.0284 14.7058 17.124 13.8014 16.0036 13.8014C14.8832 13.8014 13.9788 14.7058 13.9788 15.8262ZM5.20452 30H26.8027C27.9231 30 28.8275 29.0956 28.8275 27.9752C28.8275 26.8548 27.9231 25.9503 26.8027 25.9503H5.20452C4.08411 25.9503 3.17969 26.8548 3.17969 27.9752C3.17969 29.0956 4.08411 30 5.20452 30ZM22.0781 15.8262V21.2257C22.0781 22.3461 22.9825 23.2506 24.1029 23.2506C25.2233 23.2506 26.1277 22.3461 26.1277 21.2257V15.8262C26.1277 14.7058 25.2233 13.8014 24.1029 13.8014C22.9825 13.8014 22.0781 14.7058 22.0781 15.8262ZM14.7482 2.31385L4.08411 7.92937C3.53066 8.21285 3.17969 8.7933 3.17969 9.41425C3.17969 10.3457 3.93562 11.1016 4.86704 11.1016H27.1537C28.0716 11.1016 28.8275 10.3457 28.8275 9.41425C28.8275 8.7933 28.4765 8.21285 27.9231 7.92937L17.259 2.31385C16.4761 1.89538 15.5311 1.89538 14.7482 2.31385Z" />
    </SvgIcon>
  )
}

export default BankIcon
