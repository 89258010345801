import { useRouter } from "next/router"
import { DateTime } from "luxon"
import { Button, styled } from "@mui/material"
import MuiAvatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Popover from "@mui/material/Popover"
import ListItemIcon from "@mui/material/ListItemIcon"
import MuiMenuItem from "@mui/material/MenuItem"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import InlineContainer from "@/components/InlineContainer"
import PlusIcon from "@mui/icons-material/AddRounded"
import NorthEastIcon from "@mui/icons-material/SubdirectoryArrowRight"
import { usePopupState, bindTrigger, bindMenu } from "material-ui-popup-state/hooks"
import Link from "@/components/Link"
import useAppDispatch from "@/hooks/useAppDispatch"
import useUser from "@/features/auth/useUser"
import getInitialsFromName from "@/utils/getInitials"
import { logoutThunk } from "@/features/auth/auth-queries"
import { selectSpoof } from "@/features/auth/auth-selectors"
import { useSelector } from "react-redux"
import { unspoofUser } from "@/features/admin-management/admin-management-queries"
import { setAccountId, setPartnershipId, setSpoof } from "@/features/auth/auth-reducers"
import { useMutation } from "@tanstack/react-query"
import ChevronRightRounded from "@mui/icons-material/ChevronRightRounded"
import NiceModal from "@ebay/nice-modal-react"
import { DialogKeys } from "@/types"
import useAccount from "@/features/account-management/useAccount"
import usePartnership from "@/features/partner-management/usePartnership"
import { useSnackbar } from "notistack"
import { clientBaseUrl } from "@/config/api"
import usePartnershipRole from "@/features/partner-management/usePartnershipRole"

const Avatar = styled(MuiAvatar)(
  ({ theme }) => `
  font-size: ${theme.spacing(2)};
  margin-right: ${theme.spacing(0)};
  padding: 0;
  width: 36px;
  height: 36px;

  svg {
    margin: 0;
  }
`,
)

const Menu = styled(Popover)(
  ({ theme }) => `
  margin-top: ${theme.spacing(1)};
  max-height: 75vh;
  
  &.MuiModal-root > .MuiPopover-paper {
    border-radius: 0;
    margin-left: -16px;
    max-width: 100% !important;
    padding-top: 0.75rem;
    width: 100% !important;
  }
  
  ${theme.breakpoints.up("md")} {
    display: block;
    
    &.MuiModal-root > .MuiPopover-paper {
      border-radius: ${theme.spacing(1.5)};
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      margin-left: 0px;
      max-width: none !important;
      width: auto !important;
    }
  }
  
  ${theme.breakpoints.up("lg")} {
    margin-top: 0;

    &.MuiModal-root > .MuiPopover-paper {
      border-radius: ${theme.spacing(1.5)};
    }
  }
`,
)

const MenuItem = styled(MuiMenuItem)(
  ({ theme }) => `
  font-size: ${theme.spacing(1.75)};

  svg {
    margin-right: ${theme.spacing(1)}
  }
`,
)

const LegalInfo = styled(Typography)(
  ({ theme }) => `
  font-size: 12px;
  margin-top: ${theme.spacing(1)};
  
  a {
    font-size: 12px;
  }
`,
)

const UserMenu = () => {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const { account, actions: accountActions } = useAccount()
  const { user } = useUser()
  const isAdminPage = router?.pathname?.includes("/admin")
  const { partnership } = usePartnership()
  const menu = usePopupState({
    variant: "popover",
    popupId: "user-menu",
  })
  const { isPartnerMember } = usePartnershipRole()
  const { enqueueSnackbar } = useSnackbar()

  const handleLogout = async () => {
    const url =
      "/login" +
      (partnership?.id || isPartnerMember ? `?partnership_id=${partnership?.id ?? account?.partnership_id}` : "")

    await dispatch(logoutThunk())
    menu.close()
    router.push(url)
  }

  const handleCreateAccount = () => {
    menu.close()

    NiceModal.show(DialogKeys.CONFIRM, {
      title: "Create a Business",
      content: "Are you sure you want to create a new business account?",
      confirmButton: "Create",
      cancelButton: "Close",
    }).then(() => {
      accountActions?.create(account?.partnership?.id || null)
    })
  }

  const isSpoof = useSelector(selectSpoof)
  const unSpoof = useMutation(unspoofUser, {
    onSuccess: () => {
      dispatch(setSpoof(false))
      dispatch(setAccountId(null))
      dispatch(setPartnershipId(null))
      window.location.href = clientBaseUrl + "/admin"
    },
    onError: (e) => {
      enqueueSnackbar(
        // @ts-ignore
        e?.response?.data?.message || "Could not switch to selected account",
        {
          variant: "error",
        },
      )
    },
  })

  return (
    <>
      <IconButton {...bindTrigger(menu)}>
        {isSpoof ? (
          <Avatar sx={{ background: "red" }}>
            <NorthEastIcon sx={{ fontSize: "1.35rem" }} />
          </Avatar>
        ) : (
          <Avatar color="primary" src={user?.avatar_url}>
            {getInitialsFromName(user?.name)}
          </Avatar>
        )}
      </IconButton>
      <Menu
        {...bindMenu(menu)}
        elevation={2}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {isSpoof && (
          <MenuItem sx={{ padding: 0 }}>
            <Button
              sx={{
                color: "white",
                backgroundColor: "red",
                fontWeight: 400,
                width: "100%",
                "&:hover": {
                  backgroundColor: "red",
                },
              }}
              onClick={() => {
                unSpoof.mutate()
              }}
            >
              Leave Spoof Mode
            </Button>
          </MenuItem>
        )}
        {user?.is_payer && (
          <Typography
            variant="body2"
            sx={{
              fontWeight: 500,
              padding: "0 1rem 0.25rem 1rem",
              textTransform: "uppercase",
            }}
          >
            Payer Portal
          </Typography>
        )}
        <MenuItem
          disableRipple={!user?.is_payer}
          onClick={() => {
            return router.push(clientBaseUrl + "/user/payments")
          }}
          sx={{
            "&:hover": {
              bgcolor: user?.is_payer ? "rgba(0, 0, 0, 0.04)" : "transparent",
              cursor: user?.is_payer ? "pointer" : "default",
              "& .MuiIconButton-root": {
                display: user?.is_payer ? "inline-flex" : "none",
              },
            },
          }}
        >
          <InlineContainer flexDirection="row" justifyContent="start" width="100%">
            <Avatar
              variant="circular"
              sx={{
                width: 42,
                height: 42,
                fontSize: "1rem",
                fontWeight: 600,
                marginRight: "0.75rem",
              }}
              src={user?.avatar_url}
            >
              {getInitialsFromName(user?.name)}
            </Avatar>
            <Box flexBasis="100%">
              <Typography
                variant="body1"
                noWrap
                sx={{
                  fontWeight: 500,
                  marginBottom: 0,
                  overflow: "hidden",
                  maxWidth: "200px",
                }}
              >
                {user?.name}
              </Typography>
              <Typography
                variant="body2"
                noWrap
                sx={{
                  color: "grey.600",
                  fontSize: "0.8rem",
                  marginTop: "-0.2rem",
                  maxWidth: "200px",
                }}
              >
                {user?.email}
              </Typography>
            </Box>
            {user?.is_payer && <ChevronRightRounded fontSize="small" />}
          </InlineContainer>
        </MenuItem>
        <MenuItem sx={{ padding: 0 }}>
          <Link
            href={clientBaseUrl + "/user/settings"}
            sx={{
              color: "black",
              fontWeight: 400,
              padding: "0.4rem 0.4rem 0.4rem 4.5rem",
              width: "100%",
              "&:hover": {
                textDecoration: "none",
              },
            }}
          >
            Personal Settings
          </Link>
        </MenuItem>
        <MenuItem sx={{ padding: 0 }}>
          <Link
            href={partnership ? `mailto:${partnership?.finli_support_email}}` : clientBaseUrl + "/support"}
            target="_blank"
            sx={{
              color: "black",
              fontWeight: 400,
              padding: "0.4rem 0.4rem 0.4rem 4.5rem",
              width: "100%",
              "&:hover": {
                textDecoration: "none",
              },
            }}
          >
            Support
          </Link>
        </MenuItem>
        <MenuItem sx={{ paddingLeft: "4.5rem" }} onClick={handleLogout}>
          Logout
        </MenuItem>
        {Boolean(user?.roles?.length) && !partnership?.id && !isAdminPage && (
          <>
            <Divider />
            <MenuItem onClick={handleCreateAccount}>
              <ListItemIcon sx={{ minWidth: "30px" }}>
                <PlusIcon color="primary" sx={{ fontSize: "1.25rem" }} />
              </ListItemIcon>
              <Typography color="primary" variant="body2" fontWeight={500}>
                Create a new business
              </Typography>
            </MenuItem>
          </>
        )}
        <Divider />
        <Box margin="0.5rem 1.25rem" width="260px">
          <LegalInfo variant="body2">
            <Link
              href="https://finli.com/terms-and-conditions/"
              target="_blank"
              color="primary.dark"
              sx={{ fontWeight: 600 }}
            >
              Terms of Service
            </Link>
            <Link
              href="https://finli.com/privacy-policy/"
              target="_blank"
              color="primary.dark"
              style={{
                display: "inline-block",
                marginLeft: "1rem",
                fontWeight: 600,
              }}
            >
              Privacy Policy
            </Link>
          </LegalInfo>
          <LegalInfo sx={{ marginTop: 0 }}>
            2019 - {DateTime.now().toFormat("yyyy")} {String.fromCodePoint(0x00a9)} Finli
          </LegalInfo>
        </Box>
      </Menu>
    </>
  )
}

export default UserMenu
