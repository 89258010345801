import Box from "@mui/material/Box"
import InlineContainer from "@/components/InlineContainer"
import Typography from "@mui/material/Typography"
import useAppSelector from "@/hooks/useAppSelector"
import FormattedNumber from "@/components/FormattedNumber"
import { InvoiceItem, InvoiceStatus, QuantityMeasurement, QuoteItem, QuoteStatus } from "@/types"
import { getInvoiceTotals } from "@/utils/calculations"
import { getChangeAmount, getDollarsFromInt, getIntFromDollars, calculatePriceModifier } from "@/utils/calculations"
import Decimal from "decimal.js-light"
import { Fragment } from "react"
import { selectInvoice, selectInvoicePreview } from "@/features/invoice-management/invoice-management-selectors"
import useAccount from "@/features/account-management/useAccount"
import EditableAvatar from "@/features/builder/EditableAvatar"
import AffirmativeButton from "@/components/buttons/AffirmativeButton"
import FormattedPhone from "@/components/FormattedPhone"
import { padWithZeros } from "@/utils/stringUtils"
import { DateTime } from "luxon"
import { calculateItemGroup, INDIVIDUAL_ITEMS } from "@/features/builder/utils"
import { selectQuote, selectQuotePreview } from "../quote-management/quote-management-selectors"
import { formatDollar } from "@/utils/formatters"

const InvoiceQuotePreview = ({
  sync = false,
  is_interactable,
  hideCustomerContact = false,
  mode,
}: {
  sync: boolean
  is_interactable: boolean
  hideCustomerContact?: Boolean
  mode: "invoice" | "quote"
}) => {
  const invoiceQuote = useAppSelector(
    mode === "invoice" ? (sync ? selectInvoice : selectInvoicePreview) : sync ? selectQuote : selectQuotePreview,
  )
  // In the preview we only want to show the first customer of the list, makes things easier
  const customer = invoiceQuote?.customers?.[0]
  // for draft case
  const { account: accountFromPortal } = useAccount()
  let account = invoiceQuote?.account || accountFromPortal
  const { categories, itemDict } = calculateItemGroup(invoiceQuote?.items || [])

  const invoiceQuoteModifier = {
    id: invoiceQuote?.account_pricemodifier_id,
    name: invoiceQuote?.pricemodifier_name,
    value_pct: invoiceQuote?.pricemodifier_value_pct,
    value_flat: invoiceQuote?.pricemodifier_value_flat,
  }

  const { subTotal, invoiceTotal: total, taxSummary } = getInvoiceTotals(invoiceQuote)

  const amount = getDollarsFromInt(getChangeAmount(getIntFromDollars(subTotal), 1, invoiceQuoteModifier))

  const { name: invoice_pm_name } = calculatePriceModifier(invoiceQuote)

  const isSameDate = mode === "invoice" && invoiceQuote?.statement_period_start === invoiceQuote?.statement_period_end

  const dataObject = mode === "invoice" ? "Invoice" : "Quote"

  return (
    <Box padding={2}>
      <InlineContainer marginBottom="1rem" alignItems="flex-start">
        <Box flexBasis="100%" display="flex" flexDirection="column" alignItems="start">
          <Typography sx={{ fontSize: "1.2rem", fontWeight: 600, marginBottom: "1rem" }}>
            {dataObject} #{padWithZeros(invoiceQuote?.invoice_number || (account?.invoice_number || 0) + 1 || 0)}
          </Typography>{" "}
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {account?.public_name}
          </Typography>{" "}
          {account?.public_address1 && (
            <>
              <Typography variant="body1">{account?.public_address1}</Typography>
              {account?.public_address2 && <Typography variant="body1">{account?.public_address2}</Typography>}
              <Typography variant="body1">
                {[account?.public_city, account?.public_state, account?.public_zip_code].join(", ")}
              </Typography>
            </>
          )}
          {account?.public_email && <Typography variant="body1">{account?.public_email}</Typography>}
          {account?.public_phone && (
            <Typography variant="body1">
              <FormattedPhone value={account?.public_phone} />
            </Typography>
          )}
        </Box>
        <Box display="flex" flexDirection="column" flexBasis="50%" alignItems="end">
          <EditableAvatar
            isEditable={is_interactable && !invoiceQuote.is_readonly}
            avatar_url={account?.avatar_url}
            public_name={account?.public_name}
          />
        </Box>
      </InlineContainer>
      <InlineContainer alignItems="flex-start" marginBottom="1rem">
        <Box flexBasis="100%" display="flex" flexDirection="column" alignItems="start">
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {mode === "invoice" ? "Bill to" : "Quote for"}
          </Typography>
          {customer?.name && (
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              {customer?.name}
            </Typography>
          )}
          {customer?.email && !hideCustomerContact && <Typography variant="body1">{customer?.email}</Typography>}
          {customer?.cell_phone && !hideCustomerContact && (
            <Typography variant="body1">
              <FormattedPhone value={customer?.cell_phone} />
            </Typography>
          )}
        </Box>
        {mode === "invoice" && (
          <Box display="flex" flexDirection="column" minWidth="20rem" alignItems="start">
            {invoiceQuote?.is_recurring && (
              <InlineContainer>
                <Typography color="primary.dark" variant="body2" sx={{ fontWeight: 500 }} noWrap>
                  Recurring Invoice
                </Typography>
              </InlineContainer>
            )}
            {invoiceQuote?.due_at && (
              <InlineContainer textAlign="left" width="100%" alignItems="flex-start">
                <Typography variant="body1" sx={{ fontWeight: 600 }} minWidth="8rem">
                  Due Date
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 500, paddingTop: "0.1rem" }}>
                  {DateTime.fromFormat(invoiceQuote?.due_at?.split("T")[0], "yyyy-MM-dd")
                    .startOf("day")
                    .toFormat("MM/dd/yyyy")}
                </Typography>
              </InlineContainer>
            )}
            {invoiceQuote?.statement_period_start && (
              <InlineContainer textAlign="left" width="100%" alignItems="flex-start">
                <Typography variant="body1" sx={{ fontWeight: 600 }} minWidth="8rem">
                  Invoice Date{isSameDate ? "" : "s"}
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 500, paddingTop: "0.1rem" }}>
                  {DateTime.fromFormat(invoiceQuote?.statement_period_start, "yyyy-MM-dd").toFormat("MM/dd/yyyy")}
                  {isSameDate || !invoiceQuote?.statement_period_end
                    ? ""
                    : ` to ${DateTime.fromFormat(invoiceQuote?.statement_period_end as string, "yyyy-MM-dd").toFormat("MM/dd/yyyy")}`}
                </Typography>
              </InlineContainer>
            )}
          </Box>
        )}
      </InlineContainer>
      <InlineContainer
        sx={{
          borderRadius: "0.25rem",
          justifyContent: "space-between",
          margin: "1rem 0 0 0",
          padding: "0.25rem 1rem",
          border: "1px solid lightgrey",
        }}
      >
        <Typography variant="body2" sx={{ flexBasis: "200%" }}>
          Item Name
        </Typography>
        <Typography variant="body2" sx={{ flexBasis: "125%" }}>
          Qty/Rate
        </Typography>
        <Typography variant="body2" sx={{ flexBasis: "60%", textAlign: "right" }}>
          Amount
        </Typography>
      </InlineContainer>
      <Box marginTop="0.5rem">
        {categories?.map((category) => {
          const itemList: (InvoiceItem | QuoteItem)[] = itemDict[category]
          const categoryName = itemList[0]?.account_customer_category_name
          return (
            <Fragment key={`invoice-quote-preview-${categoryName}-${category}`}>
              {category === INDIVIDUAL_ITEMS || !Boolean(category) ? null : (
                <InlineContainer
                  justifyContent="space-between"
                  sx={{
                    backgroundColor: "grey.400",
                    color: "black",
                    padding: "0.25rem 1rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  <Typography variant="h6" sx={{ fontSize: "1rem", fontWeight: 700 }}>
                    {categoryName}
                  </Typography>
                </InlineContainer>
              )}

              {itemList?.map((item) => {
                const name = item?.name
                const description = item?.description

                const price = getDollarsFromInt(item?.price ?? 0)
                const lineTotal = new Decimal(price ?? 0).times(item?.quantity ?? 0).toNumber()

                const priceModifier = {
                  name: item?.pricemodifier_name,
                  value_pct: item?.pricemodifier_value_pct,
                  value_flat: item?.pricemodifier_value_flat,
                }

                const { name: pm_name } = calculatePriceModifier(item)

                let changeAmount = getDollarsFromInt(getChangeAmount(item?.price, item?.quantity, priceModifier))

                if (changeAmount < -lineTotal) changeAmount = -lineTotal

                return (
                  <Box key={`invoice-quote-preview-${item?.id || name}`} padding="0 1rem" marginBottom="0.75rem">
                    <InlineContainer justifyContent="space-between">
                      <Typography variant="body1" flexBasis="200%">
                        {name}{" "}
                        {!item?.is_recurring && invoiceQuote.is_recurring && mode === "invoice" && (
                          <Box component="span" sx={{ color: "primary.dark" }}>
                            (One Time)
                          </Box>
                        )}
                      </Typography>
                      <InlineContainer flexBasis="125%">
                        <Typography variant="body2">
                          {item?.quantity}{" "}
                          {item?.quantity_measurement === QuantityMeasurement.UNIT
                            ? "Qty."
                            : `Hr${item?.quantity > 1 ? "s" : ""}.`}{" "}
                          x <FormattedNumber prefix="$" value={price} />
                        </Typography>
                      </InlineContainer>
                      <InlineContainer justifyContent="flex-end" flexBasis="60%" textAlign="right">
                        <Typography variant="body2">
                          <FormattedNumber prefix="$" value={lineTotal} />
                        </Typography>
                      </InlineContainer>
                    </InlineContainer>
                    {description && (
                      <Typography variant="body2" sx={{ paddingLeft: "1rem", color: "grey.600" }}>
                        {description}
                      </Typography>
                    )}
                    {priceModifier?.name && (
                      <InlineContainer justifyContent="space-between" width="100%" paddingLeft="1rem">
                        <Typography variant="body2" sx={{ color: "grey.600" }}>
                          {pm_name}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "grey.600" }}>
                          <FormattedNumber prefix="$" value={changeAmount} />
                        </Typography>
                      </InlineContainer>
                    )}
                  </Box>
                )
              })}
            </Fragment>
          )
        })}

        <InlineContainer
          justifyContent="space-between"
          marginTop="0.5rem"
          padding="0.5rem 1rem"
          border="1px solid lightgrey"
          borderBottom="0"
          borderRadius="0.25rem 0.25rem 0 0"
        >
          <Typography variant="body1">Subtotal</Typography>
          <Typography variant="body1">
            <FormattedNumber prefix="$" value={subTotal} />
          </Typography>
        </InlineContainer>
        {Boolean(invoiceQuoteModifier?.name) && (
          <InlineContainer
            key={`invoice-quote-preview-mods-${invoiceQuoteModifier?.id}`}
            color="grey.700"
            justifyContent="space-between"
            padding="0rem 1rem 0.5rem"
            border="1px solid lightgrey"
            borderTop="0"
            borderBottom="0"
          >
            <Typography variant="body2">
              {invoice_pm_name}{" "}
              {mode === "invoice" && !invoiceQuote?.is_pricemodifier_recurring && invoiceQuote?.is_recurring && (
                <Box component="span" sx={{ color: "primary.dark" }}>
                  (One Time)&nbsp;
                </Box>
              )}
            </Typography>
            <Typography variant="body2">
              <FormattedNumber prefix="$" fixedDecimalScale value={amount} />
            </Typography>
          </InlineContainer>
        )}
        {customer && !customer?.is_taxable && taxSummary.length === 0 ? (
          <InlineContainer
            justifyContent="space-between"
            padding="0.5rem 1rem"
            border="1px solid lightgrey"
            borderBottom="0"
            borderTop="0"
          >
            <Typography variant="body2">Tax exempt</Typography>
            <Typography variant="body2"></Typography>
          </InlineContainer>
        ) : (
          taxSummary?.map((tax) => (
            <InlineContainer
              key={`invoice-quote-preview-tax-summary-${tax.account_pricemodifier_id}`}
              justifyContent="space-between"
              padding="0.5rem 1rem"
              border="1px solid lightgrey"
              borderBottom="0"
              borderTop="0"
            >
              <Typography variant="body2">{tax.summary}</Typography>
              <Typography variant="body2">{formatDollar(tax.change)}</Typography>
            </InlineContainer>
          ))
        )}
        <InlineContainer
          justifyContent="space-between"
          marginBottom="1rem"
          padding="0.5rem 1rem"
          border="1px solid lightgrey"
          borderRadius="0 0 0.25rem 0.25rem"
        >
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Total
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            <FormattedNumber prefix="$" value={total} />
          </Typography>
        </InlineContainer>
        {invoiceQuote?.recipient_message ? (
          <Box padding="1rem 0">
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              {dataObject} Message
            </Typography>
            {(invoiceQuote?.recipient_message || "").split("\n").map((text, index) => (
              <Typography key={text + index} variant="body1" sx={{ overflowWrap: "break-word" }}>
                {text}
              </Typography>
            ))}
          </Box>
        ) : null}
        {![
          InvoiceStatus.DRAFT,
          InvoiceStatus.CANCELED,
          InvoiceStatus.SCHEDULED,
          InvoiceStatus.COMPLETED,
          QuoteStatus.DRAFT,
          QuoteStatus.CANCELED,
          QuoteStatus.APPROVED,
          QuoteStatus.INVOICED,
          QuoteStatus.REJECTED,
        ].includes(invoiceQuote?.status as InvoiceStatus | QuoteStatus) &&
          is_interactable && (
            <InlineContainer justifyContent="flex-end" marginTop="1rem">
              <AffirmativeButton
                sx={{ margin: "0.5rem 0 0 0", padding: "0.5rem 1.5rem" }}
                onClick={() => {
                  window.open(`/${mode === "invoice" ? "pay" : "approve"}/${invoiceQuote.id}`)
                }}
              >
                Review & {mode === "invoice" ? "Pay" : "Approve"}
              </AffirmativeButton>
            </InlineContainer>
          )}
      </Box>
    </Box>
  )
}

export default InvoiceQuotePreview
