import InputAdornment from "@mui/material/InputAdornment"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import LockIcon from "@mui/icons-material/Lock"
import Link from "@/components/Link"
import { AdornedInput, TextField, Adornment, FormLabel, Spacer } from "@/features/auth/components/styled"
import getFieldErrors, { doesFormHaveErrors } from "@/utils/getFieldErrors"
import SystemFeedback from "@/components/SystemFeedback"
import TestId from "@/constants/testIds"
import AffirmativeButton from "@/components/buttons/AffirmativeButton"
import NeutralButton from "@/components/buttons/NeutralButton"
import { PinInput } from "react-input-pin-code"
import SimpleLink from "@/components/SimpleLink"
import { useState } from "react"
import InlineContainer from "./InlineContainer"
import { AuthValues } from "@/types"
import GoogleAuthButton from "@/components/GoogleAuthButton"
import EmailOrPhoneField from "@/components/EmailOrPhoneField"
import { useRouter } from "next/router"

type LoginFormProps = {
  message: any
  loginFormik: any
  roleId?: string
  showPasswordInput: boolean
  showOneTimeCodeInput: boolean
  showTwoFaInput: boolean
  sendOneTimeCode: () => void
  setShowPasswordInput: (value: boolean) => void
  setShowOneTimeCodeInput: (value: boolean) => void
  setShowTwoFaInput: (value: boolean) => void
  resetForm: () => void
  alternateAction?: () => any
  initiateLogin: (values: AuthValues) => Promise<void>
  onForgotPassword?: () => void
  size?: "small" | "medium" | "large"
  isMobileApp?: boolean
  partnershipId?: string
}

const LoginForm = ({
  message,
  loginFormik,
  roleId = "",
  showPasswordInput,
  showOneTimeCodeInput,
  showTwoFaInput,
  sendOneTimeCode,
  setShowPasswordInput,
  setShowOneTimeCodeInput,
  setShowTwoFaInput,
  resetForm,
  initiateLogin,
  onForgotPassword,
  size = "medium",
  isMobileApp = false,
  alternateAction,
  partnershipId,
}: LoginFormProps) => {
  const [visible, setVisible] = useState(false)
  const router = useRouter()

  return (
    <>
      <SystemFeedback {...message} />

      <form onSubmit={loginFormik.handleSubmit}>
        {roleId ? null : <EmailOrPhoneField formik={loginFormik} />}

        {!showPasswordInput && !showOneTimeCodeInput && !showTwoFaInput && (
          <>
            <AffirmativeButton
              variant="contained"
              fullWidth
              type="button"
              size={size}
              onClick={sendOneTimeCode}
              disabled={Boolean(loginFormik?.errors.email_or_phone)}
              sx={{ fontWeight: 500, marginBottom: "1rem" }}
            >
              Use a one-time code
            </AffirmativeButton>
            <NeutralButton
              fullWidth
              size={size}
              sx={{
                backgroundColor: "grey.100",
                fontWeight: 500,
                "&:hover": {
                  color: "primary.contrastText",
                  backgroundColor: "primary.dark",
                },
              }}
              onClick={() => {
                setShowPasswordInput(true)
                setShowOneTimeCodeInput(false)
                setShowTwoFaInput(false)
              }}
              disabled={Boolean(loginFormik?.errors?.email_or_phone)}
              data-testid={TestId.Input.Continue}
            >
              Continue with password
            </NeutralButton>

            {!roleId && !isMobileApp && (
              <>
                <Spacer sx={{ margin: size === "small" ? "1.5rem 0" : "2.125rem 0" }}>
                  <span>or</span>
                  <Divider />
                </Spacer>
                <GoogleAuthButton action={initiateLogin} />
              </>
            )}
          </>
        )}

        {showOneTimeCodeInput && (
          <>
            <FormLabel htmlFor="password">One-time code</FormLabel>
            <PinInput
              autoFocus
              showState={false}
              placeholder=""
              size="lg"
              autoComplete="one-time-code"
              containerStyle={{
                flexBasis: "100%",
                justifyContent: "space-around",
                margin: "1rem 0 1.25rem 0",
              }}
              values={loginFormik.values.otc_code ?? ["", "", "", "", ""]}
              onChange={(_value, _index, values) => loginFormik.setFieldValue("otc_code", values)}
            />
            <AffirmativeButton
              variant="contained"
              fullWidth
              size={size}
              type="submit"
              disabled={doesFormHaveErrors(loginFormik?.errors)}
              sx={{ marginBottom: "1rem" }}
            >
              Continue
            </AffirmativeButton>
            <InlineContainer gap="0.35rem" justifyContent="center" sx={{ flexDirection: { xs: "column", sm: "row" } }}>
              <Typography variant="body2">Problems receiving the code?</Typography>
              <Typography variant="body2">
                <SimpleLink component="button" type="button" onClick={sendOneTimeCode}>
                  Resend Code
                </SimpleLink>
              </Typography>
            </InlineContainer>
            <Box />
            <Spacer sx={{ margin: size === "small" ? "1.5rem 0" : "2.125rem 0" }}>
              <span>or</span>
              <Divider />
            </Spacer>
            <NeutralButton
              fullWidth
              size={size}
              sx={{
                backgroundColor: "grey.100",
                fontWeight: 500,
                marginBottom: "1rem",
                "&:hover": {
                  color: "primary.contrastText",
                  backgroundColor: "primary.dark",
                },
              }}
              onClick={() => {
                setShowPasswordInput(true)
                setShowOneTimeCodeInput(false)
                setShowTwoFaInput(false)
                resetForm()
              }}
            >
              Use password instead
            </NeutralButton>
            {!isMobileApp && <GoogleAuthButton action={initiateLogin} />}
          </>
        )}

        {showTwoFaInput && (
          <>
            <FormLabel htmlFor="two_fa_token">One-time code</FormLabel>
            <PinInput
              autoFocus
              showState={false}
              placeholder=""
              size="lg"
              autoComplete="one-time-code"
              containerStyle={{
                flexBasis: "100%",
                justifyContent: "space-around",
                margin: "1rem 0 1.25rem 0",
              }}
              values={loginFormik.values.two_fa_token ?? ["", "", "", "", ""]}
              onChange={(_value, _index, values) => loginFormik.setFieldValue("two_fa_token", values)}
            />
            <AffirmativeButton
              variant="contained"
              fullWidth
              size={size}
              type="submit"
              disabled={doesFormHaveErrors(loginFormik?.errors)}
              sx={{ marginBottom: "1rem" }}
              data-testid={TestId.Input.Submit}
            >
              Login
            </AffirmativeButton>
            <Spacer sx={{ margin: size === "small" ? "1.5rem 0" : "2.125rem 0" }}>
              <span>or</span>
              <Divider />
            </Spacer>
            <NeutralButton
              fullWidth
              size={size}
              sx={{
                backgroundColor: "grey.100",
                fontWeight: 500,
                marginBottom: "1rem",
                "&:hover": {
                  color: "primary.contrastText",
                  backgroundColor: "primary.dark",
                },
              }}
              onClick={() => {
                setShowPasswordInput(true)
                setShowOneTimeCodeInput(false)
                setShowTwoFaInput(false)
                resetForm()
              }}
            >
              Use password instead
            </NeutralButton>
            {!isMobileApp && <GoogleAuthButton action={initiateLogin} />}
          </>
        )}

        {showPasswordInput && (
          <>
            <FormLabel htmlFor="password">Password</FormLabel>
            <AdornedInput sx={{ marginBottom: "0.35rem" }}>
              <Adornment>
                <LockIcon fontSize="small" />
              </Adornment>
              <TextField
                type={visible ? "text" : "password"}
                size="small"
                fullWidth
                variant="outlined"
                placeholder="Enter Password"
                autoComplete="current-password"
                {...loginFormik.getFieldProps("password")}
                {...getFieldErrors("password", loginFormik)}
                inputProps={{
                  tabIndex: 2,
                  "data-testid": TestId.Input.Password,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={() => setVisible(!visible)}>
                        {visible ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </AdornedInput>

            {roleId ? (
              <Box />
            ) : (
              <Box marginBottom="1rem" textAlign="right">
                <Typography variant="body2">
                  <Link
                    onClick={() => {
                      if (typeof onForgotPassword === "function") {
                        onForgotPassword()
                      }

                      if (router?.pathname?.includes("/login")) {
                        router.push(
                          `/forgot-password${
                            loginFormik?.values?.email_or_phone?.length
                              ? `?contact=${encodeURIComponent(loginFormik?.values?.email_or_phone)}`
                              : ""
                          }`,
                        )
                      } else {
                        router.push(
                          `/forgot-password?redirect=${window.location.href}${
                            loginFormik?.values?.email_or_phone?.length
                              ? `&contact=${encodeURIComponent(loginFormik?.values?.email_or_phone)}`
                              : ""
                          }`,
                        )
                      }
                    }}
                    data-testid={TestId.Login.ForgotPassword}
                  >
                    Forgot Password?
                  </Link>
                </Typography>
              </Box>
            )}
            <AffirmativeButton
              variant="contained"
              fullWidth
              size={size}
              type="submit"
              disabled={doesFormHaveErrors(loginFormik?.errors)}
              data-testid={TestId.Input.Submit}
              loading={loginFormik.isSubmitting}
            >
              {roleId ? "Accept Invitation" : "Login"}
            </AffirmativeButton>
            <Spacer sx={{ margin: size === "small" ? "1.5rem 0" : "2.125rem 0" }}>
              <span>or</span>
              <Divider />
            </Spacer>
            <NeutralButton
              fullWidth
              size={size}
              sx={{
                backgroundColor: "grey.100",
                fontWeight: 500,
                marginBottom: "1rem",
                "&:hover": {
                  color: "primary.contrastText",
                  backgroundColor: "primary.dark",
                },
              }}
              onClick={sendOneTimeCode}
            >
              Use one-time code instead
            </NeutralButton>
            {!isMobileApp && <GoogleAuthButton action={initiateLogin} />}
          </>
        )}
        {isMobileApp ? null : (
          <Typography variant="body2" sx={{ color: "grey.600", marginTop: "1rem" }}>
            Don't have an account?{" "}
            <Link
              onClick={() => {
                if (
                  typeof alternateAction === "function" ||
                  (alternateAction !== null && alternateAction !== undefined)
                ) {
                  alternateAction()
                } else {
                  router.push("/signup" + (partnershipId ? `?partnership_id=${partnershipId}` : ""))
                }
              }}
            >
              Sign up
            </Link>
          </Typography>
        )}
      </form>
    </>
  )
}

export default LoginForm
