import UserIcon from "@mui/icons-material/PersonOutlineOutlined"
import { AdornedInput, FormLabel, TextField, Adornment } from "@/features/auth/components/styled"
import getFieldErrors from "@/utils/getFieldErrors"
import TestId from "@/constants/testIds"

const EmailOrPhoneField = ({ formik, testId = TestId.Input.EmailOrPhone, hideIcon = false }) => {
  return (
    <>
      <FormLabel htmlFor="email_or_phone">Email or Mobile Phone</FormLabel>
      <AdornedInput sx={{ paddingLeft: 0 }}>
        {!hideIcon && (
          <Adornment>
            <UserIcon fontSize="small" />
          </Adornment>
        )}
        <TextField
          type="text"
          size="small"
          variant="outlined"
          fullWidth
          placeholder="Email or Mobile Phone #"
          autoComplete="username"
          {...formik.getFieldProps("email_or_phone")}
          {...getFieldErrors("email_or_phone", formik)}
          inputProps={{
            tabIndex: 1,
            "data-testid": testId,
          }}
          onChange={(e) => {
            const value = e.target.value

            // Checks if the value is an email
            if (value.match(/\@|\.|[a-zA-Z]/g)) {
              formik.setFieldValue("isPhone", 0)
            } else {
              formik.setFieldValue("isPhone", 1)
            }

            formik.setFieldValue("email_or_phone", value)
          }}
          sx={
            hideIcon
              ? {
                  "& .MuiOutlinedInput-root": {
                    paddingLeft: 0,
                  },
                  "& .MuiInputLabel-root": {
                    left: 0,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    paddingLeft: 0,
                  },
                }
              : {}
          }
        />
      </AdornedInput>
    </>
  )
}

export default EmailOrPhoneField
