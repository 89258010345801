import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Query, Account } from "@/types"
import {
  createNewAccount,
  fetchAccountDetails,
  switchAccount,
} from "@/features/account-management/account-management-queries"
import useAppSelector from "@/hooks/useAppSelector"
import { selectAccountId } from "@/features/auth/auth-selectors"
import { useSnackbar } from "notistack"
import decodeToken from "@/utils/saveSessionToken"
import useAppDispatch from "@/hooks/useAppDispatch"
import { clientBaseUrl } from "@/config/api"
import { setAccountId, setAdminRole, setPartnershipId } from "../auth/auth-reducers"

interface UseAccount {
  account?: Account
  actions: {
    create: (accountId: string | null) => void
    switch: (accountId: string) => void
  }
}

const useAccount = (): UseAccount => {
  const queryClient = useQueryClient()
  const accountId = useAppSelector(selectAccountId)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()

  const { data: account } = useQuery<Account, Error>([Query.account.ACCOUNT_INFO], () => fetchAccountDetails(), {
    initialData: undefined,
    retry: 2,
    keepPreviousData: true,
    enabled: !!accountId,
  })

  const accountSwitch = useMutation(switchAccount, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([Query.user.USER_INFO])
      queryClient.invalidateQueries([Query.account.PRICE_ITEMS_PAGINATION])
      queryClient.invalidateQueries([Query.account.PRICE_MODIFIERS_PAGINATION])
      queryClient.invalidateQueries([Query.account.ACCOUNT_TEAM_MEMBERS])
      queryClient.invalidateQueries([Query.account.ACCOUNT_INFO])
      queryClient.invalidateQueries([Query.account.INVOICES_PAGINATION])
      queryClient.invalidateQueries([Query.account.QUOTES_PAGINATION])
      queryClient.invalidateQueries([Query.account.SERIES_PAGINATION])
      queryClient.invalidateQueries([Query.partnership.PARTNERSHIP_INFO])

      const { accountId, adminRole } = decodeToken(res?.data?.token)
      dispatch(setAccountId(accountId))
      dispatch(setPartnershipId(null))
      dispatch(setAdminRole(adminRole))

      window.location.href = clientBaseUrl
    },
    onError: () => {
      enqueueSnackbar("Could not switch to selected account", {
        variant: "error",
      })
    },
  })

  const accountCreate = useMutation(createNewAccount, {
    onSuccess: (response) => {
      setTimeout(() => {
        accountSwitch.mutate(response?.data?.id)
      }, 200)
    },
    onError: () => {
      enqueueSnackbar("Could not create an account", {
        variant: "error",
      })
    },
  })

  return {
    account: accountId ? account : undefined,
    actions: {
      create: accountCreate.mutate,
      switch: accountSwitch.mutate,
    },
  }
}

export default useAccount
