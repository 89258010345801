import MuiDialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import NiceModal, { useModal } from "@ebay/nice-modal-react"
import Typography from "@mui/material/Typography"
import InlineContainer from "@/components/InlineContainer"
import { useTheme, useMediaQuery } from "@mui/material"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import NeutralButton from "@/components/buttons/NeutralButton"
import { saveCustomer } from "@/features/customer-management/customer-management-queries"
import { CustomerStatus, Query } from "@/types"
import ErrorButton from "@/components/buttons/ErrorButton"
import { useSnackbar } from "notistack"

export default NiceModal.create<{
  customerId?: string
}>(({ customerId }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const modal = useModal()
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const handleCloseModal = () => {
    modal.hide()
  }

  const updateCustomer = useMutation(saveCustomer, {
    onSuccess: () => {
      queryClient.invalidateQueries([Query.account.CUSTOMERS_PAGINATION])
      queryClient.invalidateQueries([Query.account.ALL_TAGS])
      handleCloseModal()
    },
    onError: () => {
      enqueueSnackbar("Failed to update customer", { variant: "error" })
    },
  })

  return (
    <MuiDialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      onClose={handleCloseModal}
      fullScreen={isMobile}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            color="primary"
            variant="h6"
            sx={{
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            Deactivate customer
          </Typography>
        </InlineContainer>
      </DialogTitle>
      <DialogContent dividers>
        <Typography
          variant="body2"
          sx={{
            marginBottom: "1.5rem",
          }}
        >
          Are you sure you want to make this customer inactive?
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontWeight: 600,
          }}
        >
          The following will occur:
        </Typography>
        <Typography variant="body2">
          <ul>
            <li>All recurring invoices will be paused</li>
            <li>All scheduled invoices will be cancelled</li>
            <li>Cannot create invoice for this customer until they are reactivated</li>
            <li>Customer will still be able to pay for invoices that have been sent</li>
          </ul>
        </Typography>
      </DialogContent>
      <DialogActions>
        <InlineContainer justifyContent="space-between" width="100%">
          <NeutralButton
            disableElevation
            onClick={() => {
              handleCloseModal()
            }}
          >
            No, cancel
          </NeutralButton>
          <ErrorButton
            onClick={() => {
              updateCustomer.mutate({
                id: customerId,
                status: CustomerStatus.INACTIVE,
              })
            }}
          >
            Yes, make inactive
          </ErrorButton>
        </InlineContainer>
      </DialogActions>
    </MuiDialog>
  )
})
