import Box from "@mui/material/Box"
import MuiDialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import NiceModal, { useModal } from "@ebay/nice-modal-react"
import Typography from "@mui/material/Typography"
import InlineContainer from "@/components/InlineContainer"
import { matchSorter } from "match-sorter"
import { useQuery } from "@tanstack/react-query"
import { useTheme, useMediaQuery } from "@mui/material"
import { fetchAllPriceModifiers } from "@/features/price-management/price-management-queries"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/CloseRounded"
import FormattedNumber from "@/components/FormattedNumber"
import { useState } from "react"
import { getPercentFromFloat, getPriceModifierValue } from "@/utils/calculations"
import TextField from "@/components/TextField"
import PlusIcon from "@mui/icons-material/AddRounded"
import { setQuote } from "@/features/quote-management/quote-management-reducers"
import useAppDispatch from "@/hooks/useAppDispatch"
import { Query, DialogKeys } from "@/types"
import useAppSelector from "@/hooks/useAppSelector"
import { selectQuote } from "@/features/quote-management/quote-management-selectors"
import AffirmativeButton from "@/components/buttons/AffirmativeButton"
import NeutralButton from "@/components/buttons/NeutralButton"
import { selectInvoice } from "../invoice-management/invoice-management-selectors"
import { setInvoice } from "../invoice-management/invoice-management-reducers"
import { ItemCard, ItemList } from "./styled"
import { transformPriceModifierValues } from "./InvoiceQuotePriceItemOptionsDialog"

export default NiceModal.create(({ mode }: { mode: "invoice" | "quote" }) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const modal = useModal(DialogKeys.INVOICE_QUOTE_PRICE_MODIFIER)
  const [selectedModifier, setSelectedModifier] = useState<any>({})
  const [search, setSearch] = useState("")
  const selectedInvoiceQuote = useAppSelector(mode === "invoice" ? selectInvoice : selectQuote)

  const { data: priceModifiers } = useQuery(
    [Query.account.BUILDER_ALL_PRICE_MODIFIERS],
    () => fetchAllPriceModifiers(),
    { keepPreviousData: true },
  )

  const searchedPriceModifiers: any = matchSorter(priceModifiers?.results ?? [], search, {
    keys: [
      "name",
      "description",
      (tax: any) => getPercentFromFloat(tax?.value_pct).toString(),
      "value_flat",
      // The following allows the user to search for a derived
      // value: "percentage" or "fixed", in this case.
      (mod: any) => (!Boolean(mod?.value_flat) ? "percentage" : "fixed"),
    ],
  })

  const handleCloseModal = () => {
    modal.hide()
  }

  const handleSelectModifier = (modifier) => {
    setSelectedModifier({
      account_pricemodifier_id: modifier?.id,
      id: modifier?.id,
      name: modifier?.name,
      value_flat: modifier?.value_flat,
      value_pct: modifier?.value_pct,
      description: modifier?.description,
    })
  }

  const handleSaveModifiers = (modifier) => {
    const data = {
      account_pricemodifier_id: modifier?.id,
      pricemodifier_name: modifier?.name,
      pricemodifier_value_pct: modifier?.value_pct,
      pricemodifier_value_flat: modifier?.value_flat,
    }

    if (mode === "quote") {
      dispatch(
        // @ts-ignore
        setQuote({
          ...selectedInvoiceQuote,
          ...data,
          has_changed: true,
        }),
      )
    } else {
      if (selectedInvoiceQuote?.sync_mode === "ask") {
        dispatch(
          setInvoice({
            ...selectedInvoiceQuote,
            ...data,
            is_pricemodifier_recurring: selectedInvoiceQuote?.is_recurring,
            has_sync_data_changed: true,
            should_sync_data: true,
            has_changed: true,
          }),
        )
      } else {
        dispatch(
          setInvoice({
            ...selectedInvoiceQuote,
            ...data,
            is_pricemodifier_recurring: selectedInvoiceQuote?.is_recurring,
            has_changed: true,
          }),
        )
      }
    }

    handleCloseModal()
  }

  const handleAddNewPriceModifier = () => {
    handleCloseModal()
    NiceModal.show(DialogKeys.PRICE_MODIFIER, {
      existingModifier: { from_builder: true },
    })
      // @ts-ignore
      .then(({ modifier }) => {
        const {
          pricemodifier_name: name,
          pricemodifier_value_pct: value_pct,
          pricemodifier_value_flat: value_flat,
        } = transformPriceModifierValues(modifier)
        handleSaveModifiers({
          id: null,
          name,
          value_pct,
          value_flat,
        })
      })
  }

  const CreateNewButton = () => (
    <Box textAlign="center" padding="0 0.5rem 1.25rem">
      <AffirmativeButton onClick={handleAddNewPriceModifier} startIcon={<PlusIcon fontSize="small" />}>
        Create New
      </AffirmativeButton>
    </Box>
  )

  const PriceModifierCard = ({ mod }) => {
    const isFlat = !!mod?.value_flat
    return (
      <ItemCard onClick={() => handleSelectModifier(mod)} highlight={selectedModifier?.id === mod?.id}>
        <InlineContainer>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            {mod?.name}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 500, marginRight: "0.5rem" }}>
            :
          </Typography>
          <Typography variant="body1" sx={{ color: "grey.600", fontWeight: 500 }}>
            <FormattedNumber
              prefix={isFlat ? "$" : undefined}
              suffix={!isFlat ? "%" : undefined}
              value={getPriceModifierValue(mod)}
            />
          </Typography>
        </InlineContainer>
      </ItemCard>
    )
  }

  return (
    <MuiDialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            color="primary"
            variant="h6"
            sx={{
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            Discounts & Surcharges
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: (theme) => theme.palette.grey[500],
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      {priceModifiers?.results?.length < 1 ? (
        <DialogContent sx={{ paddingTop: "3rem" }} dividers>
          {isMobile ? (
            <Typography variant="body1" sx={{ fontWeight: 500, textAlign: "center" }}>
              Looks like you currently do not have any discounts or surcharges. To create a new discount or surcharge
              <strong> tap the "+" button below</strong>.
            </Typography>
          ) : (
            <>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                  textAlign: "center",
                  marginBottom: "2.5rem",
                }}
              >
                You currently do not have any discounts or surcharges. To create a new discount or surcharge, click the
                “Create New” button below.
              </Typography>
              <CreateNewButton />
            </>
          )}
        </DialogContent>
      ) : (
        <DialogContent
          sx={{
            backgroundColor: "grey.200",
            padding: "1.25rem",
          }}
          dividers
        >
          <CreateNewButton />
          <Box sx={{ textAlign: "center", width: "100%" }}>
            <TextField
              name="price-item-search"
              placeholder="Search"
              sx={{
                backgroundColor: "white",
                borderRadius: "0.5rem",
                margin: "0 0 1rem",
                display: "flex",
              }}
              onChange={(event) => setSearch(event?.target?.value)}
            />
          </Box>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 500,
              margin: "1.25rem auto 0",
            }}
          >
            All Discounts & Surcharges
          </Typography>
          <ItemList>
            {searchedPriceModifiers?.map((mod) => (
              <PriceModifierCard key={`quote-price-modifier-${mod?.id}`} mod={mod} />
            ))}
          </ItemList>
        </DialogContent>
      )}
      {priceModifiers?.results?.length < 1 ? null : (
        <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
          <InlineContainer justifyContent="space-between" width="100%">
            {isMobile ? null : (
              <NeutralButton variant="outlined" onClick={handleCloseModal}>
                Cancel
              </NeutralButton>
            )}
            <AffirmativeButton
              variant="contained"
              onClick={() => handleSaveModifiers(selectedModifier)}
              disabled={!Boolean(selectedModifier?.account_pricemodifier_id)}
              fullWidth={isMobile}
            >
              Add to {mode === "quote" ? "Quote" : "Invoice"}
            </AffirmativeButton>
          </InlineContainer>
        </DialogActions>
      )}
    </MuiDialog>
  )
})
