import { styled } from "@mui/material"
import MuiBox from "@mui/material/Box"

const Box = styled(MuiBox)(
  ({ theme }) => `
	color: ${theme.palette.primary.contrastText};
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: 10px;
	height: 10px;

	&.disabled,
	&.inactive,
	&.draft {
		background: ${theme.palette.grey[500]};
	}

	&.error,
	&.cancelled,
	&.canceled,
	&.rejected {
		background: ${theme.palette.error.main};
	}
	
	&.warning,
	&.pending,
	&.progress {
		background: ${theme.palette.warning.main};
	}
	
	&.success,
	&.active,
	&.approved,
	&.invoiced,
	&.complete {
		background: ${theme.palette.success.light};
	}
`,
)

const StatusIndicator = ({ status, ...props }) => {
  return (
    <Box className={status.toLowerCase()} {...props}>
      {props?.children ? props?.children : null}
    </Box>
  )
}

export default StatusIndicator
