import TextField, { CustomTextFieldProps } from "@/components/TextField"
import merge from "lodash/merge"

const Select = ({ children, ...props }: CustomTextFieldProps) => {
  const SelectProps = merge(props?.SelectProps ?? {}, {
    MenuProps: { disableScrollLock: true },
  })

  return (
    <TextField select size="small" color="primary" variant="outlined" fullWidth SelectProps={SelectProps} {...props}>
      {children}
    </TextField>
  )
}

export default Select
