import { PatternFormat, PatternFormatProps } from "react-number-format"
import TextField, { CustomTextFieldProps } from "@/components/TextField"

const PhoneField = (
  props: Omit<PatternFormatProps<CustomTextFieldProps>, "format"> & {
    format?: string
  },
) => {
  const format = "+# (###) ###-####"

  return (
    <PatternFormat
      displayType="input"
      mask="_"
      type="tel"
      format={format}
      {...props}
      onValueChange={(values, info) => {
        if (info.source === "event") {
          if (typeof props.onChange === "function") {
            props.onChange({
              target: {
                value: values.value,
              },
            } as any)
          }
        }
      }}
      customInput={TextField}
    />
  )
}

export default PhoneField
