import { RadioLabel } from "@/features/onboarding/components"
import AddressAutocomplete from "@/components/AddressAutocomplete"
import TextField from "@/components/TextField"
import getFieldErrors from "@/utils/getFieldErrors"
import Box from "@mui/material/Box"
import InlineContainer from "@/components/InlineContainer"
import Select from "@/components/Select"
import { stateNamesAndAbbrs } from "@/types"
import Typography from "@mui/material/Typography"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import RadioGroup from "@mui/material/RadioGroup"
import Radio from "@mui/material/Radio"
import { useEffect } from "react"

const BusinessAddress = ({ formik, user, account }) => {
  const setFieldValues = (sameAsPersonal) => {
    formik.setValues({
      ...formik.values,
      legal_address1: sameAsPersonal === "yes" ? user?.compliance_answer?.address_1 : account?.legal_address1,
      legal_address2: sameAsPersonal === "yes" ? user?.compliance_answer?.address_2 : account?.legal_address2,
      legal_city: sameAsPersonal === "yes" ? user?.compliance_answer?.city : account?.legal_city,
      legal_state: sameAsPersonal === "yes" ? user?.compliance_answer?.state : account?.legal_state,
      legal_zip_code: sameAsPersonal === "yes" ? user?.compliance_answer?.zip_code : account?.legal_zip_code,
      public_address1: sameAsPersonal === "yes" ? user?.compliance_answer?.address_1 : account?.legal_address1,
      public_address2: sameAsPersonal === "yes" ? user?.compliance_answer?.address_2 : account?.legal_address2,
      public_city: sameAsPersonal === "yes" ? user?.compliance_answer?.city : account?.legal_city,
      public_state: sameAsPersonal === "yes" ? user?.compliance_answer?.state : account?.legal_state,
      public_zip_code: sameAsPersonal === "yes" ? user?.compliance_answer?.zip_code : account?.legal_zip_code,
      same_as_personal_address: sameAsPersonal,
    })
  }

  useEffect(() => {
    setFieldValues(formik.values.same_as_personal_address)
  }, [])

  return (
    <>
      <Typography variant="h4">What is your business address?</Typography>
      <Box marginTop="1.5rem">
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup>
            <RadioLabel>
              <FormControlLabel
                {...formik.getFieldProps("same_as_personal_address")}
                onChange={(event: any) => setFieldValues(event?.target?.value)}
                control={<Radio size="small" checked={formik.values.same_as_personal_address === "yes"} />}
                value="yes"
                label="Same as personal address"
              />
              {formik.values.same_as_personal_address === "yes" && (
                <Box marginLeft="2rem" marginBottom="0.5rem">
                  <Typography variant="body1">
                    {user?.compliance_answer?.address_1} {user?.compliance_answer?.address_2}
                  </Typography>
                  <Typography variant="body1">
                    {user?.compliance_answer?.city}, {user?.compliance_answer?.state}{" "}
                    {user?.compliance_answer?.zip_code}
                  </Typography>
                </Box>
              )}
            </RadioLabel>
            <RadioLabel>
              <FormControlLabel
                {...formik.getFieldProps("same_as_personal_address")}
                onChange={(event: any) => setFieldValues(event?.target?.value)}
                control={<Radio size="small" checked={formik.values.same_as_personal_address === "no"} />}
                value="no"
                label="Other"
              />
              {formik.values.same_as_personal_address === "no" && (
                <Box
                  marginLeft="2rem"
                  sx={{
                    marginBottom: "0.75rem",
                    marginLeft: "1.75rem",
                    paddingRight: "3.5rem",
                    width: "100%",
                  }}
                >
                  <Box marginBottom="0.5rem">
                    <AddressAutocomplete
                      placeholder="Business Address"
                      onPlaceSelected={(place) => {
                        formik.setFieldValue("legal_address1", place.address1)
                        formik.setFieldValue("legal_address2", place.address2)
                        formik.setFieldValue("legal_city", place.city)
                        formik.setFieldValue("legal_state", place.state)
                        formik.setFieldValue("legal_zip_code", place.zip)
                        formik.setFieldValue("public_address1", place.address1)
                        formik.setFieldValue("public_address2", place.address2)
                        formik.setFieldValue("public_city", place.city)
                        formik.setFieldValue("public_state", place.state)
                        formik.setFieldValue("public_zip_code", place.zip)
                      }}
                      {...formik.getFieldProps("legal_address1")}
                      onChange={(e) => {
                        formik.setFieldValue("legal_address1", e.target.value)
                        formik.setFieldValue("public_address1", e.target.value)
                      }}
                    />
                    {getFieldErrors("legal_address1", formik)?.error && (
                      <Typography color="error" variant="body2" fontSize="0.85rem" margin="0.25rem 0 0 0.75rem">
                        {getFieldErrors("legal_address1", formik)?.helperText}
                      </Typography>
                    )}
                  </Box>
                  <Box marginBottom="0.5rem">
                    <TextField
                      size="small"
                      fullWidth
                      variant="outlined"
                      placeholder="Suite, Unit, or Apt"
                      {...formik.getFieldProps("legal_address2")}
                      {...getFieldErrors("legal_address2", formik)}
                      onChange={(e) => {
                        formik.setFieldValue("legal_address2", e.target.value)
                        formik.setFieldValue("public_address2", e.target.value)
                      }}
                    />
                  </Box>
                  <Box marginBottom="0.5rem">
                    <TextField
                      size="small"
                      fullWidth
                      variant="outlined"
                      placeholder="City"
                      {...formik.getFieldProps("legal_city")}
                      {...getFieldErrors("legal_city", formik)}
                      onChange={(e) => {
                        formik.setFieldValue("legal_city", e.target.value)
                        formik.setFieldValue("public_city", e.target.value)
                      }}
                    />
                  </Box>
                  <InlineContainer gap="1rem" marginBottom="0.25rem" alignItems="start">
                    <Box sx={{ flexBasis: "100%" }}>
                      <Select
                        {...formik.getFieldProps("legal_state")}
                        {...getFieldErrors("legal_state", formik)}
                        onChange={(e) => {
                          formik.setFieldValue("legal_state", e.target.value)
                          formik.setFieldValue("public_state", e.target.value)
                        }}
                        placeholder="State"
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                maxHeight: 400,
                              },
                            },
                          },
                        }}
                      >
                        <MenuItem disabled value="x">
                          Select a State
                        </MenuItem>
                        {stateNamesAndAbbrs.map((state) => (
                          <MenuItem key={state.ABBR} value={state.ABBR}>
                            {state.NAME}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                    <Box sx={{ flexBasis: "100%" }}>
                      <TextField
                        size="small"
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          maxLength: 10,
                        }}
                        placeholder="Zip Code"
                        {...formik.getFieldProps("legal_zip_code")}
                        {...getFieldErrors("legal_zip_code", formik)}
                        onChange={(e) => {
                          formik.setFieldValue("legal_zip_code", e.target.value)
                          formik.setFieldValue("public_zip_code", e.target.value)
                        }}
                      />
                    </Box>
                  </InlineContainer>
                </Box>
              )}
            </RadioLabel>
          </RadioGroup>
        </FormControl>
      </Box>
    </>
  )
}

export default BusinessAddress
