import { useFormik } from "formik"
import { User, Query } from "@/types"
import getModifiedValues from "@/utils/getModifiedValues"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { saveUserSettings } from "@/features/account-management/account-management-queries"
import { useSnackbar } from "notistack"
import useUser from "@/features/auth/useUser"
import humanName from "humanparser"
import { adaptUser } from "@/features/user-management/user-management-adapters"
import {
  cellPhoneValidationSchema,
  emailValidationSchema,
  nameValidationSchema,
  passwordChangeValidationSchema,
  fullNameValidationSchema,
  dateOfBirthValidationSchema,
  personalAddressValidationSchema,
} from "@/features/user-management/user-management-validation"
import { passwordValidationSchema, newPasswordValidationSchema } from "@/features/auth/auth-validation"
import { useEffect } from "react"

const schemas = {
  password: passwordChangeValidationSchema,
  name: nameValidationSchema,
  email: emailValidationSchema,
  cell_phone: cellPhoneValidationSchema,
  has_seen_updates: true,
  passwordOnly: passwordValidationSchema,
  newPasswordOnly: newPasswordValidationSchema,
  fullName: fullNameValidationSchema,
  dateOfBirth: dateOfBirthValidationSchema,
  personalAddress: personalAddressValidationSchema,
}

const getInitialValues = (user: User = {} as User) => ({
  avatar_url: user?.avatar_url ?? "",
  compliance_answer: {
    first_name: humanName.parseName(user?.name ?? "")?.firstName ?? "",
    last_name: humanName.parseName(user?.name ?? "")?.lastName ?? "",
    date_of_birth: user?.compliance_answer?.date_of_birth || null,
    ...user?.compliance_answer,
  },
  email: user?.email ?? "",
  cell_phone: user?.cell_phone ?? "",
  home_phone: user?.home_phone ?? "",
  work_phone: user?.work_phone ?? "",
  timezone: user?.timezone ?? "",
  name: user?.name ?? "",
  password: "",
  newPassword: "",
  confirmPassword: "",
  has_seen_updates: user?.has_seen_updates,
  is_not_shady: false,
})

const useUserUpdater = ({
  validate = "",
  quiet = false,
  onSuccess = undefined,
  context = "settings",
}: {
  validate?: string
  quiet?: boolean
  onSuccess?: (response?: User) => void
  context?: "settings" | "compliance"
} = {}) => {
  const queryClient = useQueryClient()
  const { user } = useUser()
  const { enqueueSnackbar } = useSnackbar()

  const saveDetails = useMutation(
    (values: Partial<User> & Partial<typeof getInitialValues>) => saveUserSettings(values),
    {
      onSuccess: (response: any) => {
        queryClient.invalidateQueries([Query.user.USER_INFO])
        formik.resetForm()
        formik.setValues(
          getModifiedValues({ ...response?.data }, getInitialValues({ ...response?.data, is_not_shady: true })) as any,
        )
        if (!quiet) {
          enqueueSnackbar(validate === "password" ? "Password successfully updated!" : "User updated successfully!", {
            variant: "success",
          })
        }

        if (typeof onSuccess === "function") {
          onSuccess(response)
        }
      },
      onError: (error) => {
        if (!quiet) {
          enqueueSnackbar("User cannot be updated.", {
            variant: "error",
          })
        }
      },
    },
  )

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(user),
    ...(Boolean(validate) ? { validationSchema: schemas?.[validate] } : {}),
    onSubmit: (values) => {
      const updatedValues = getModifiedValues<User>(values, getInitialValues(user), ["is_not_shady"])

      if (Object.keys(updatedValues).length) {
        saveDetails.mutate(
          context === "settings"
            ? {
                ...adaptUser(updatedValues),
                compliance_answer: undefined,
              }
            : {
                compliance_answer: values.compliance_answer,
              },
        )
      } else {
        if (typeof onSuccess === "function") {
          onSuccess()
        }
      }
    },
  })

  useEffect(() => {
    formik.setValues(getInitialValues(user))
  }, [user?.updated_at])

  return {
    user,
    initialValues: getInitialValues(user),
    fields: formik,
    isLoading: saveDetails.isLoading,
    disableSubmit:
      !Object.keys(getModifiedValues<User>(formik.values, getInitialValues(user))).length && formik.isValid,
    saveUserDetails: saveDetails,
  }
}

export default useUserUpdater
