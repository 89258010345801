import * as yup from "yup"

export const priceItemValidationSchema = yup.object().shape({
  name: yup.string().max(50, "Name cannot be longer than 50 characters").required("Name is required"),
  price: yup.string().min(0, "Price cannot be negative").required("Amount is required"),
})

export const priceModifierValidationSchema = yup.object().shape({
  name: yup.string().max(50, "Name cannot be longer than 50 characters").required("Name is required"),
  value: yup.string().min(0, "Value cannot be negative").required("Amount is required"),
  type: yup.string().required("Type is required"),
})

export const taxValidationSchema = yup.object().shape({
  name: yup.string().max(50, "Name cannot be longer than 50 characters").required("Name is required"),
  value_pct: yup.string().min(0, "Value cannot be negative").required("Tax rate is required"),
})
