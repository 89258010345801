import { RadioLabel } from "@/features/onboarding/components"
import Typography from "@mui/material/Typography"
import TextField from "@/components/TextField"
import MenuItem from "@mui/material/MenuItem"
import Box from "@mui/material/Box"
import { BusinessStructureEnum, businessStructuresList, stateNamesAndAbbrs } from "@/types"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormHelperText from "@mui/material/FormHelperText"
import RadioGroup from "@mui/material/RadioGroup"
import Radio from "@mui/material/Radio"
import { PatternFormat } from "react-number-format"
import InputAdornment from "@mui/material/InputAdornment"
import Select from "@/components/Select"
import getFieldErrors from "@/utils/getFieldErrors"

const BusinessType = ({ formik }) => {
  const isIndividualBusiness = (business: BusinessStructureEnum) =>
    [BusinessStructureEnum.FREELANCE, BusinessStructureEnum.SOLE_PROPRIETOR].includes(business)

  const isFullSsn = (ssn: string) => ssn.length === 11

  return (
    <>
      <Typography variant="h4">What type of business is this?</Typography>
      <Typography variant="body1" sx={{ marginTop: "0.25rem" }}>
        Specify which entity type you used to register your business.
      </Typography>
      <Box marginTop="1rem">
        <FormControl
          sx={{ width: "100%" }}
          error={
            getFieldErrors("business_structure", formik, {
              ignoreTouched: true,
            })?.error
          }
        >
          <FormHelperText
            sx={{
              marginTop: "-0.25rem",
              marginLeft: "0",
            }}
          >
            {
              getFieldErrors("business_structure", formik, {
                ignoreTouched: true,
              })?.helperText
            }
          </FormHelperText>
          <RadioGroup
            onChange={(e) => {
              if (isIndividualBusiness(e.target.value as BusinessStructureEnum)) {
                formik.setFieldValue("ein", "")
                formik.setFieldTouched("ein", false)
                formik.setFieldValue("state_incorporation", "x")
                formik.setFieldTouched("state_incorporation", false)
              } else {
                formik.setFieldValue("ssn", "")
                formik.setFieldValue("last_four_ssn", "")
                formik.setFieldTouched("ssn", false)
                formik.setFieldTouched("last_four_ssn", false)
              }
              formik.setFieldValue("business_structure", e.target.value)
            }}
          >
            {businessStructuresList.map((structure) => (
              <RadioLabel
                key={structure.title}
                sx={{
                  borderColor: getFieldErrors("business_structure", formik, {
                    ignoreTouched: true,
                  })?.error
                    ? "error.main"
                    : "",
                }}
              >
                <FormControlLabel
                  control={<Radio size="small" checked={structure.value === formik.values.business_structure} />}
                  value={structure.value}
                  label={structure.title}
                />
                {formik.values.business_structure === structure.value && isIndividualBusiness(structure.value) && (
                  <>
                    <PatternFormat
                      displayType="input"
                      format="###-##-####"
                      mask="_"
                      allowEmptyFormatting
                      customInput={TextField}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">SSN: </InputAdornment>,
                      }}
                      type="tel"
                      size="small"
                      sx={{
                        marginBottom: "0.75rem",
                        marginLeft: "1.75rem",
                        paddingRight: "3.5rem",
                        width: "100%",
                      }}
                      placeholder="SSN"
                      {...formik.getFieldProps("ssn")}
                      {...getFieldErrors("ssn", formik)}
                      onChange={(e) => {
                        formik.setFieldValue("ssn", e.target.value)

                        formik.setFieldValue("last_four_ssn", isFullSsn(e.target.value) ? e.target.value.slice(-4) : "")
                        formik.setFieldValue("ein", "")
                      }}
                      onBlur={(e) => {
                        formik.setFieldValue("last_four_ssn", isFullSsn(e.target.value) ? e.target.value.slice(-4) : "")
                        formik.setFieldValue("ein", "")
                        formik.setFieldTouched("ssn", true)
                        formik.setFieldTouched("last_four_ssn", false)
                      }}
                    />
                  </>
                )}
                {formik.values.business_structure === structure.value && !isIndividualBusiness(structure.value) && (
                  <>
                    <PatternFormat
                      displayType="input"
                      format="##-#######"
                      mask="_"
                      allowEmptyFormatting
                      customInput={TextField}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">EIN: </InputAdornment>,
                      }}
                      type="tel"
                      size="small"
                      sx={{
                        marginBottom: "0.75rem",
                        marginLeft: "1.75rem",
                        paddingRight: "3.5rem",
                        width: "100%",
                      }}
                      placeholder="EIN"
                      {...formik.getFieldProps("ein")}
                      {...getFieldErrors("ein", formik)}
                      onChange={(e) => {
                        formik.setFieldValue("ein", e.target.value)
                      }}
                      onBlur={() => {
                        formik.setFieldValue("ssn", "")
                        formik.setFieldValue("last_four_ssn", "")
                        formik.setFieldTouched("ein", true)
                        formik.setFieldTouched("last_four_ssn", false)
                        formik.setFieldTouched("state_incorporation", false)
                      }}
                    />
                    <Typography
                      sx={{
                        marginLeft: "1.75rem",
                        marginBottom: "0.5rem",
                      }}
                    >
                      State of Incorporation
                    </Typography>
                    <Select
                      {...formik.getFieldProps("state_incorporation")}
                      {...getFieldErrors("state_incorporation", formik)}
                      onChange={(e) => {
                        formik.setFieldValue("state_incorporation", e.target.value)
                        formik.setFieldValue("ssn", "")
                        formik.setFieldValue("last_four_ssn", "")
                      }}
                      placeholder="State"
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: 400,
                            },
                          },
                        },
                      }}
                      sx={{
                        marginBottom: "0.75rem",
                        marginLeft: "1.75rem",
                        paddingRight: "3.5rem",
                        width: "100%",
                      }}
                    >
                      <MenuItem disabled value="x">
                        Select a State
                      </MenuItem>
                      {stateNamesAndAbbrs.map((state) => (
                        <MenuItem key={state.ABBR} value={state.ABBR}>
                          {state.NAME}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </RadioLabel>
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </>
  )
}

export default BusinessType
