import MuiTextField, { TextFieldProps } from "@mui/material/TextField"
import { styled } from "@mui/material"

export type CustomTextFieldProps = TextFieldProps & {
  readOnly?: boolean
  format?: string | null
}

const CustomTextField = styled(MuiTextField, {
  shouldForwardProp: (prop) => prop !== "readOnly",
})<CustomTextFieldProps>(
  ({ theme, readOnly }) => `
  transition: border 200ms, box-shadow 200ms;
  
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    transition: border 200ms, box-shadow 200ms;
  }
  
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline:hover {
    border-color: ${theme.palette.secondary.main};
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
    transition: border 200ms, box-shadow 200ms;
  }

	& .MuiOutlinedInput-input.Mui-disabled,
  & ::placeholder {
		color: ${readOnly ? theme.palette.common.black : "rgba(0, 0, 0, 0.38)"};
		-webkit-text-fill-color: ${readOnly ? theme.palette.common.black : "rgba(0, 0, 0, 0.38)"};
		z-index: ${readOnly ? 1 : 0};
  }
	
	& .MuiOutlinedInput-input.Mui-disabled.MuiSelect-outlined,
	& ::placeholder,
	& .MuiOutlinedInput-input.Mui-disabled + fieldset {
		background-color: ${readOnly ? theme.palette.grey[100] : "none"};
		border-color: ${readOnly ? theme.palette.common.white : "rgba(255, 255, 255, 0.25)"};
	}
`,
)

const TextField = ({ readOnly = false, ...props }: CustomTextFieldProps) => {
  return (
    <CustomTextField
      variant="outlined"
      size="small"
      readOnly={readOnly}
      disabled={readOnly || props?.disabled}
      {...props}
    />
  )
}

export default TextField
