import SvgIcon from "@mui/material/SvgIcon"

const InfoOutlineIcon = (props) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width={16} height={17} fill="none" viewBox="0 0 16 17" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 8.5C1 4.636 4.129 1.5 7.993 1.5C11.864 1.5 15 4.636 15 8.5C15 12.364 11.864 15.5 7.993 15.5C4.129 15.5 1 12.364 1 8.5ZM2.4 8.5C2.4 11.594 4.906 14.1 8 14.1C11.094 14.1 13.6 11.594 13.6 8.5C13.6 5.406 11.094 2.9 8 2.9C4.906 2.9 2.4 5.406 2.4 8.5ZM7.26317 5.18423H8.66317V6.58423H7.26317V5.18423ZM7.96317 12.1842C7.57817 12.1842 7.26317 11.8692 7.26317 11.4842V8.68423C7.26317 8.29923 7.57817 7.98423 7.96317 7.98423C8.34817 7.98423 8.66317 8.29923 8.66317 8.68423V11.4842C8.66317 11.8692 8.34817 12.1842 7.96317 12.1842Z"
        fill="#333638"
      />
    </SvgIcon>
  )
}

export default InfoOutlineIcon
