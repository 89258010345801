import VisaIcon from "@/components/icons/CreditCards/VisaIcon"
import VenmoIcon from "@/components/icons/VenmoIcon"
import ZelleIcon from "@/components/icons/ZelleIcon"
import MastercardIcon from "@/components/icons/CreditCards/MastercardIcon"
import AmexIcon from "@/components/icons/CreditCards/AmexIcon"
import DiscoverIcon from "@/components/icons/CreditCards/DiscoverIcon"
import DinersClubIcon from "@/components/icons/CreditCards/DinersClubIcon"
import JCBIcon from "@/components/icons/CreditCards/JCBIcon"
import UnionPayIcon from "@/components/icons/CreditCards/UnionPayIcon"
import CheckingIcon from "@mui/icons-material/AccountBalanceRounded"
import Box from "@mui/material/Box"
import { PaymentBrand } from "@/types"
import UnknownIcon from "@/components/icons/CreditCards/UnknownIcon"
import OfflinePaymentIcon from "@/components/icons/OfflinePaymentIcon"
import ApplePayIcon from "@/components/icons/ApplePayIcon"

export const GenericCardIcon = ({ type = "bank", ...props }: { type?: "bank" | "offline"; [key: string]: any }) => (
  <Box
    sx={{
      border: "1px solid",
      borderColor: "grey.400",
      borderRadius: "3px",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      width: 28,
      height: 20,
      marginRight: "0.5rem",
      ...props?.sx,
    }}
  >
    {type === "bank" && <CheckingIcon fontSize="small" sx={{ fill: "black", width: 14, height: 14 }} />}
    {type === "offline" && <OfflinePaymentIcon fontSize="small" sx={{ fill: "black", width: 14, height: 14 }} />}
  </Box>
)

export const BankAccountIcon = (props) => <GenericCardIcon type="bank" {...props} />

export const OfflineCardIcon = (props) => <GenericCardIcon type="offline" {...props} />

export const PaymentBrandIcons = {
  [PaymentBrand.VISA]: <VisaIcon sx={{ marginRight: "0.5rem" }} />,
  [PaymentBrand.MASTERCARD]: <MastercardIcon sx={{ marginRight: "0.5rem" }} />,
  [PaymentBrand.DISCOVER]: <DiscoverIcon sx={{ marginRight: "0.5rem" }} />,
  [PaymentBrand.AMEX]: <AmexIcon sx={{ marginRight: "0.5rem" }} />,
  [PaymentBrand.DINERS_CLUB]: <DinersClubIcon sx={{ marginRight: "0.5rem" }} />,
  [PaymentBrand.JCB]: <JCBIcon sx={{ marginRight: "0.5rem" }} />,
  [PaymentBrand.UNION_PAY]: <UnionPayIcon sx={{ marginRight: "0.5rem" }} />,
  [PaymentBrand.UNKNOWN]: <UnknownIcon sx={{ marginRight: "0.5rem" }} />,
  [PaymentBrand.VENMO]: <VenmoIcon sx={{ fontSize: "1.25rem", marginRight: "0.25rem" }} />,
  [PaymentBrand.PAYPAL]: <UnknownIcon sx={{ marginRight: "0.5rem" }} />,
  [PaymentBrand.ZELLE]: <ZelleIcon sx={{ marginRight: "0.25rem" }} />,
  [PaymentBrand.CASH]: <UnknownIcon sx={{ marginRight: "0.5rem" }} />,
  [PaymentBrand.CHECK]: <UnknownIcon sx={{ marginRight: "0.5rem" }} />,
  [PaymentBrand.OTHER]: <UnknownIcon sx={{ marginRight: "0.5rem" }} />,
  [PaymentBrand.BANK_ACCOUNT]: <BankAccountIcon sx={{ marginRight: "0.5rem" }} />,
  [PaymentBrand.APPLE_PAY]: <ApplePayIcon sx={{ marginRight: "0.5rem" }} />,
  [PaymentBrand.OFFLINE]: <GenericCardIcon sx={{ marginRight: "0.5rem" }} type="offline" />,
}

export const getPaymentBrandIcon = (paymentString) => {
  if (!paymentString) {
    return null
  }

  const cardBrandRegex = /^[^ ]+/
  const brand = paymentString.match(cardBrandRegex)
  const brandName = brand?.[0]?.toUpperCase()
  switch (brandName) {
    case PaymentBrand.VISA:
      return <VisaIcon sx={{ marginRight: "0.5rem" }} />
    case PaymentBrand.AMEX:
      return <AmexIcon sx={{ marginRight: "0.5rem" }} />
    case PaymentBrand.MASTERCARD:
      return <MastercardIcon sx={{ marginRight: "0.5rem" }} />
    case PaymentBrand.DISCOVER:
      return <DiscoverIcon sx={{ marginRight: "0.5rem" }} />
    case PaymentBrand.DINERS_CLUB:
      return <DinersClubIcon sx={{ marginRight: "0.5rem" }} />
    case PaymentBrand.JCB:
      return <JCBIcon sx={{ marginRight: "0.5rem" }} />
    case PaymentBrand.UNION_PAY:
      return <UnionPayIcon sx={{ marginRight: "0.5rem" }} />
    case PaymentBrand.VENMO:
      return <VenmoIcon sx={{ marginRight: "0.5rem" }} />
    case PaymentBrand.ZELLE:
      return <ZelleIcon sx={{ marginRight: "0.5rem" }} />
    case PaymentBrand.CHECK:
    case PaymentBrand.ACH:
    case PaymentBrand.BANK_ACCOUNT:
      return <BankAccountIcon sx={{ marginRight: "0.5rem" }} />
    case PaymentBrand.OFFLINE:
      return <OfflineCardIcon sx={{ marginRight: "0.5rem" }} />
    default:
      return <UnknownIcon sx={{ margiRight: "0.5rem" }} />
  }
}
