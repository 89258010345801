import Box from "@mui/material/Box"
import { BankAccountType } from "@/types"
import TextField from "@/components/TextField"
import Select from "@/components/Select"
import Typography from "@mui/material/Typography"
import FormLabel from "@mui/material/FormLabel"
import MenuItem from "@mui/material/MenuItem"
import { FormikProps } from "formik"
import { WalletInstrumentValues } from "@/features/wallet/components/payment-method/AddWalletInstrument"
import InlineContainer from "@/components/InlineContainer"
import getFieldErrors from "@/utils/getFieldErrors"
import isEmptyOrNumber from "@/utils/isEmptyOrNumber"

const AddBankAccount = ({
  formik,
  // for admin usage
  supportAddRawBankInfo = false,
  partnershipInformation = undefined,
}: {
  formik: FormikProps<WalletInstrumentValues>
  supportAddRawBankInfo?: boolean
  partnershipInformation?: {
    routingNumber: string
    name: string
  }
}) => {
  const accountNumberBox = (
    <Box width="100%" marginTop="1.25rem">
      <FormLabel
        htmlFor="account_number"
        sx={{
          display: "inline-block",
          marginBottom: "0.25rem",
        }}
      >
        <Typography variant="body1" sx={{ color: "black", fontWeight: 600 }}>
          Account Number
        </Typography>
      </FormLabel>
      <TextField
        id="account_number"
        placeholder="xxxxxxxxxxx"
        {...formik.getFieldProps("account_number")}
        {...getFieldErrors("account_number", formik)}
        onChange={(event) => {
          formik.setFieldValue(
            "account_number",
            isEmptyOrNumber(event) ? event.target.value : formik.values.account_number,
          )
        }}
        inputProps={{ maxLength: 17, minLength: 12 }}
        fullWidth
      />
    </Box>
  )

  const accountHolderNameBox = (
    <Box width="100%" marginTop="1.25rem">
      <FormLabel
        htmlFor="bank_full_name"
        sx={{
          display: "inline-block",
          marginBottom: "0.25rem",
        }}
      >
        <Typography variant="body1" sx={{ color: "black", fontWeight: 600 }}>
          Account Holder Name
        </Typography>
      </FormLabel>
      <InlineContainer gap="1rem">
        <TextField
          id="bank_full_name"
          {...formik.getFieldProps("bank_full_name")}
          {...getFieldErrors("bank_full_name", formik)}
          placeholder="Full Name"
          fullWidth
        />
      </InlineContainer>
    </Box>
  )

  if (partnershipInformation?.name && partnershipInformation?.routingNumber) {
    return (
      <Box width="100%" marginTop="1.25rem">
        <FormLabel
          htmlFor="routing_number"
          sx={{
            display: "inline-block",
            marginBottom: "0.25rem",
          }}
        >
          <Typography variant="body1" sx={{ color: "black", fontWeight: 600 }}>
            Bank
          </Typography>
        </FormLabel>
        <Typography variant="body2">{partnershipInformation.name}</Typography>
        <FormLabel
          htmlFor="routing_number"
          sx={{
            display: "inline-block",
            marginTop: "1rem",
            marginBottom: "0.25rem",
          }}
        >
          <Typography variant="body1" sx={{ color: "black", fontWeight: 600 }}>
            Routing Number
          </Typography>
        </FormLabel>
        <Typography variant="body2">{partnershipInformation.routingNumber}</Typography>
        {accountHolderNameBox}
        {accountNumberBox}
      </Box>
    )
  }

  return (
    <>
      <Box width="100%">
        <FormLabel
          htmlFor="account_type"
          sx={{
            display: "inline-block",
            marginBottom: "0.25rem",
          }}
        >
          <Typography variant="body1" sx={{ color: "black", fontWeight: 600 }}>
            Business or Personal
          </Typography>
        </FormLabel>
        <InlineContainer justifyContent="start" alignItems="start" gap="1rem">
          <Select id="account_type" {...formik.getFieldProps("account_type")}>
            <MenuItem value={BankAccountType.PERSONAL}>Personal Account</MenuItem>
            <MenuItem value={BankAccountType.BUSINESS}>Business Account</MenuItem>
          </Select>
        </InlineContainer>
      </Box>
      {supportAddRawBankInfo && (
        <>
          <Box width="100%" marginTop="1.25rem">
            <FormLabel
              htmlFor="routing_number"
              sx={{
                display: "inline-block",
                marginBottom: "0.25rem",
              }}
            >
              <Typography variant="body1" sx={{ color: "black", fontWeight: 600 }}>
                Routing Number
              </Typography>
            </FormLabel>
            <TextField
              id="routing_number"
              {...formik.getFieldProps("routing_number")}
              onChange={(event) => {
                formik.setFieldValue(
                  "routing_number",
                  isEmptyOrNumber(event) ? event.target.value : formik.values.routing_number,
                )
              }}
              inputProps={{ maxLength: 9 }}
              {...getFieldErrors("routing_number", formik)}
              fullWidth
            />
          </Box>
          {accountNumberBox}
          {accountHolderNameBox}
        </>
      )}
    </>
  )
}

export default AddBankAccount
