export const removeAtIndex = <T>(index: number, array: T[]): T[] => {
  const newArray = array.slice()
  newArray.splice(index, 1)
  return newArray
}

export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from<T>(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return [...result]
}

export const hasIntersection = (array1: any[], array2: any[]): boolean => {
  return array1.some((item) => array2.includes(item))
}
