import { initApi, routes } from "@/config/api"
import { PriceItemValues, PriceModifierType } from "@/types"
import { downloadFromBlob } from "@/utils/download"
import { getIntFromDollars, parsePercent } from "@/utils/calculations"
import unformatNumber from "@/utils/unformatNumber"
import { GetServerSidePropsContext } from "next"

export const fetchPriceItems = async (filters = {}, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.price.items(filters))
    .then(({ data }) => data?.data)

export const fetchAllPriceItems = async (req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.price.allItems)
    .then(({ data }) => data?.data)

export const createPriceItem = async (values: PriceItemValues) =>
  await initApi()
    .post(routes.price.item, {
      name: values?.name,
      price: getIntFromDollars(unformatNumber(values?.price ?? 0)),
      description: values?.description,
      is_adhoc: values?.is_adhoc ?? false,
      is_track_inventory: values?.is_track_inventory ?? false,
      inventory_on_hand: unformatNumber(values?.inventory_on_hand ?? 0),
      inventory_reorder_point: unformatNumber(values?.inventory_reorder_point ?? 0),
      is_taxable: values?.is_taxable,
      category: values?.category,
    })
    .then(({ data }) => data?.data)

export const editPriceItem = async ({ id, ...values }) =>
  await initApi()
    .patch(`${routes.price.item}/${id}`, {
      name: values?.name,
      price: getIntFromDollars(unformatNumber(values?.price)),
      description: values?.description,
      is_adhoc: values?.is_adhoc ?? false,
      is_track_inventory: values?.is_track_inventory,
      inventory_on_hand: unformatNumber(values?.inventory_on_hand),
      inventory_reorder_point: unformatNumber(values?.inventory_reorder_point),
      is_taxable: values?.is_taxable,
      category: values?.category,
    })
    .then(({ data }) => data?.data)

export const deletePriceItem = async (userId?: string) => {
  if (!userId) {
    return null
  }

  return await initApi().delete(routes.price.delete.item(userId))
}

export const fetchTaxes = async (filters = {}, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.price.taxes(filters))
    .then(({ data }) => data?.data)

export const createTax = async (values) =>
  await initApi()
    .post(routes.price.tax, {
      name: values?.name,
      description: values?.description,
      value_pct: values?.value_pct,
      is_applied_to_all_invoices: values?.is_applied_to_all_invoices,
    })
    .then(({ data }) => data?.data)

export const editTax = async (values) => {
  return await initApi()
    .patch(`${routes.price.tax}/${values.id}`, {
      name: values?.name,
      description: values?.description,
      value_pct: values?.value_pct,
      is_applied_to_all_invoices: values?.is_applied_to_all_invoices,
    })
    .then(({ data }) => data?.data)
}

export const deleteTax = async (userId) => await initApi().delete(routes.price.delete.tax(userId))

export const exportTaxes = async (filters) => {
  return await initApi()
    .get(`${routes.price.export.taxes(filters)}`, {
      responseType: "blob",
    })
    .then((res) => downloadFromBlob(res))
}

export const fetchPriceModifiers = async (filters = {}, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.price.modifiers(filters))
    .then(({ data }) => data?.data)

export const fetchAllPriceModifiers = async (req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.price.allModifiers)
    .then(({ data }) => data?.data)

export const createPriceModifier = async (values) =>
  await initApi()
    .post(routes.price.modifier, {
      name: values?.name,
      description: values?.description,
      ...(values?.mod === "fixed"
        ? {
            value_flat:
              getIntFromDollars(unformatNumber(values?.value)) * (values?.type === PriceModifierType.DISCOUNT ? -1 : 1),
          }
        : {
            value_pct:
              parsePercent(unformatNumber(values?.value)) * (values?.type === PriceModifierType.DISCOUNT ? -1 : 1),
          }),
    })
    .then(({ data }) => data?.data)

export const editPriceModifier = async (values) => {
  return await initApi()
    .patch(`${routes.price.modifier}/${values.id}`, {
      name: values?.name,
      type: values?.type,
      description: values?.description,
      ...(values?.mod === "fixed"
        ? {
            value_flat:
              getIntFromDollars(Math.abs(unformatNumber(values?.value))) *
              (values?.type === PriceModifierType.DISCOUNT ? -1 : 1),
          }
        : {
            value_pct:
              parsePercent(Math.abs(unformatNumber(values?.value))) *
              (values?.type === PriceModifierType.DISCOUNT ? -1 : 1),
          }),
    })
    .then(({ data }) => data?.data)
}

export const deletePriceModifier = async (userId) => await initApi().delete(routes.price.delete.modifier(userId))

export const exportPriceItems = async (filters = {}) => {
  return await initApi()
    .get(`${routes.price.export.item(filters)}`, {
      responseType: "blob",
    })
    .then((res) => downloadFromBlob(res))
}

export const exportPriceModifiers = async (filters) => {
  return await initApi()
    .get(`${routes.price.export.modifiers(filters)}`, {
      responseType: "blob",
    })
    .then((res) => downloadFromBlob(res))
}

export const fetchCustomerCategories = async (customerId: string, req?: GetServerSidePropsContext["req"]) => {
  return await initApi(req)
    .get(routes.customer.categories(customerId))
    .then(({ data }) => data?.data)
}

export const importPriceItemList = async (file: File) =>
  await initApi()
    .post(
      routes.price.itemsImport,
      { file },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    )
    .then(() => true)
    .catch(({ response }) => response?.data)
