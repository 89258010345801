import { initApi, routes } from "@/config/api"
import { Invoice, InvoiceAction, TableInvoices, TablePayments } from "@/types"
import { downloadFromBlob } from "@/utils/download"
import { adaptOutgoingInvoice } from "@/features/invoice-management/invoice-management-adapters"
import { GetServerSidePropsContext } from "next"

export const saveInvoice = async (invoice) => {
  return await initApi()
    .post(routes.invoice.save, adaptOutgoingInvoice(invoice))
    .then(({ data }) => data?.data)
}

export const updateInvoice = async (invoiceId, payload) => {
  return await initApi()
    .patch(routes.invoice.id(invoiceId), payload)
    .then(({ data }) => data?.data)
}

export const fetchAdminAllInvoices = async (
  filters: Record<string, any> = {},
  req?: GetServerSidePropsContext["req"],
) =>
  await initApi(req)
    .get(routes.admin.invoices.list(filters))
    .then(({ data }) => data?.data)

export const exportAdminAllInvoices = async (filters = {}, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.admin.invoices.export(filters))
    .then(({ data }) => data?.data)

export const fetchCustomerInvoices = async (customerId, filters, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.customer.invoices(customerId, filters))
    .then(({ data }) => data?.data)

export const fetchInvoice = async (invoiceId?: string | null, req?: GetServerSidePropsContext["req"]) =>
  invoiceId
    ? await initApi(req)
        .get(routes.invoice.id(invoiceId))
        .then(({ data }) => data?.data)
    : null

export const fetchInvoices = async (filters = {}, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.invoice.list(filters))
    .then(({ data }) => data?.data)

export const fetchUserInvoices = async (filters = {}, req?: GetServerSidePropsContext["req"]): Promise<TableInvoices> =>
  await initApi(req)
    .get(routes.user.invoices(filters))
    .then(({ data }) => data?.data)

export const fetchUserPayments = async (filters = {}, req?: GetServerSidePropsContext["req"]): Promise<TablePayments> =>
  await initApi(req)
    .get(routes.user.payments(filters))
    .then(({ data }) => data?.data)

export const performInvoiceAction = async (
  action: keyof typeof InvoiceAction,
  invoiceId: string,
  metadata?: Record<string, any>,
) =>
  await initApi()
    .post(routes.invoice.action(invoiceId), {
      action,
      metadata,
    })
    .then(({ data }) => data?.data)

export const performSeriesAction = async (action: keyof typeof InvoiceAction, seriesId: string, dueDate?: string) => {
  try {
    const res = await initApi().post(routes.invoice.seriesAction(seriesId), {
      action,
      due_at: dueDate,
    })
    return res?.data?.data
  } catch (error: any) {
    throw new Error(error?.response?.data?.message ?? "Request failed")
  }
}

export const exportCustomerInvoices = async (filters, customerId) =>
  await initApi()
    .get(routes.customer.invoiceExport(customerId, filters), {
      responseType: "blob",
    })
    .then((res) => downloadFromBlob(res))

export const exportInvoices = async (filters) =>
  await initApi()
    .get(routes.invoice.export(filters), {
      responseType: "blob",
    })
    .then((res) => downloadFromBlob(res))

export const fetchPilInvoice = async (invoiceId, isBackDoor = false, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(isBackDoor ? routes.invoice.bdpil(invoiceId) : routes.invoice.pil(invoiceId))
    .then(({ data }) => data?.data)

export const verifyPbl = async ({ invoiceId, code }: { invoiceId: string; code: string }) =>
  await initApi()
    .post(routes.invoice.login(invoiceId), { code })
    .then(() => {
      return { status: 200, redirectPath: `/pay/${invoiceId}` }
    })

export const modifyCustomerAutopay = async ({
  customerId,
  instrumentId = null,
  settleOutstanding = false,
}: {
  customerId: string
  instrumentId?: string | null
  settleOutstanding?: boolean
}) =>
  await initApi()
    .patch(routes.customer.switchAutopay(customerId), {
      autopay_wallet_instrument_id: instrumentId,
      should_settle_outstanding: settleOutstanding,
    })
    .then(({ data }) => data?.data)

export const checkOutstandingInvoices = async ({
  customerId,
  instrumentId,
}: {
  customerId: string
  instrumentId: string
}) =>
  await initApi()
    .get(routes.customer.outstandingInvoices(customerId, instrumentId))
    .then(({ data }) => data?.data)

export const sendBatchInvoice = async ({ invoice, isDraft = false }: { invoice: Invoice; isDraft?: boolean }) =>
  await initApi()
    .post(routes.invoice.batch, {
      customers: invoice?.customers.map((customer) => customer.id),
      ...adaptOutgoingInvoice(invoice),
      isDraft,
    })
    .then(({ data }) => data?.data)

// approve invoice
export const approveInvoice = async (invoice) => {
  return await initApi()
    .post(routes.invoice.approve(invoice.id))
    .then(({ data }) => data?.data)
}

export const disapproveInvoice = async (invoice) => {
  return await initApi()
    .post(routes.invoice.disapprove(invoice.id))
    .then(({ data }) => data?.data)
}

export const downloadReceipt = async (paymentId: string) => {
  return await initApi()
    .get(routes.payments.downloadReceipt(paymentId), {
      responseType: "blob",
    })
    .then((res) => {
      downloadFromBlob(res)
    })
}

export const downloadInvoice = async (invoiceId: string) => {
  return await initApi()
    .get(routes.invoice.pdf(invoiceId), {
      responseType: "blob",
    })
    .then((res) => {
      downloadFromBlob(res)
    })
}

export const fetchReceipt = async (paymentId: string) => {
  return await initApi()
    .get(routes.payments.downloadReceipt(paymentId), {
      responseType: "blob",
    })
    .then((res) => res)
}

export const sendReceipt = async (invoiceId: string) => {
  return await initApi()
    .get(routes.payments.sendReceipt(invoiceId))
    .then(({ data }) => data?.data)
}
