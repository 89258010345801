import { Invoice, Quote, Tax } from "@/types"

export const recalculateItemTaxes = (invoiceQuote: Invoice | Quote, newTaxes: Tax[]) => {
  const taxes = invoiceQuote.taxes || []
  const maps = {}
  const uniqTaxes: Tax[] = []

  newTaxes.forEach((tax) => {
    if (!maps[tax.id]) {
      maps[tax.id] = tax
      uniqTaxes.push(tax)
    }
  })

  const addedTaxes = uniqTaxes.filter((tax) => !taxes.some((t) => t.id === tax.id))
  const removedTaxIds = taxes.filter((tax) => !uniqTaxes.some((t) => t.id === tax.id)).map((tax) => tax.id)

  const items = (invoiceQuote.items || []).map((item) => {
    let pricemodifiers = [...(item.pricemodifiers || [])]

    addedTaxes.forEach((tax) => {
      if (pricemodifiers.every((pm) => pm.account_pricemodifier_id !== tax.id) && item.is_taxable) {
        pricemodifiers.push({
          name: tax.name,
          value_pct: tax.value_pct,
          account_pricemodifier_id: tax.id,
          is_tax: true,
        })
      }
    })

    pricemodifiers = pricemodifiers.filter((pm) => !removedTaxIds.includes(pm.account_pricemodifier_id || ""))

    return {
      ...item,
      pricemodifiers,
    }
  })

  return {
    items,
    taxes: uniqTaxes,
  }
}
