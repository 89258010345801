import MuiDialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import NiceModal, { useModal } from "@ebay/nice-modal-react"
import Typography from "@mui/material/Typography"
import InlineContainer from "@/components/InlineContainer"
import getFieldErrors from "@/utils/getFieldErrors"
import { useTheme, useMediaQuery } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/CloseRounded"
import NeutralButton from "@/components/buttons/NeutralButton"
import VerifyPhoneNumberDialog from "@/features/user-management/components/VerifyPhoneNumberDialog"
import useUserUpdater from "@/hooks/useUserUpdater"
import PhoneField from "@/components/PhoneField"
import AffirmativeButton from "@/components/buttons/AffirmativeButton"
import { intlFormat } from "@/utils/phone"

export default NiceModal.create(() => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const modal = useModal()

  const { fields } = useUserUpdater()

  const handleCloseModal = () => {
    modal.hide()
  }

  return (
    <MuiDialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      onClose={handleCloseModal}
      fullScreen={isMobile}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            color="common.black"
            variant="h6"
            sx={{
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            Phone Number
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: (theme) => theme.palette.grey[500],
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon fontSize="small" sx={{ color: "primary.dark" }} />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      <DialogContent sx={{ padding: "1.25rem" }} dividers>
        <Typography variant="body1" marginBottom={"1.25rem"}>
          This is the number used to sign in to your account. It can also be used to receive invoices and notifications
        </Typography>
        <Typography variant="body1" fontWeight={"bold"} marginBottom={".50rem"}>
          Mobile Phone Number
        </Typography>
        <PhoneField
          size="small"
          color="primary"
          variant="outlined"
          fullWidth
          sx={{ fontSize: "1rem", marginBottom: "1.25rem" }}
          {...fields.getFieldProps("cell_phone")}
          onChange={(e) => {
            fields.setFieldValue("cell_phone", intlFormat(e.target.value))
          }}
          {...getFieldErrors("cell_phone", fields)}
        />
      </DialogContent>
      <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between" width="100%">
          <NeutralButton disableElevation onClick={handleCloseModal}>
            Cancel
          </NeutralButton>
          <AffirmativeButton
            onClick={() => {
              modal.hide()
              fields.handleSubmit()
              NiceModal.show(VerifyPhoneNumberDialog).catch(() => {
                modal.show()
              })
            }}
          >
            Next
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </MuiDialog>
  )
})
