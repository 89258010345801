import { getIn, FormikProps, FormikErrors } from "formik"

export interface GetFieldErrorProps {
  (
    fieldName: string,
    formik: FormikProps<any>,
    options?: {
      ignoreTouched?: boolean
      ignoreMessage?: boolean
    },
  ): {
    error: boolean
    helperText: string | null
  }
}

export const getFieldErrors: GetFieldErrorProps = (fieldName, formik, options) => {
  const touched = options?.ignoreTouched ? true : !!getIn(formik.touched, fieldName)
  const error = getIn(formik.errors, fieldName)
  const shouldError = !!error && touched

  return {
    error: shouldError,
    helperText: options?.ignoreMessage ? null : shouldError ? error : null,
  }
}

export const doesFormHaveErrors = (errors: FormikErrors<any>) => {
  return Boolean(Object.keys(errors).length)
}

export default getFieldErrors
