import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import PaymentMethodList from "../../wallet/components/payment-method/PaymentMethodList"
import { PaymentMethodTypeEnum } from "@/types"

const BusinessWalletInstrument = ({
  newPaymentMethodFormik,
  account,
  walletInstruments,
  selectedWalletInstrumentId,
  setSelectedWalletInstrumentId,
}) => {
  return (
    <>
      <Typography variant="h4">Link your bank account</Typography>
      <Typography variant="body1" marginTop="0.5rem">
        Payments received from your customers will be deposited into this account.
      </Typography>
      <Box marginTop="1rem">
        <PaymentMethodList
          formik={newPaymentMethodFormik}
          walletInstruments={walletInstruments}
          isBackdoor={false}
          selectedWalletInstrumentId={selectedWalletInstrumentId}
          setSelectedWalletInstrumentId={setSelectedWalletInstrumentId}
          showProcessingFee={false}
          walletId={account?.wallet_id}
          metadata={{
            [PaymentMethodTypeEnum.BANK_ACCOUNT]: {
              visible: true,
              visibleNew: true,
            },
          }}
          allowUnverfiedInstrumentSelection
          partnershipInformation={
            account?.partnership?.routing_number
              ? {
                  routingNumber: account?.partnership?.routing_number,
                  name: account?.partnership?.name,
                }
              : undefined
          }
        />
      </Box>
    </>
  )
}

export default BusinessWalletInstrument
