import Dialog, { DialogContent } from "@/components/Dialog"
import NiceModal, { useModal } from "@ebay/nice-modal-react"
import useMessage from "@/hooks/useMessage"
import useSignup from "@/features/auth/useSignup"
import SignupForm from "./SignupForm"
import LoginDialog from "./LoginDialog"

export default NiceModal.create(() => {
  const modal = useModal()
  const [message, setMessage] = useMessage()

  const { fields, signupStep, handleGoogleAuthSuccess, sendOneTimeCode } = useSignup({
    // Because the user here is a Payer not a potential Merchant
    is_account_signup: false,
    setMessage,
    onSuccess: () => {
      modal.hide()
    },
    stayOnThisPage: true,
  })

  return (
    <Dialog
      modal={modal}
      maxWidth="xs"
      preventCloseOn="backdropClick"
      title="Sign Up"
      sx={{ maxWidth: "500px", margin: "0 auto" }}
    >
      <DialogContent
        sx={{
          padding: "2rem",
          paddingTop: "2rem",
        }}
      >
        <SignupForm
          formik={fields}
          signupStep={signupStep}
          message={message}
          handleGoogleAuthSuccess={handleGoogleAuthSuccess}
          sendOneTimeCode={sendOneTimeCode}
          alternateAction={() => {
            NiceModal.show(LoginDialog)
            modal.hide()
          }}
        />
      </DialogContent>
    </Dialog>
  )
})
