import BaseButton from "@/components/buttons/BaseButton"
import CircularProgress from "@mui/material/CircularProgress"

const AffirmativeButton = ({ children, ...props }) => {
  return (
    <BaseButton
      variant="contained"
      {...props}
      sx={(theme) => ({
        ...(Boolean(Object.keys(props?.sx ?? {}).length) ? props.sx : {}),
        color: theme.palette.getContrastText(theme.palette.primary.dark),
      })}
      startIcon={props?.loading ? null : props?.startIcon}
      endIcon={props?.loading ? null : props?.endIcon}
      {...(props?.loading ? { disabled: true } : {})}
    >
      {props?.loading ? (
        <CircularProgress size={24} thickness={4} color="inherit" sx={{ margin: "0.125rem" }} />
      ) : (
        children
      )}
    </BaseButton>
  )
}

export default AffirmativeButton
