import Decimal from "decimal.js-light"
import { formatDollar } from "./formatters"
import { InvoiceItem, QuoteItem } from "@/types"

export const getDollarsFromInt = (flat) => {
  return new Decimal(flat ?? 0).dividedBy(100).toNumber()
}

export const getPercentFromFloat = (percent) => {
  return new Decimal(percent ?? 0).times(100).toNumber()
}

export const getIntFromDollars = (flat) => {
  return new Decimal(flat ?? 0).times(100).toNumber()
}

export const parsePercent = (percent) => {
  return new Decimal(percent ?? 0).dividedBy(100).toNumber()
}

export const getPriceModifierValue = (modifier) => {
  return modifier?.value_pct ? getPercentFromFloat(modifier?.value_pct) : getDollarsFromInt(modifier?.value_flat)
}

export const calculatePriceModifier = ({
  price = 0,
  pricemodifier_name,
  pricemodifier_value_pct,
}: {
  price?: number
  pricemodifier_name?: string
  pricemodifier_value_pct?: string | number
}) => {
  let amount
  let priceModifier = ""

  if (pricemodifier_name && pricemodifier_value_pct) {
    let modifier = ""
    if (+pricemodifier_value_pct < 0) modifier = "-"
    const value = Math.abs(+pricemodifier_value_pct * 100)
    amount = price * +pricemodifier_value_pct

    if (amount < -price) {
      amount = -price
    }
    priceModifier = ` (${modifier}${value.toFixed(2)}%)`
  }

  return {
    name: `${pricemodifier_name}${priceModifier}`,
    amount,
  }
}

type Item = InvoiceItem | QuoteItem

export const getChangeAmount = (amount = 0, quantity = 1, modifier) => {
  return modifier?.value_pct !== null
    ? new Decimal(amount)
        .times(quantity)
        .times(modifier?.value_pct ?? 0)
        .toInteger()
    : new Decimal(modifier?.value_flat ?? 0).times(quantity).toInteger()
}

export const taxToSummary = ({ name, value_pct, total }: { name: string; value_pct: number; total?: number }) =>
  `${name} (${new Decimal(value_pct || 0).times(100).toNumber().toFixed(2)}%${total ? ` on ${formatDollar(total)}` : ""})`

export const sum = (values: any[]): Decimal => values.reduce((acc, inc) => acc.plus(new Decimal(inc)), new Decimal(0))

const minZero = (value: Decimal) => (value.lessThan(0) ? new Decimal(0) : value)

const subtotalsAfterInvoiceModifier = (
  invoiceModifier: {
    value_pct?: number
    value_flat?: number
  },
  itemSubtotals: { item: Item; amount: Decimal }[],
): { item: Item; amount: Decimal }[] => {
  if (!invoiceModifier?.value_flat && !invoiceModifier?.value_pct) {
    return itemSubtotals
  }

  if (invoiceModifier?.value_pct !== null) {
    return itemSubtotals.map(({ item, amount }) => {
      const change = getChangeAmount(amount.toNumber(), 1, invoiceModifier)

      return {
        item,
        amount: minZero(amount.add(change).toInteger()),
      }
    })
  }

  const sumSubtotal = sum(itemSubtotals.map(({ amount }) => amount))
  let remainingModification = new Decimal(invoiceModifier?.value_flat ?? 0)

  if (sumSubtotal.plus(remainingModification).lessThanOrEqualTo(0)) {
    return itemSubtotals.map(({ item }) => {
      return {
        item,
        amount: new Decimal(0),
      }
    })
  }

  const subtotals: { item: Item; amount: Decimal }[] = []
  for (const { item, amount } of itemSubtotals) {
    const percentOfSum = amount.dividedBy(sumSubtotal).toNumber()
    const modification = getChangeAmount(0, percentOfSum, {
      value_flat: invoiceModifier?.value_flat,
      value_pct: null,
    })

    remainingModification = remainingModification.minus(modification)
    // @ts-ignore
    subtotals.push({
      item,
      amount: minZero(amount.add(modification)),
    })
  }

  if (!remainingModification.equals(0) && subtotals.length) {
    // @ts-ignore
    subtotals[subtotals.length - 1].amount = minZero(subtotals[subtotals.length - 1].amount.plus(remainingModification))
  }

  return subtotals
}

export const getInvoiceTotals = (invoice) => {
  const invoiceItems = [...invoice?.items]?.map((i) => i)?.sort((a, b) => (a.id > b.id ? 1 : -1))

  if (invoiceItems.length === 0 && invoice?.pricemodifier_value_flat) {
    return {
      subTotal: 0,
      modificationTotal: Math.max(0, getDollarsFromInt(invoice?.pricemodifier_value_flat)),
      invoiceTotal: Math.max(0, getDollarsFromInt(invoice?.pricemodifier_value_flat)),
      taxSummary: [],
    }
  }

  const itemSubtotals = invoiceItems.map((item: any) => {
    const changeAmount = sum(
      (item?.pricemodifiers || [])
        .filter((modifier) => !modifier.is_tax)
        .map((modifier) => {
          return getChangeAmount(item?.price, item?.quantity, {
            value_pct: modifier?.value_pct,
            value_flat: modifier?.value_flat,
          })
        }),
    )

    return {
      item,
      amount: minZero(new Decimal(item.price ?? 0).times(item?.quantity).toInteger().add(changeAmount).toInteger()),
    }
  })

  const subTotal = sum(itemSubtotals.map(({ amount }) => amount))

  const subtotalsAfterDiscount = subtotalsAfterInvoiceModifier(
    {
      value_pct: invoice?.pricemodifier_value_pct,
      value_flat: invoice?.pricemodifier_value_flat,
    },
    itemSubtotals,
  )
  const subTotalAfterDiscount = sum(subtotalsAfterDiscount.map(({ amount }) => amount))

  const itemTotalByTax = new Map()

  subtotalsAfterDiscount.forEach(({ item, amount }) => {
    ;(item?.pricemodifiers || []).forEach((modifier) => {
      if (modifier.account_pricemodifier_id && modifier.is_tax) {
        if (!itemTotalByTax.has(modifier.account_pricemodifier_id)) {
          itemTotalByTax.set(modifier.account_pricemodifier_id, {
            priceModifier: modifier,
            total: new Decimal(0),
          })
        }

        const tax = itemTotalByTax.get(modifier.account_pricemodifier_id)

        tax.total = tax.total.plus(amount)
      }
    })
  })

  const taxTotals = Array.from(itemTotalByTax.entries()).map(
    ([_account_pricemodifier_id, { priceModifier, total }]) => {
      const change = getChangeAmount(total.toNumber(), 1, {
        value_pct: priceModifier?.value_pct,
        value_flat: null,
      })

      return {
        priceModifier,
        total,
        change,
      }
    },
  )

  const taxSummary = taxTotals
    .map(({ priceModifier, total, change }) => {
      return {
        account_pricemodifier_id: priceModifier.account_pricemodifier_id,
        summary: taxToSummary({ name: priceModifier?.name, value_pct: priceModifier?.value_pct, total }),
        value_pct: priceModifier?.value_pct,
        change: change.toNumber(),
      }
    })
    .sort((a, b) => (a.summary > b.summary ? 1 : -1))
    .sort((a, b) => b.value_pct - a.value_pct)

  const taxTotal = sum(taxTotals.map(({ change }) => change))
  const invoiceTotal = subTotalAfterDiscount.plus(taxTotal)

  return {
    subTotal: Math.max(0, getDollarsFromInt(subTotal)),
    modificationTotal: getDollarsFromInt(subTotalAfterDiscount.minus(subTotal)),
    invoiceTotal: Math.max(0, getDollarsFromInt(invoiceTotal)),
    taxTotal: getDollarsFromInt(taxTotal),
    taxSummary,
  }
}
