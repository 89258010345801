import Dialog, { DialogContent } from "@/components/Dialog"
import NiceModal, { useModal } from "@ebay/nice-modal-react"
import useMessage from "@/hooks/useMessage"
import useLogin from "@/features/auth/useLogin"
import LoginForm from "@/components/LoginForm"
import SignupDialog from "./SignupDialog"

export default NiceModal.create(() => {
  const modal = useModal()
  const [message, setMessage] = useMessage()

  const {
    showOneTimeCodeInput,
    showPasswordInput,
    showTwoFaInput,
    setShowOneTimeCodeInput,
    setShowPasswordInput,
    setShowTwoFaInput,
    resetForm,
    initiateLogin,
    loginFormik,
    sendOneTimeCode,
  } = useLogin({
    setMessage,
    stayOnThisPage: true,
    onLoginSuccess: () => {
      const timer = setTimeout(() => {
        clearTimeout(timer)
        window.location.reload()
      }, 1000)
    },
  })

  return (
    <Dialog
      modal={modal}
      maxWidth="xs"
      preventCloseOn="backdropClick"
      title="Login"
      sx={{ maxWidth: "500px", margin: "0 auto" }}
    >
      <DialogContent
        sx={{
          padding: "2rem",
          paddingTop: showOneTimeCodeInput ? "0.5rem" : "2rem",
        }}
      >
        <LoginForm
          message={message}
          showOneTimeCodeInput={showOneTimeCodeInput}
          showPasswordInput={showPasswordInput}
          showTwoFaInput={showTwoFaInput}
          setShowOneTimeCodeInput={setShowOneTimeCodeInput}
          setShowPasswordInput={setShowPasswordInput}
          setShowTwoFaInput={setShowTwoFaInput}
          resetForm={resetForm}
          initiateLogin={initiateLogin}
          loginFormik={loginFormik}
          sendOneTimeCode={sendOneTimeCode}
          onForgotPassword={() => {
            modal.hide()
          }}
          alternateAction={() => {
            NiceModal.show(SignupDialog)
            modal.hide()
          }}
        />
      </DialogContent>
    </Dialog>
  )
})
