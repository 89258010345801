import { DateTime } from "luxon"
import * as yup from "yup"
import "@/utils/yup-phone"

export const passwordChangeValidationSchema = yup.object().shape({
  newPassword: yup
    .string()
    .notOneOf([yup.ref("password"), null], "New password cannot be the same as current password")
    .min(10, "Password must be at least 10 characters")
    .required("New password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm new password is required"),
})

export const nameValidationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
})

export const fullNameValidationSchema = yup.object().shape({
  compliance_answer: yup.object().shape({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
  }),
})

export const businessAddressValidationSchema = yup.object().shape({
  legal_address1: yup.string().required("Address is required"),
  legal_city: yup.string().required("Required"),
  legal_state: yup.string().required("Required"),
  legal_zip_code: yup.string().required("Required"),
})

export const personalAddressValidationSchema = yup.object().shape({
  compliance_answer: yup.object().shape({
    address_1: yup.string().required("Address is required"),
    city: yup.string().required("Required"),
    state: yup.string().required("Required"),
    zip_code: yup.string().required("Required"),
  }),
})

export const dateOfBirthValidationSchema = yup.object().shape({
  compliance_answer: yup.object().shape({
    date_of_birth: yup
      .string()
      .required("Please select your date of birth")
      .test("ageCheck", "User must be at least 18 years old", (value) => {
        const today = DateTime.now()
        const selectedDate = DateTime.fromJSDate(new Date(value ?? ""))
        const eighteenYearsAgo = today.minus({ years: 18 })

        return selectedDate <= eighteenYearsAgo
      })
      .nullable(),
  }),
})

export const emailValidationSchema = yup.object().shape({
  email: yup.string().email("Please enter a valid email").required("Email is required"),
})

export const cellPhoneValidationSchema = yup.object().shape({
  cell_phone: yup.string().phone("US", true, "Please enter a valid phone number").required("Phone number is required"),
})
