import Box from "@mui/material/Box"
import MuiDialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import NiceModal, { useModal } from "@ebay/nice-modal-react"
import Typography from "@mui/material/Typography"
import InlineContainer from "@/components/InlineContainer"
import { useTheme, useMediaQuery } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/CloseRounded"
import { DialogKeys, InvoiceCustomer } from "@/types"
import AffirmativeButton from "@/components/buttons/AffirmativeButton"
import FormattedPhone from "@/components/FormattedPhone"
import { styled } from "@mui/material"
import { useState } from "react"
import NeutralButton from "@/components/buttons/NeutralButton"
import TestId from "@/constants/testIds"

const CustomerCard = styled(InlineContainer)(
  ({ theme }) => `
  background-color: ${theme.palette.common.white};
  border-radius: ${theme.shape.borderRadius}px;
  border: 1px solid ${theme.palette.common.white};
  padding: 0.75rem 1rem;
  width: 75%;

 
  
  &.selected {
    border: 2px solid ${theme.palette.primary.main};
  }

  .MuiTypography-body1 {
    color: ${theme.palette.common.black};
    font-weight: 500;
  }

  .MuiTypography-body2 {
    color: ${theme.palette.grey[700]};
  }
`,
)

export default NiceModal.create(({ prevSelectedCustomers = [] }: { prevSelectedCustomers: InvoiceCustomer[] }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const modal = useModal(DialogKeys.INVOICE_CUSTOMER_OPTIONS)
  const [customerList, setCustomerList] = useState(prevSelectedCustomers)

  const handleCloseModal = () => {
    modal.reject()
    modal.hide()
  }

  const handleRemoveCustomerFromList = (customerToRemove: InvoiceCustomer) => {
    setCustomerList(customerList.filter((customer) => customer?.id !== customerToRemove?.id))
  }

  const handleBackClick = () => {
    modal.hide()
    NiceModal.show(DialogKeys.INVOICE_QUOTE_CUSTOMER, {
      prevSelectedCustomers: customerList,
      mode: "invoice",
    })
  }

  return (
    <MuiDialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer width="100%" justifyContent="space-between">
          <Typography
            color="primary"
            variant="h6"
            sx={{
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            Add Customers
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: (theme) => theme.palette.grey[500],
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      {customerList?.length < 1 ? (
        <DialogContent sx={{ paddingTop: "3rem", justifyContent: "center" }} dividers>
          {isMobile ? (
            <Typography variant="body1" sx={{ fontWeight: 500, textAlign: "center" }}>
              Looks like you don't have any customers saved in the system. To create a new customer,{" "}
              <strong>tap the "+ Create New" button</strong>.
            </Typography>
          ) : (
            <>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                  textAlign: "center",
                  marginBottom: "2.5rem",
                }}
              >
                Looks like you don't have any customers saved in the system. To create a new customer, click the blue "+
                Create New" button.
              </Typography>
            </>
          )}
        </DialogContent>
      ) : (
        <DialogContent sx={{ padding: { xs: "0 0 5rem 0", md: "1.25rem" } }} dividers>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              backgroundColor: "grey.500",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "white",
                fontWeight: 700,
                padding: "0.5rem 1.5rem",
                textAlign: "left",
                width: "100%",
              }}
            >
              Batch Invoice Recipients
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "white",
                padding: "0.5rem 1.5rem",
                textAlign: "left",
                width: "100%",
              }}
            >
              Each customer is billed <strong>separately</strong> with a Batch Invoice
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              backgroundColor: "grey.200",
              justifyContent: "center",
              alignItems: "center",
              gap: ".5rem",
              padding: "1rem",
            }}
          >
            {customerList?.map((customer) => (
              <CustomerCard key={customer.id}>
                <InlineContainer justifyContent="space-between" width="100%">
                  <Box display="flex" flexDirection="column">
                    <Typography variant="h6">{customer?.name}</Typography>
                    {(customer?.email || customer?.user?.email) && (
                      <Typography variant="body2">{customer?.email || customer?.user?.email}</Typography>
                    )}
                    {(customer?.cell_phone || customer?.user?.cell_phone) && (
                      <Typography variant="body2">
                        <FormattedPhone value={customer?.cell_phone || customer?.user?.cell_phone} />
                      </Typography>
                    )}
                  </Box>
                  <IconButton
                    aria-label="close"
                    size="small"
                    onClick={() => handleRemoveCustomerFromList(customer)}
                    sx={{
                      color: (theme) => theme.palette.common.black,
                      position: "relative",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </InlineContainer>
              </CustomerCard>
            ))}
          </Box>
        </DialogContent>
      )}
      <DialogActions
        sx={{
          padding: "0.75rem 1.25rem",
          justifyContent: "center",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <AffirmativeButton
          disabled={customerList?.length < 1}
          sx={{ width: { xs: "100%", md: 350 } }}
          onClick={() => {
            modal.resolve(customerList)
            modal.hide()
          }}
          data-testid={TestId.Dialog.InvoiceAddCustomer}
        >
          {customerList.length > 1 ? `Add ${customerList.length} Customers` : `Add Customer`}
        </AffirmativeButton>
        <NeutralButton sx={{ width: { xs: "100%", md: 350 } }} onClick={handleBackClick} disableElevation>
          Back
        </NeutralButton>
      </DialogActions>
    </MuiDialog>
  )
})
