import Box from "@mui/material/Box"
import MuiDialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import NiceModal, { useModal } from "@ebay/nice-modal-react"
import Typography from "@mui/material/Typography"
import InlineContainer from "@/components/InlineContainer"
import { matchSorter } from "match-sorter"
import { useQuery } from "@tanstack/react-query"
import { useTheme, useMediaQuery, Checkbox } from "@mui/material"
import { fetchTaxes } from "@/features/price-management/price-management-queries"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/CloseRounded"
import FormattedNumber from "@/components/FormattedNumber"
import { useState } from "react"
import TextField from "@/components/TextField"
import PlusIcon from "@mui/icons-material/AddRounded"
import { setQuote } from "@/features/quote-management/quote-management-reducers"
import useAppDispatch from "@/hooks/useAppDispatch"
import { Query, DialogKeys } from "@/types"
import useAppSelector from "@/hooks/useAppSelector"
import { selectQuote } from "@/features/quote-management/quote-management-selectors"
import AffirmativeButton from "@/components/buttons/AffirmativeButton"
import NeutralButton from "@/components/buttons/NeutralButton"
import { selectInvoice } from "../invoice-management/invoice-management-selectors"
import { setInvoice } from "../invoice-management/invoice-management-reducers"
import { getPercentFromFloat } from "@/utils/calculations"
import { recalculateItemTaxes } from "./tax"
import { ItemCard, ItemList } from "./AddPriceItemToInvoiceQuoteDialog"

export default NiceModal.create(({ mode }: { mode: "invoice" | "quote" }) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const modal = useModal(DialogKeys.INVOICE_QUOTE_TAX)
  const [selectedTaxes, setSelectedTaxes] = useState<any[]>([])

  const [search, setSearch] = useState("")
  const selectedInvoiceQuote = useAppSelector(mode === "invoice" ? selectInvoice : selectQuote)

  const { data: taxes } = useQuery([Query.account.BUILDER_ALL_TAXES], () => fetchTaxes({ is_all: true }), {
    keepPreviousData: true,
  })

  const searchedTaxes: any = matchSorter(taxes?.results ?? [], search, {
    keys: ["name", "description", (tax: any) => getPercentFromFloat(tax?.value_pct).toString()],
  })

  const handleSelectTax = (tax) => {
    if (selectedTaxes.some((i) => i?.id === tax?.id)) {
      return setSelectedTaxes(selectedTaxes.filter((i) => i?.id !== tax?.id))
    }

    setSelectedTaxes([...selectedTaxes, tax])
  }

  const handleCloseModal = () => {
    modal.hide()
  }

  const isTaxUsed = (tax) => selectedInvoiceQuote?.taxes?.some((t) => t?.id === tax.id)

  const handleSaveTaxes = (taxes) => {
    const { items: newItems, taxes: newTaxes } = recalculateItemTaxes(selectedInvoiceQuote, [
      ...selectedInvoiceQuote.taxes,
      ...taxes,
    ])

    if (mode === "quote") {
      dispatch(
        // @ts-ignore
        setQuote({
          ...selectedInvoiceQuote,
          items: newItems,
          taxes: newTaxes,
          has_changed: true,
        }),
      )
    } else {
      if (selectedInvoiceQuote?.sync_mode === "ask") {
        dispatch(
          setInvoice({
            ...selectedInvoiceQuote,
            items: newItems,
            taxes: newTaxes,
            has_sync_data_changed: true,
            should_sync_data: true,
            has_changed: true,
          }),
        )
      } else {
        dispatch(
          setInvoice({
            ...selectedInvoiceQuote,
            items: newItems,
            taxes: newTaxes,
            has_changed: true,
          }),
        )
      }
    }

    handleCloseModal()
  }

  const handleAddNewTax = () => {
    // @ts-ignore
    NiceModal.show(DialogKeys.TAX).then(({ tax }) => {
      handleSaveTaxes(tax)
    })
    handleCloseModal()
  }

  const CreateNewButton = () => (
    <Box textAlign="center" padding="0 0.5rem 1.25rem">
      <AffirmativeButton onClick={handleAddNewTax} startIcon={<PlusIcon fontSize="small" />}>
        Create New
      </AffirmativeButton>
    </Box>
  )

  return (
    <MuiDialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            color="primary"
            variant="h6"
            sx={{
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            Taxes
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: (theme) => theme.palette.grey[500],
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      {taxes?.results?.length < 1 ? (
        <DialogContent sx={{ paddingTop: "3rem" }} dividers>
          {isMobile ? (
            <Typography variant="body1" sx={{ fontWeight: 500, textAlign: "center" }}>
              Looks like you currently do not have any taxes. To create a new tax
              <strong> tap the "+" button below</strong>.
            </Typography>
          ) : (
            <>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                  textAlign: "center",
                  marginBottom: "2.5rem",
                }}
              >
                You currently do not have any taxes. To create a new tax, click the “Create New” button below.
              </Typography>
              <CreateNewButton />
            </>
          )}
        </DialogContent>
      ) : (
        <DialogContent
          sx={{
            backgroundColor: "grey.200",
            padding: "1.25rem",
          }}
          dividers
        >
          <CreateNewButton />
          <Box sx={{ textAlign: "center", width: "100%" }}>
            <TextField
              name="price-item-search"
              placeholder="Search"
              sx={{
                backgroundColor: "white",
                borderRadius: "0.5rem",
                margin: "0 0 1rem",
                display: "flex",
              }}
              onChange={(event) => setSearch(event?.target?.value)}
            />
          </Box>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 500,
              margin: "1.25rem auto 0",
            }}
          >
            All Taxes
          </Typography>
          <ItemList>
            {searchedTaxes?.map((tax) => (
              <ItemCard
                key={`tax-${tax?.id}`}
                onClick={() => (isTaxUsed(tax) ? null : handleSelectTax(tax))}
                highlight={selectedTaxes.some((i) => i?.id === tax?.id)}
                disabled={isTaxUsed(tax)}
              >
                <InlineContainer>
                  <Checkbox size="small" checked={selectedTaxes.some((i) => i?.id === tax?.id)} />
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {tax?.name}
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500, marginRight: "0.5rem" }}>
                    :
                  </Typography>
                  <Typography variant="body1" sx={{ color: "grey.600", fontWeight: 500 }}>
                    <FormattedNumber prefix={undefined} suffix={"%"} value={getPercentFromFloat(tax?.value_pct)} />
                  </Typography>
                </InlineContainer>
              </ItemCard>
            ))}
          </ItemList>
        </DialogContent>
      )}
      {taxes?.results?.length < 1 ? null : (
        <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
          <InlineContainer justifyContent="space-between" width="100%">
            {isMobile ? null : (
              <NeutralButton variant="outlined" onClick={handleCloseModal}>
                Cancel
              </NeutralButton>
            )}
            <AffirmativeButton
              variant="contained"
              onClick={() => handleSaveTaxes(selectedTaxes)}
              disabled={!selectedTaxes.length}
              fullWidth={isMobile}
            >
              Add to {mode === "quote" ? "Quote" : "Invoice"}
            </AffirmativeButton>
          </InlineContainer>
        </DialogActions>
      )}
    </MuiDialog>
  )
})
