import { DateTime } from "luxon"

export const formattedDate = (date, format: string = "MM/dd/yyyy") => {
  if (date instanceof DateTime) {
    return date.toFormat(format)
  }

  if (typeof date === "string") {
    return DateTime.fromISO(date).toFormat(format)
  }

  if (!Boolean(date)) {
    return ""
  }

  const value = new Date(date)
  return DateTime.fromJSDate(value).toFormat(format)
}

export const formatDollar = (x: number) => {
  return `${x < 0 ? "-" : ""}$${Math.abs(x / 100).toLocaleString("en-US", {
    minimumFractionDigits: 2,
  })}`
}

export const formatNumber = (x: number) => {
  return `${x < 0 ? "-" : ""}${Math.abs(x / 100).toLocaleString("en-US")}`
}

export const dateFromFormat = ({
  date,
  from = "yyyy-MM-dd",
  to = "MM/dd/yyyy",
}: {
  date: string
  from?: string
  to?: string
}) => {
  return !date ? null : DateTime.fromFormat(date.split("T")[0], from).startOf("day").toFormat(to)
}
