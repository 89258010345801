import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const GamblingIcon = (props: SvgIconProps) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    fill="transparent"
    stroke="none"
    width="18"
    height="19"
    viewBox="0 0 18 19"
    style={{ padding: "0.125rem" }}
    {...props}
  >
    <g clipPath="url(#clip0_1327_98830)">
      <path
        d="M18.2996 3.99733L17.1448 3.51471V11.297L19.239 6.24669C19.5924 5.36763 19.1873 4.3593 18.2996 3.99733ZM1.49406 7.18608L5.76871 17.4849C5.89458 17.7982 6.10929 18.0679 6.38643 18.2609C6.66357 18.4538 6.99106 18.5615 7.32861 18.5708C7.55268 18.5708 7.78538 18.5277 8.00945 18.4329L14.3611 15.8043C15.0075 15.5371 15.4039 14.8994 15.4211 14.2616C15.4298 14.0376 15.3867 13.7876 15.3091 13.5636L11 3.26478C10.8782 2.94937 10.6642 2.67793 10.386 2.48579C10.1078 2.29365 9.77818 2.18971 9.44008 2.1875C9.216 2.1875 8.99193 2.23921 8.77647 2.31677L2.43345 4.94534C2.01181 5.118 1.676 5.45103 1.49985 5.87122C1.32369 6.29141 1.32161 6.76435 1.49406 7.18608ZM15.4125 3.91115C15.4125 3.45401 15.2309 3.01559 14.9077 2.69234C14.5844 2.3691 14.146 2.1875 13.6889 2.1875H12.4392L15.4125 9.37511"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1327_98830">
        <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default GamblingIcon
