import AddressAutocomplete from "@/components/AddressAutocomplete"
import { FormLabel } from "@/features/auth/components/styled"
import TextField from "@/components/TextField"
import getFieldErrors from "@/utils/getFieldErrors"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import InlineContainer from "@/components/InlineContainer"
import Select from "@/components/Select"
import { stateNamesAndAbbrs } from "@/types"
import MenuItem from "@mui/material/MenuItem"
import FormHelperText from "@mui/material/FormHelperText"

const PersonalAddress = ({ formik }) => {
  return (
    <>
      <Typography variant="h4">What is your personal address?</Typography>
      <Box marginTop="2rem">
        <Box marginBottom="1rem">
          <FormLabel sx={{ marginBottom: "6px" }}>Home Address Line 1</FormLabel>
          <AddressAutocomplete
            placeholder="Home Address"
            onPlaceSelected={(place) => {
              formik.setFieldValue("compliance_answer['address_1']", place.address1)
              formik.setFieldValue("compliance_answer['address_2']", place.address2)
              formik.setFieldValue("compliance_answer['city']", place.city)
              formik.setFieldValue("compliance_answer['state']", place.state)
              formik.setFieldValue("compliance_answer['zip_code']", place.zip)
            }}
            {...formik.getFieldProps("compliance_answer['address_1']")}
          />
          {getFieldErrors("compliance_answer['address_1']", formik)?.error && (
            <FormHelperText>{getFieldErrors("compliance_answer['address_1']", formik)?.helperText}</FormHelperText>
          )}
        </Box>
        <Box marginBottom="1rem">
          <FormLabel sx={{ marginBottom: "6px" }}>Home Address Line 2</FormLabel>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            placeholder="Suite, Unit, or Apt"
            {...formik.getFieldProps("compliance_answer['address_2']")}
            {...getFieldErrors("compliance_answer['address_2']", formik)}
          />
        </Box>
        <Box marginBottom="1rem">
          <FormLabel sx={{ marginBottom: "6px" }}>City</FormLabel>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            placeholder="City"
            {...formik.getFieldProps("compliance_answer['city']")}
            {...getFieldErrors("compliance_answer['city']", formik)}
          />
        </Box>
        <InlineContainer gap="1rem" alignItems="start">
          <Box sx={{ flexBasis: "100%" }}>
            <FormLabel sx={{ marginBottom: "6px" }}>State</FormLabel>
            <Select
              {...formik.getFieldProps("compliance_answer['state']")}
              placeholder="State"
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 400,
                    },
                  },
                },
              }}
            >
              <MenuItem disabled value="x">
                Select a State
              </MenuItem>
              {stateNamesAndAbbrs.map((state) => (
                <MenuItem key={state.ABBR} value={state.ABBR}>
                  {state.NAME}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box sx={{ flexBasis: "100%" }}>
            <FormLabel sx={{ marginBottom: "6px" }}>Zip Code</FormLabel>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              inputProps={{ maxLength: 10 }}
              placeholder="Zip Code"
              {...formik.getFieldProps("compliance_answer['zip_code']")}
              {...getFieldErrors("compliance_answer['zip_code']", formik)}
            />
          </Box>
        </InlineContainer>
      </Box>
    </>
  )
}

export default PersonalAddress
