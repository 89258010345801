import { createSelector } from "@reduxjs/toolkit"
import type { RootState } from "@/config/store"
import { Invoice, InvoiceCustomer } from "@/types"

export const selectInvoice = ({ invoice }: RootState) => invoice?.invoice

export const selectInvoicePreview = ({ invoice }: RootState) => invoice?.invoicePreview

export const selectInvoiceItems = createSelector(
  [selectInvoice],
  (invoice) => invoice?.items ?? ([] as Invoice["items"]),
)

export const selectInvoiceItemIds = createSelector([selectInvoiceItems], (items) =>
  items?.map((i) => i?.account_priceitem_id),
)

export const selectInvoiceCustomers = createSelector(
  [selectInvoice],
  (invoice) => invoice?.customers ?? ([{}] as InvoiceCustomer[]),
)
