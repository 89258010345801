import { ReactNode, useState } from "react"

export type MessageVariation = "error" | "success" | "info" | "warning"

export interface Message {
  type: MessageVariation
  message: ReactNode
}

const useMessage = (defaultValue = null) => {
  const [message, setMessage] = useState<Message | null>(defaultValue)

  return [message, setMessage] as const
}

export default useMessage
