import NiceModal, { useModal } from "@ebay/nice-modal-react"
import { DateTime } from "luxon"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import InlineContainer from "@/components/InlineContainer"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/CloseRounded"
import NeutralButton from "@/components/buttons/NeutralButton"
import { Invoice } from "@/types"
import AffirmativeButton from "@/components/buttons/AffirmativeButton"
import { useMediaQuery, useTheme } from "@mui/material"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import TestId from "@/constants/testIds"

const ShowDate = ({ date }) => {
  const today = DateTime.now().toFormat("MM/dd/yyyy")
  const showDate = DateTime.fromISO(date as string).toFormat("MM/dd/yyyy")

  if (today === showDate) {
    return (
      <span>
        <b>today</b>
      </span>
    )
  } else {
    return (
      <span>
        on <b>{showDate}</b>
      </span>
    )
  }
}

export default NiceModal.create<{
  invoice: Invoice
  isSchedule?: boolean
  issueToday?: boolean
}>(({ invoice, isSchedule = false, issueToday = false }) => {
  const modal = useModal()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const handleCloseModal = () => {
    modal.hide()
  }

  const isBatch = invoice?.customers?.length > 1

  return (
    <Dialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      onClose={handleCloseModal}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{
              color: "primary.dark",
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            {isSchedule ? "Schedule" : "Issue"} Invoice
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: "grey.500",
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      <DialogContent sx={{ padding: "2rem 1.25rem" }} dividers>
        <Typography variant="body1">
          {isBatch
            ? `Are you sure you would like to ${isSchedule ? "schedule" : "issue"} this batch invoice to the following recipients?`
            : `Are you sure you would like to ${isSchedule ? "schedule" : "issue"} this ${invoice?.series ? "recurring" : "one-time"} invoice?`}
        </Typography>
        {isBatch && (
          <>
            <Grid container spacing={1} marginTop=".5rem">
              {invoice?.customers?.map((customer) => (
                <Grid item key={customer.id} xs={12} sm={6} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      border: "1px solid",
                      borderRadius: "4px",
                      borderColor: "primary.main",
                      padding: "2px",
                    }}
                  >
                    <Typography variant="body2" noWrap>
                      {customer.name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </>
        )}
        <br />
        <Typography variant="body1">
          {isBatch ? "Each" : "This"} invoice will be issued&nbsp;
          <ShowDate date={issueToday ? DateTime.now().toISO() : invoice?.expected_issue_at} /> and&nbsp;
          {isBatch ? "each" : "your"} customer will{" "}
          {invoice?.expected_notify_at ? (
            <span>
              be notified&nbsp;
              <ShowDate date={invoice?.expected_notify_at} />
            </span>
          ) : (
            "not be notified"
          )}
          .
        </Typography>
        {isBatch && (
          <Typography
            variant="body1"
            marginTop="1.5rem"
            sx={{ color: "#686D73", fontWeight: 400, fontSize: "0.875rem" }}
          >
            When you send out your Batch Invoice, multiple separate invoices will be created for each Batch Invoice
            receipient that can be found invididually on the Invoice Table.
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between" width="100%">
          <NeutralButton
            disableElevation
            onClick={() => {
              modal.reject()
              handleCloseModal()
            }}
            sx={{ fontSize: isMobile ? "small" : "medium" }}
          >
            Cancel
          </NeutralButton>
          <AffirmativeButton
            onClick={() => {
              modal.resolve()
              handleCloseModal()
            }}
            sx={{ fontSize: isMobile ? "small" : "medium" }}
            data-testid={TestId.Dialog.InvoiceConfirmSendInvoice}
          >
            Confirm
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </Dialog>
  )
})
