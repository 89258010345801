import * as yup from "yup"
import "@/utils/yup-phone"

// If value has two leading zeros and 8 following digits.
const numberWithLeadingZeros = /^(00)\d{8}$/m

export const email_or_phone = yup.string().when("isPhone", {
  is: 1,
  then: yup.string().when("email_or_phone", {
    is: (value) => !value?.match(numberWithLeadingZeros),
    then: yup
      .string()
      .phone("US", true, "Please enter a valid phone number")
      .required("Please enter a valid phone number"),
  }),
  otherwise: yup.string().email("Please enter a valid email").required("Please enter a valid email or phone number"),
})

export const password = yup.string().min(10, "minimum").required("Please enter a password")

export const loginValidationSchema = yup.object().shape({
  email_or_phone,
  password: yup.string().required("Please enter your password"),
})

export const passwordValidationSchema = yup.object().shape({
  password: yup.string().required("Please enter your password").min(10, "Minimum of 10 characters required"),
})

export const newPasswordValidationSchema = yup.object().shape({
  newPassword: yup.string().required("Please enter your password").min(10, "Minimum of 10 characters required"),
})

export const otcValidationSchema = yup.object().shape({
  email_or_phone,
  otc_code: yup.array().of(yup.string().required("Code is required")).min(6, "Code must be 6 characters"),
})

export const twoFaValidationSchema = yup.object().shape({
  email_or_phone,
  two_fa_token: yup.array().of(yup.string().required("Code is required")).min(6, "Code must be 6 characters"),
})

export const initialValidationSchema = yup.object().shape({
  email_or_phone,
})

export const registrationValidationSchema = yup.object().shape({
  email_or_phone,
  password,
  recaptchaToken: yup.string().required("Please verify you are not a robot"),
})

export const emailPhoneValidationSchema = yup.object().shape({
  email_or_phone,
})

export const confirmPasswordValidationSchema = yup.object().shape({
  password,
  passwordConfirmation: yup.string().oneOf([yup.ref("password"), null], "mismatch"),
})

export const validatePhoneVerificationSchema = yup.object().shape({
  code: yup.array().of(yup.number().required()).required(),
})

export const onboardingValidationSchema = yup.object().shape({
  public_name: yup.string().required("Please enter your business name"),
  public_phone: yup.string().required("Please enter your business phone number"),
  public_email: yup.string().email("Please enter a valid business email"),
})

export const businessUserDemographicValidationSchema = yup.object().shape({
  user_demographic: yup
    .object()
    .shape({
      user_id: yup.string().required(),
      race: yup.array().required("Please select your race").of(
        yup.string(),
        // .oneOf(["hispanic", "white", "black", "asian", "pacific", "native", "prefer-not-to-answer"])
      ),
      gender: yup
        .string()
        .required("Please select your gender")
        .oneOf(["non-binary", "female", "male", "not-listed", "prefer-not-to-answer"]),
      income: yup
        .string()
        .required("Please select your income")
        .oneOf(["_25k", "25k_50k", "50k_75k", "75k_100k", "100k_", "prefer-not-to-answer"]),
      is_veteran: yup.boolean().required("Please select whether you're a veteran"),
      is_ada: yup.boolean().required("Please select whether you're a disability"),
    })
    .required(),
})

export const onboardingMemberValidationSchema = yup.object().shape({
  name: yup.string().required("Please enter your full name"),
  cell_phone: yup.string().required("Please enter your phone number"),
})
