import InlineContainer from "@/components/InlineContainer"
import SimpleMessageDialog from "@/components/SimpleMessageDialog"
import Box from "@mui/material/Box"
import FormControlLabel from "@mui/material/FormControlLabel"
import Typography from "@mui/material/Typography"
import NiceModal from "@ebay/nice-modal-react"
import FormGroup from "@mui/material/FormGroup"
import Checkbox from "@mui/material/Checkbox"
import MuiLink from "@mui/material/Link"
import { styled } from "@mui/material/styles"
import IllegalActivityIcon from "@mui/icons-material/BlockRounded"
import GamblingIcon from "@/components/icons/GamblingIcon"
import CannabisIcon from "@/components/icons/CannabisIcon"
import CryptoIcon from "@/components/icons/CryptoIcon"
import MoneyServicesIcon from "@/components/icons/MoneyServicesIcon"

const MoreInfoButton = styled(MuiLink)(
  ({ theme }) => `
	color: ${theme.palette.primary.main};
	display: block;
	font-family: Poppins;
	font-size: 1rem;
	font-weight: 600;
	line-height: 240%;
`,
) as typeof MuiLink // https://github.com/mui/material-ui/issues/15759#issuecomment-1156138459

export const bannedBusinessTypes = [
  {
    label: "Crypto",
    description: "Virtual and cryptocurrencies; Non-Fungible Tokens (NFTs); and mining services.",
    icon: <CryptoIcon fontSize="small" sx={{ marginRight: "0.5rem" }} />,
  },
  {
    label: "Money Services",
    description:
      "Money Services (i.e. check cashing); Investment & Credit Services; Money Laundering Schemes; Get Rich Quick Schemes; Multi-level Marketing.",
    icon: <MoneyServicesIcon fontSize="small" sx={{ marginRight: "0.5rem" }} />,
  },
  {
    label: "Cannabis & Drug Related",
    description:
      "Cannabis products; Cannabis or drug dispensaries; Drug Paraphernalia; Substances designed to mimic illegal drugs.",
    icon: <CannabisIcon fontSize="small" sx={{ marginRight: "0.5rem" }} />,
  },
  {
    label: "Gambling",
    description:
      "Online or in-person gambling; Gaming with virtual world credits and coins; Sports betting; Lotteries.",
    icon: <GamblingIcon fontSize="small" sx={{ marginRight: "0.5rem" }} />,
  },
  {
    label: "Illegal Activity",
    description:
      "Counterfeit or unauthorized goods; Intellectual Property or Property Rights Infringement; Mugshot publications or pay-to-remove services; Regulated or illegal Products or Services (excluding care and rehabilitation)",
    icon: (
      <IllegalActivityIcon
        fontSize="small"
        sx={{
          fill: "currentColor",
          fontSize: "1.25rem",
          margin: "0 0.5rem 0 0.25rem",
        }}
      />
    ),
  },
]

const NotShady = ({ formik }) => {
  return (
    <>
      <Typography variant="h4">Before we start...</Typography>
      <Box>
        <Typography variant="body1" sx={{ margin: "1rem 0" }}>
          Due to financial regulations we are not able to work with the following types of businesses:
        </Typography>
        {bannedBusinessTypes.map((type) => (
          <InlineContainer key={type.label}>
            {type.icon}
            <MoreInfoButton
              component="button"
              onClick={() =>
                NiceModal.show(SimpleMessageDialog, {
                  title: type.label,
                  content: type.description,
                })
              }
            >
              {type.label}
            </MoreInfoButton>
          </InlineContainer>
        ))}
        <Box marginTop="0.75rem">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.is_not_shady}
                  {...formik.getFieldProps("is_not_shady")}
                  sx={{
                    color: !formik.values.is_not_shady && formik.touched.is_not_shady ? "red" : "inherit",
                  }}
                />
              }
              label="I verify that my type of business is not listed above."
            />
          </FormGroup>
        </Box>
      </Box>
    </>
  )
}

export default NotShady
